<template>
    <div class="main-loader" v-if="loading">
        <div class="loader-cnt">
            <img src="@/assets/main_logo.svg" width="200" alt="PayAngel">
            <p class="mb-0">Please wait...</p>
        </div>
    </div>
</template>
<script>
export default {
    name:"LoaderComponent",
    data(){
        return{
            loading:false
        }
    },methods: {
        show(){
            this.loading = true
        },

        hide(){
            this.loading = false
        }
    }
}
</script>