<template>
    <div class="body d-flex flex-column">
        <div class="d-flex justify-content-between">
            <i class="fa fa-2x" :class="icon"></i>
            <div class="d-flex flex-column justify-content-center text-right">
                <h6>{{ text }}</h6>
                <span class="font-weight-normal">{{ price }}</span>
            </div>
        </div>
        <div class="progress progress-xs mb-0 mt-3">
            <div role="progressbar" :aria-valuenow="progressbar_width" aria-valuemin="0" aria-valuemax="100" class="progress-bar" :class="progress_class" :style="`width:${progressbar_width}%`"></div>
        </div>
        <small class="text-muted mt-1">{{ compared_text }}</small>
    </div>
</template>
<script>
export default {
    name:'TransactionsTopRow',
    props: {
        icon : String, 
        text : String,
        price: String,
        progress_class : String,
        compared_text : String, 
        progressbar_width: String
    }
}
</script>
