<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <b-tab title="Company" active>
                                <ul class="list-unstyled mb-0 common-form small-form">
                                    <ValidationObserver v-slot="{ passes }">
                                        <form @submit.prevent="passes(accountDetails)" id="basic-form">
                                            <li>
                                                <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Select Country*</label>
                                                                <multiselect
                                                                    v-model="user.country" 
                                                                    :show-labels="false" 
                                                                    :options="countrys_options" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    :preselect-first="true">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="user.country && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="functions" rules="required" v-slot="{ errors }">
                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.curancy && !errors[0]}">
                                                        <span class="float-label">
                                                            <label for="roles">Select Currencies*</label>
                                                            <multiselect
                                                                v-model="user.curancy"  
                                                                :show-labels="false" 
                                                                :options="curancys_options" 
                                                                :searchable="false"
                                                                label="name" 
                                                                track-by="name"
                                                                :preselect-first="true">
                                                                <template slot="option" slot-scope="props">
                                                                    <div class="option-title">
                                                                        <span :class="props.option.flagClass" class="mr-2"></span>
                                                                        <span>{{ props.option.name }}</span>
                                                                    </div>
                                                                </template>
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="user.curancy && !errors[0]"></i>  
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="account_number" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !user.account_number,'success':user.account_number}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="user.account_number" class="form-control" id="account_number" placeholder="Account Number*" @blur="accountFisEmpty">
                                                            <label for="account_number" class="control-label">Account Number*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !user.account_number"></i>
                                                            <i class="fa fa-check" v-if="user.account_number"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="sort_code" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !user.sort_code,'success':user.sort_code}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="user.sort_code" class="form-control" id="sort_code" placeholder="Sort code*">
                                                            <label for="sort_code" class="control-label">Sort code*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !user.sort_code"></i>
                                                            <i class="fa fa-check" v-if="user.sort_code"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="legal_name" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !user.legal_name,'success':user.legal_name}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="user.legal_name" class="form-control" id="legal_name" placeholder="Legal Name*">
                                                            <label for="legal_name" class="control-label">Legal Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !user.legal_name"></i>
                                                            <i class="fa fa-check" v-if="user.legal_name"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                                    <b-button size="lg" class="btn-mw" @click="$router.go(-1)">Cancel</b-button>
                                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Continue</b-button>
                                                </div>
                                            </li>
                                        </form> 
                                    </ValidationObserver>
                                </ul>
                                <!-- Account Invalid Modal -->
                                <b-modal id="invalideAccoubtType" ref="invalideAccoubtType" hide-footer> 
                                    <div class="modal-area">
                                        <ul class="list-unstyled mb-0 invalid-modal text-center px-2">
                                            <li><i class="fa fa-4x fa-warning text-primary mt-4"></i></li>
                                            <li><h4 class="mt-5 mb-3">Account type doesn't match</h4></li>
                                            <li><p class="mb-4">The recipient's bank said the account belongs to an individual.</p></li>
                                            <li><p>You entered <strong>COMPANY.</strong></p></li>
                                            <li><p>Please double check the details.</p></li>
                                            <li><b-button class="mb-3" block variant="primary" size="lg" @click="$bvModal.hide('invalideAccoubtType')">Replace and continue</b-button></li>
                                            <li><a href="#" @click="$bvModal.hide('invalideAccoubtType')">Keep what i entered</a></li>
                                        </ul>
                                    </div>
                                </b-modal>
                            </b-tab>
                            <b-tab title="individual"><p>individual</p></b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'AccountDetailsComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data (){
        return{
            countrys_options: [
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],
            curancys_options: [
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],

            user:{
                country: null,
                curancy: null,
                account_number: '',
                sort_code:'',
                legal_name:''
            }
        }
    },methods: {
        accountDetails(){
            this.$router.push({ path: '/pay-transfer/user-account-details' })
        },

        accountFisEmpty(e) {      
            if(e.target.value == ''){
                this.$refs['invalideAccoubtType'].show()
            }
        },
    }
}

</script>
