<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card">
                <div class="body pt-0">
                    <div class="chart-top-action d-md-flex justify-content-end py-3">
                        <!-- SearchBox Filters -->
                        <!-- <div class="mr-0 mr-md-3 action-top-search">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search currency" aria-label="Search currency" aria-describedby="search-currency" v-model="searchText" v-on:keyup.enter="refreshCurrencyTable">
                                <div class="input-group-append" @click="refreshCurrencyTable()">
                                    <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                </div>
                            </div>
                        </div> -->
                        <!-- Toggle Filter Button -->
                        <!-- <div class="fliter-main mr-auto mt-3 mt-md-0">
                            <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                            <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                <div class="d-flex justify-content-between p-3">
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Filter Type"
                                                v-model="filterBy"  
                                                :show-labels="false" 
                                                :options="filterOption" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Filter Type"
                                                v-model="condition"  
                                                :show-labels="false" 
                                                :options="conditionList" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshCurrencyTable()">Apply</button>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div> -->

                        <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown">
                            <b-button size="lg" variant="primary btn-mw" @click="createCurrency()">Add Currency <i class="fa fa-plus-square"></i></b-button>
                        </div>
                        <b-modal id="add_currency" title="Add Currency" hide-footer no-stacking no-close-on-backdrop>
                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                <ValidationObserver v-slot="{ passes }">
                                    <form @submit.prevent="passes(addCurrencyForm)" id="single-form">
                                        <li>
                                            <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                <div class="country-dropdown">
                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':addCurrency.currencyname && addCurrency.currencyname.length != 0  && !errors[0]}">
                                                        <span class="float-label">
                                                        <label for="Currency">Select Currency*</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="addCurrency.currencyname" 
                                                                :show-labels="false" 
                                                                :options="currencyOptions" 
                                                                :searchable="false" 
                                                                label="name" 
                                                                track-by="name"
                                                                @select="resetFilterValue">
                                                                <template slot="option" slot-scope="props">
                                                                    <span :class="props.option.flagClass"></span>
                                                                    <div class="option__desc">
                                                                        <span class="option__title">{{ props.option.name }}</span>
                                                                    </div>
                                                                </template>
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="addCurrency.currencyname && addCurrency.currencyname.length != 0 && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                            <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0003-102'">{{apiErrorMsg}}</span>
                                        </li>
                                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                            <b-button type="submit" variant="primary btn-mw" size="lg">Add Currency</b-button>
                                            <b-button @click="$bvModal.hide('add_currency')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </ul>
                        </b-modal>
                    </div>
                    
                    <b-table 
                        ref="currencyTable"
                        responsive
                        hover 
                        outlined
                        table-class="js-basic-example table-custom mb-0"
                        head-variant="light"
                        :items="getCurrencyData" 
                        :fields="currencyfields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection">
                        <template #head()="{label,  field: { key }}">
                            {{ label }}
                            <!-- Custom icons -->
                            <template>
                                <i v-if="sortBy !== key" class=""></i>
                                <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                <i v-else class="fa fa-long-arrow-up ml-1"></i>
                            </template>  
                        </template>
                        <template #cell(action)="data">
                            <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                <b-dropdown-item @click="deleteCurrency(data.item.countryId, data.item.currency)">Delete</b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <!-- Table Pagination -->
                    <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                        <div class="per-page">
                            <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="number-page">
                            <b-pagination 
                                v-model="currentPage" 
                                :total-rows="totalRows" 
                                :per-page="perPage"
                                hide-goto-end-buttons 
                                hide-ellipsis 
                                prev-text="Previous page" 
                                next-text="Next page" 
                                align="right">
                            </b-pagination>
                        </div>
                        <div class="go-page">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Go To Page</span>
                                </div>
                                <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
import shared from "@/shared.js";
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default

export default {
    name: 'SupportedCurrency',
    props: {
        buttonClass: String
    },components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'main-loader':LoaderComponent
    },data(){
        return{
            isVisibleFilter: false,
            addCurrency:{
                currencyname:''
            },

            items:[],
            currencyfields: [
                { key: 'currency', label: 'Currency'},
                { key: 'action', label: 'Actions'}
            ],

            currencyOptions : [],

            // filterOption: [
            //     { value: 'currencycode', name:'Currency Code' }
            // ],

            // conditionList: [
            //     { value: '==', name:'equals to' },
            //     { value: '>=', name:'greater than or equals to' },
            //     { value: '>', name:'greater than' },
            //     { value: '<', name:'less than' },
            //     { value: '<=', name:'less than or equals to' },
            //     { value: 'like', name:'like' }
            // ],
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            // searchText: '',
            // filterBy: '',
            // filterValue: '',
            // condition: '',

            apiErrorMsg : '',
            apiErrorCode: ''
        }
    },methods:{
        async getCurrencyData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'currencycode'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'asc'
                } else {
                    sort = 'desc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    // search : this.searchText,
                    // filterby : this.filterBy ? this.filterBy.value : '',
                    // filtervalue : this.filterValue,
                    // condition : this.condition ? this.condition.value : '=='
                }
                const response = await this.$http.get(this.$baseurl+"currencies/supported/"+sessionStorage.getItem("countryId"), { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                if(response.data.code == '0003-100'){
                    response.data.details.map((item) => {
                        let currencyLists = {
                            countryId:item.countryid,
                            currency:item.currencycode,
                            action:''
                        };
                        this.items.push(currencyLists);
                    });
                    this.$root.$refs.CountryDetailComponent.setSupportedCurrency(this.items);
                    this.totalRows = response.data.query.total;
                    return this.items;
                }              
            } catch (error) {
                console.log(error);
            }
        },

        async getAllCurrency(){
            try {
                const response = await this.$http.get(this.$baseurl+"currencies",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                if(response.data.code == '0003-100'){
                    response.data.details.map((item) => {
                        let currencyData = {
                            name: item.currencycode, 
                            flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round"
                        }
                        this.currencyOptions.push(currencyData);
                    });
                }  
            } catch (error) {
                console.log(error);
            }
        },

        createCurrency(){
            this.$bvModal.show('add_currency');
        },
        refreshCurrencyTable() {
            this.$refs.currencyTable.refresh();
        },
        resetFilterValue(){
            this.apiErrorMsg = '';
            this.apiErrorCode = '';
        },

        addCurrencyForm(){
            let currencyData = {
                countryid:sessionStorage.getItem("countryId"),
                currencyCode:this.addCurrency.currencyname.name
            }
            this.$http.post(this.$baseurl+"currencies/supported", currencyData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                },
            }).then((res) => {
                if(res.data.code == '0003-100') {
                    shared.toastrSuccess(res.data.message);
                    this.refreshCurrencyTable();
                    this.$bvModal.hide('add_currency');
                    this.apiErrorMsg = '';
                    this.apiErrorCode = '';
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
            });
        },

        deleteCurrency(countryid, currency) {
            // console.log(countryid, currency);
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    this.$http.delete(this.$baseurl+"currencies/supported/"+countryid+'/'+currency, {    
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '0003-100') {
                            shared.toastrSuccess(res.data.message);
                            this.refreshCurrencyTable();
                            this.$refs.loader.hide();
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        // if(this.apiErrorCode == "E0065-005") {
                        //     this.$refs.loader.hide();
                        //     shared.toastrError(error.response.data.message);
                        // } else {
                            this.apiErrorMessage = error.response.data.message;
                        // }
                    });                
                }
            })
        },
    },mounted(){
        this.getAllCurrency();
    }
}
</script>