import Vue from "vue";
import Vuex from "vuex";
import contactDetail from './modules/contactDetail';
import registration from './modules/registration';
import personalInfo from './modules/personalInfo';
import resetPassword from './modules/resetPassword';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        contactDetail,
        registration,
        personalInfo,
        resetPassword
    },
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
})