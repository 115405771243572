<template>
   <div class="right-header">
        <b-navbar toggleable="lg" class="bg-light p-0 bg-transparent justify-content-between">
            <b-navbar-brand class="header-name" to="/home"><img src="https://www.wrraptheme.com/demo/payangel/img/Payangel_Business_main.2a69cb29.svg" width="150" alt="Payangel Business"></b-navbar-brand>
            <b-navbar-toggle target="navbarColor01"></b-navbar-toggle>
            <b-collapse id="navbarColor01" is-nav class="flex-grow-0">
                <b-nav class="navbar-nav ml-auto">
                    <b-nav-item>Contact Us</b-nav-item>
                    <b-nav-item-dropdown variant="link" class="country-dropdown">
                        <template #button-content>
                        <span :class="navbar_details.country_dropdawn.imageClass"></span>{{ navbar_details.country_dropdawn.text_title }}
                        </template>
                        <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.country_dropdawn.citys" :key="index" @click="activeCountry(index)" :active="index == navbar_details.country_dropdawn.active_index">
                            <span :class="city.imageClass" alt=""></span>{{ city.name }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown variant="link" class="lang-dropdown">
                        <template #button-content>
                        {{ navbar_details.language_dropdawn.text_title }}
                        </template>
                        <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.language_dropdawn.citys" :key="index" @click="activeLang(index)" :active="index == navbar_details.language_dropdawn.active_index">
                            {{ city.name }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    name:'RightHeaderComponent',
    components: {
    },
    data(){
        return{
            navbar_details: {
                country_dropdawn:{
                    text_title: 'United Kingdom',
                    imageClass: "flag-icon flag-icon-gb fs-20 mr-2 fi-round fi-md",
                    active_index: 1,
                    citys:[
                        {
                            imageClass: "flag-icon flag-icon-ca fs-20 mr-2 fi-round fi-md",
                            name:'Canada'
                        },
                        {
                            imageClass: "flag-icon flag-icon-gb fs-20 mr-2 fi-round fi-md",
                            name:'United Kingdom'
                        }
                    ]
                },
                language_dropdawn:{
                    text_title: 'EN',
                    active_index: 0,
                    citys:[
                        {
                            name:'EN'
                        },
                        // {
                        //     name:'FR'
                        // },
                        // {
                        //     name:'ES'
                        // }
                    ]
                },
            }
        }
    },
    methods: {
        activeCountry(index){
            this.navbar_details.country_dropdawn.active_index = index;
            this.navbar_details.country_dropdawn.text_title = this.navbar_details.country_dropdawn.citys[index].name;
            this.navbar_details.country_dropdawn.imageClass = this.navbar_details.country_dropdawn.citys[index].imageClass;
        },

        activeLang(index){
            this.navbar_details.language_dropdawn.active_index = index;
            this.navbar_details.language_dropdawn.text_title = this.navbar_details.language_dropdawn.citys[index].name;
        }
    },
    mounted () {
        
    }
}
</script>
