<template>
    <div class="card card-top-line">
        <money-header-widgets
            :img = "headerDetais.image"
            :title = "headerDetais.title"
            :subTitle = "headerDetais.subTitle"
            :isActions = false
        ></money-header-widgets>
        <div class="body">
            <h6 class="your-title">Your Beneficiaries</h6>
            <ul class="list-unstyled manage-beneficiari-count d-flex justify-content-between">
                <li v-for="(beneficiari,i) in beneficiaris" :key="i" class="beneficiari-box d-flex flex-column align-items-center justify-content-center border border-dashed w-100" :class="beneficiari.classlist">
                    <p>{{ beneficiari.name }}</p><strong>{{ beneficiari.total_amount }}</strong>
                </li>
            </ul>
            <div class="action-btn">
                <b-button type="submit" variant="primary" size="lg">Manage Beneficiaries</b-button>
            </div>
        </div>
    </div>
</template>
<script>
const MoneyTransferHeader = require('@/components/admin/dashboard/dashboard_v2_widgets/MoneyTransferHeader').default
export default {
    name:'ManageBeneficiary',
    components:{
        'money-header-widgets':MoneyTransferHeader
    },data(){
        return{
            headerDetais:{
                image: require(`@/assets/img/icon-manage-beneficiaries.svg`),
                title:'Manage beneficiaries',
                subTitle:'Tell us who you want to send money to. Share the love!'
            },

            beneficiaris:[
                {
                    name:'Family',
                    total_amount:0,
                    classlist:''
                },
                {
                    name:'Friends',
                    total_amount:0,
                    classlist:'mx-4'
                },
                {
                    name:'Other',
                    total_amount:0,
                    classlist:''
                }
            ]
        }
    }
}
</script>