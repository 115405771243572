<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <open-modal-timer></open-modal-timer>
    </div>
</template>
<script>
const TimerModal = require('@/components/core/TimerModal.vue').default
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'ClientDComponent',
    components: {
        BreadCrumb,
        'open-modal-timer':TimerModal
    }
}
</script>