<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Event List -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow campaign-main account-tab">
                    <div class="header p-0 d-flex align-items-center justify-content-between">
                        <h2>Event List</h2>
                        <!-- Events Buttons -->
                        <div class="btn-grp d-flex">
                            <router-link to="/event/dashboard" class="btn btn-primary btn-lg ml-1 btn-mw">Add Event <i class="fa fa-plus-square"></i></router-link>
                            <b-button class="ml-1" size="lg" :variant="activeTab == 'grid' ? 'primary' : 'outline-primary'" @click="activeTabIs('grid')"><i class="fa fa-th-large"></i></b-button>
                            <b-button class="ml-1" size="lg" :variant="activeTab == 'list' ? 'primary' : 'outline-primary'"  @click="activeTabIs('list')"><i class="fa fa-bars"></i></b-button>
                        </div>
                    </div>
                    <div class="body px-0">
                        <div class="row row-deck grid-view" :class="{'active show': activeTab == 'grid'}">
                             <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" v-for="(data,index) in gridDatas" :key="index">
                                <div class="card">
                                    <div class="campaign-img">
                                        <img :src="data.eventImage" class="img-fluid rounded-top" alt="">
                                    </div>
                                    <div class="header">
                                        <h2>{{ data.title }}</h2>                        
                                        <p class="mb-0"><label class="mb-0">Date:</label> {{ data.date }}</p>
                                        <p class="mb-0"><label class="mb-0">Time:</label> {{ data.time }}</p>  
                                        <card-actions></card-actions>                                
                                    </div>
                                    <div class="body">
                                        <p>{{ data.description }}</p>
                                        <div class="d-flex justify-content-between align-items-center mb-2 chart-team-grp">
                                            <ul class="list-unstyled team-info mb-0 mt-0">
                                                <li class="m-r-15"><small class="text-muted">Team</small></li>
                                                <li v-for="(team,index) in data.teams" :key="index">
                                                    <img :src="team" title="Avatar" alt="Avatar">
                                                </li>
                                            </ul>
                                            <div class="right">
                                                <router-link to="#" class="btn btn-primary btn-lg ml-1">Book Ticket</router-link>
                                            </div>
                                        </div>
                                        <div class="progress progress-xs progress-transparent" :class="data.progressColor">
                                            <div class="progress-bar" :style="`width:${data.progressHeight}%`"></div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h6 class="mb-0 mt-2 text-uppercase">Target: {{ data.currencyType }}{{ data.target }}</h6>
                                            <h6 class="mb-0 mt-2 text-uppercase">Booked: {{ data.currencyType }}{{ data.raised }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row list-view" :class="{'active show': activeTab == 'list'}">
                            <div class="col-lg-12">
                                <b-table 
                                    responsive
                                    outlined
                                    table-class="mb-0 table-custom"
                                    head-variant="light"
                                    :items="items" 
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(event)="data">
                                        <h6 class="mb-0"><a href="javascript:void(0);">{{ data.value.name }}</a></h6>
                                        <small>Created {{ data.value.created }}</small>
                                    </template>
                                    <template #cell(progress)="data">
                                        <div class="progress progress-xs">
                                            <div class="progress-bar" role="progressbar" :aria-valuenow="data.value.progressbarWidth" aria-valuemin="0" aria-valuemax="100" :style="`width:${data.value.progressbarWidth}%`"></div>                                                
                                        </div>
                                        <small>Completion with: {{ data.value.progressbarWidth }}%</small>
                                    </template>
                                    <template #cell(teams)="data">
                                        <ul class="list-unstyled team-info">
                                            <li v-for="(imagesrc,index) in data.value" :key="index">
                                                <img :src="imagesrc" alt="Avatar">
                                            </li>
                                        </ul>
                                    </template>
                                    <template #cell(action)>
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item>Edit</b-dropdown-item>
                                            <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="currentPage" 
                                            :total-rows="totalRows" 
                                            :per-page="perPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page</span>
                                            </div>
                                            <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const CardActions = require('@/components/core/CardActions.vue').default
import '@/plugins/sweetalert2'

export default {
    name:'EventListComponent',
    components: {
        BreadCrumb,
        'card-actions': CardActions,
    },data(){
        return{
            activeTab: 'grid',
            //grid data
            gridDatas:[
                {
                    eventImage: require(`@/assets/image-gallery/1.jpg`),
                    title:'Down Town Grocery',
                    date:'Jun 2nd, 2021',
                    time:'10:00 - 07:00',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
                    teams:[
                       require(`@/assets/xs/avatar1.jpg`),
                       require(`@/assets/xs/avatar2.jpg`),
                       require(`@/assets/xs/avatar3.jpg`),
                       require(`@/assets/xs/avatar4.jpg`),
                       require(`@/assets/xs/avatar5.jpg`),
                    ],
                    progressColor:'custom-color-blue',
                    progressHeight:87,
                    target:'4,870',
                    raised:'4,236',
                },
                {
                    eventImage: require(`@/assets/image-gallery/2.jpg`),
                    title:'Chocolate Shop',
                    date:'Jun 8nd, 2021',
                    time:'11:00 - 07:00',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
                    teams:[
                        require(`@/assets/xs/avatar6.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                        require(`@/assets/xs/avatar1.jpg`),
                    ],
                    progressColor:'custom-color-purple',
                    progressHeight:34,
                    target:'2,170',
                    raised:'738',
                },
                {
                    eventImage: require(`@/assets/image-gallery/3.jpg`),
                    title:'The Filling Station',
                    date:'Sept 10th, 2021',
                    time:'05:00 - 15:00',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
                    teams:[
                        require(`@/assets/xs/avatar1.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                        require(`@/assets/xs/avatar3.jpg`),
                        require(`@/assets/xs/avatar6.jpg`),
                        require(`@/assets/xs/avatar5.jpg`),
                        require(`@/assets/xs/avatar4.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                    ],
                    progressColor:'custom-color-yellow',
                    progressHeight:54,
                    target:'8,000',
                    raised:'4,320',
                },
                {
                    eventImage: require(`@/assets/image-gallery/4.jpg`),
                    title:'Tinkers Hardware',
                    date:'Sept 10th, 2021',
                    time:'05:00 - 15:00',
                    description:'There are many variations of passages of Lorem Ipsum available, but the have suffered alteration in some form.',
                    teams:[
                        require(`@/assets/sm/avatar1.jpg`),
                        require(`@/assets/sm/avatar5.jpg`),
                    ],
                    progressColor:'custom-color-green',
                    progressHeight:67,
                    target:'3,370',
                    raised:'2,258',
                },
                {
                    eventImage: require(`@/assets/image-gallery/5.jpg`),
                    title:'Build School',
                    date:'Sept 20th, 2021',
                    time:'05:00 - 19:00',
                    description:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature.',
                    teams:[
                        require(`@/assets/sm/avatar2.jpg`),
                        require(`@/assets/sm/avatar6.jpg`),
                    ],
                    progressColor:'custom-color-blue',
                    progressHeight:87,
                    target:'5,100',
                    raised:'4,437',
                },
                {
                    eventImage: require(`@/assets/image-gallery/6.jpg`),
                    title:'Social Innovations',
                    date:'Oct 2nd, 2021',
                    time:'04:00 - 20:00',
                    description:'Lorem Ipsum is simply dummy text of the printing and industry. Lorem Ipsum has been the industrys standard.',
                    teams:[
                        require(`@/assets/sm/avatar1.jpg`),
                        require(`@/assets/sm/avatar2.jpg`),
                        require(`@/assets/xs/avatar4.jpg`),
                        require(`@/assets/xs/avatar6.jpg`),
                    ],
                    progressColor:'custom-color-blue',
                    progressHeight:55,
                    target:'11,000',
                    raised:'6,050',
                },
            ],

            //table data
            items:[
                {
                    event:{name:'Down Town Grocery',created:'14.Mar.2021'},
                    progress:{ progressbarWidth:48},
                    teams:[
                        require(`@/assets/xs/avatar1.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                        require(`@/assets/xs/avatar3.jpg`),
                        require(`@/assets/xs/avatar4.jpg`),
                        require(`@/assets/xs/avatar5.jpg`),
                    ],
                    action:'',
                },
                {
                    event:{name:'Chocolate Shop',created:'25.Mar.2021'},
                    progress:{ progressbarWidth:78},
                    teams:[
                        require(`@/assets/xs/avatar6.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                        require(`@/assets/xs/avatar1.jpg`),
                    ],
                    action:'',
                },
                {
                    event:{name:'The Filling Station',created:'14.Jun.2021'},
                    progress:{ progressbarWidth:29},
                    teams:[
                        require(`@/assets/xs/avatar1.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                        require(`@/assets/xs/avatar3.jpg`),
                        require(`@/assets/xs/avatar6.jpg`),
                        require(`@/assets/xs/avatar5.jpg`),
                        require(`@/assets/xs/avatar4.jpg`),
                        require(`@/assets/xs/avatar2.jpg`),
                    ],
                    action:'',
                },
                {
                    event:{name:'Tinkers Hardware',created:'01.July.2021'},
                    progress:{ progressbarWidth:13},
                    teams:[
                        require(`@/assets/sm/avatar1.jpg`),
                        require(`@/assets/sm/avatar5.jpg`),
                    ],
                    action:'',
                },
                {
                    event:{name:'Build School',created:'06.July.2021'},
                    progress:{ progressbarWidth:78},
                    teams:[
                        require(`@/assets/xs/avatar1.jpg`),
                    ],
                    action:'',
                },
                {
                    event:{name:'Social Innovations',created:'08.July.2021'},
                    progress:{ progressbarWidth:100},
                    teams:[
                        require(`@/assets/sm/avatar1.jpg`),
                        require(`@/assets/sm/avatar5.jpg`),
                        require(`@/assets/sm/avatar6.jpg`),
                    ],
                    action:'',
                },
            ],
           
            //table header
            fields: [
                { key: 'event', label: 'Event Name',sortable: true,thClass:'th_sortfix',tdClass:'project-title'},
                { key: 'progress', label: 'Progress',sortable: true,thClass:'th_sortfix'},
                { key: 'teams', label: 'Team',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',tdClass:'project-actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //table pagination
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },methods:{
        activeTabIs(currentTab){
            this.activeTab = currentTab
        },

        // Use sweetalert2
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },

        //go to in table pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        }
    },mounted() {
        this.totalRows = this.items.length
    }
}
</script>