<template>
    <div class="add-business">
        <!-- Add Partner -->
        <b-modal id="add_partner" title="Add Partner" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(addPartnerForm)" id="single-form">
                        <li>
                            <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addPartner.partnerName && addPartner.partnerName.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Partner*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addPartner.partnerName"  
                                            :show-labels="false" 
                                            :options="partnerOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addPartner.partnerName && addPartner.partnerName.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li v-if="addPartner.partnerName">
                            <ValidationProvider name="serviceName" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addPartner.serviceName && addPartner.serviceName.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Service</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addPartner.serviceName"  
                                            :show-labels="false" 
                                            :options="serviceOptions" 
                                            label="name"
                                            :searchable="false">
                                            <!-- @input="selectservice" -->
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addPartner.serviceName && addPartner.serviceName.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li v-if="addPartner.serviceName && addPartner.serviceName.name === 'bank'">
                            <ValidationProvider name="bankName" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addPartner.bankName && addPartner.bankName.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Bank</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addPartner.bankName"  
                                            :show-labels="false" 
                                            :options="bankOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addPartner.bankName && addPartner.bankName.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li v-if="addPartner.serviceName && addPartner.serviceName.name === 'mobilemoney' || addPartner.serviceName.name === 'mobile'">
                            <ValidationProvider name="mobileNetwork" v-slot="{ errors}">
                                <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':addPartner.mobileNetwork && addPartner.mobileNetwork.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                        <label for="functions">Select Mobile Network</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addPartner.mobileNetwork"  
                                            :show-labels="false" 
                                            :options="mobileNetworkOptions" 
                                            label="name"
                                            :searchable="false">
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="addPartner.mobileNetwork && addPartner.mobileNetwork.length != 0 && !errors[0]"></i>
                                    </span>
                                </div>
                            </ValidationProvider>
                        </li>
                        <li>
                            <ValidationProvider name="rank" rules="required" v-slot="{ errors}">
                                <div class="form-group" :class="{'errors': errors.length && !addPartner.rank || errorArr[1] && errorArr[1].fieldName!='','success':addPartner.rank && errorArr[1] && errorArr[1].fieldName==''}">
                                    <span class="float-label">
                                        <input type="text" v-model="addPartner.rank" class="form-control" id="name" placeholder="Rank*">
                                        <!--  @input="checkNameFormat($event, 'name', 'Owner First Name', 'single', 1)" -->
                                        <label for="owner" class="control-label">Rank*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors.length && !addPartner.rank || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                        <i class="fa fa-check" v-if="addPartner.rank && errorArr[1] && errorArr[1].fieldName==''"></i>
                                    </span>
                                    <!-- <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span> -->
                                </div>
                            </ValidationProvider>
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg" :disabled="btndesabled">Add Partner</b-button>
                            <b-button @click="$bvModal.hide('add_partner')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
import shared from "@/shared.js";

export default {
    name: 'AddPartnerModal',
    props: {
        buttonClass: String
    },components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data(){
        return{
            addPartner:{
                partnerName:'',
                serviceName:'',
                bankName:'',
                mobileNetwork:'',
                rank:''
            },
            selectedBusinessType: '',
            mobileInputError:'',

            partnerOptions: [],
            serviceOptions:[],
            bankOptions:[],
            mobileNetworkOptions:[],
            
            errorArr : [],

            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            elementName : '',
            apiErrorCode: '',
            formType: '',

            btndesabled: false,
        }
    },methods:{
        resetForm(){
            // clear single business form fields
            Object.keys(this.addPartner).forEach(v => this.addPartner[v] = '')
        },
        //add fund submit form data
        addPartnerForm(){
            this.btndesabled = true;
            let fundData = {
                countryid:sessionStorage.getItem("countryId"),
                partnerid:this.addPartner.partnerName.id,
                serviceid:this.addPartner.serviceName ? this.addPartner.serviceName.id : '',
                bankid:this.addPartner.bankName ? this.addPartner.bankName.id : '',
                mobilemoneyid:this.addPartner.mobileNetwork ? this.addPartner.mobileNetwork.id : '',
                rank:parseInt(this.addPartner.rank)
            }
            // console.log('add fund',fundData);

            this.$http.post(this.$baseurl+"partner/rule", fundData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '1101-000') {
                    shared.toastrSuccess(res.data.message);
                    this.$root.$refs.CountryDetailComponent.refreshPartnerTable();
                    this.$bvModal.hide('add_partner');
                    this.btndesabled = false;
                    this.apiErrorMsg = '';
                    this.apiErrorCode = '';
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
                this.btndesabled = false;
            });
        },

        async partnerList(){
            try {
                this.partnerOptions = [];
                const response = await this.$http.get(this.$baseurl+"partner/list", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.code == '1101-000'){
                    response.data.details.map((item) => {
                        let partnerLists = {
                            id:item.partnerid,
                            name:item.name,
                            type:item.partnertype
                        };
                        this.partnerOptions.push(partnerLists);
                    });
                }              
            } catch (error) {
                console.log(error);
            }
        },

        async serviceList(){
            try {
                this.serviceOptions = [];
                const response = await this.$http.get(this.$baseurl+"partner/services", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log('Service name',response);
                if(response.data.code == '1101-000'){
                    response.data.details.map((item) => {
                        let serviceLists = {
                            id:item.id,
                            name:item.name
                        };
                        this.serviceOptions.push(serviceLists);
                    });
                }              
            } catch (error) {
                console.log(error);
            }
        },

        async bankList(){
            try {
                this.bankOptions = [];
                const response = await this.$http.get(this.$baseurl+"middleware/banks/"+sessionStorage.getItem("countryId"), { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);

                if(response.data.code == '1101-000'){
                    response.data.details.map((item) => {
                        let bankLists = {
                            id:item.bankid,
                            name:item.name,
                            bankCode:item.bankcode,
                            routingnumber:item.routingnumber,
                            softcode:item.softcode,
                            brandlogo:item.brandlogo,
                            countryid:item.countryid,
                            addressid:item.addressid
                        };
                        this.bankOptions.push(bankLists);
                    });
                }              
            } catch (error) {
                console.log(error);
            }
        },

        async mmList(){
            try {
                this.mobileNetworkOptions = [];
                const response = await this.$http.get(this.$baseurl+"middleware/mno/"+sessionStorage.getItem("countryId"), { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);

                if(response.data.code == '1101-000'){
                    response.data.details.map((item) => {
                        let mmLists = {
                            id:item.mnoid,
                            name:item.name,
                            network:item.network
                        };
                        this.mobileNetworkOptions.push(mmLists);
                    });
                }              
            } catch (error) {
                console.log(error);
            }
        },

        // selectservice(){
        //     console.log(this.addPartner.serviceName);
        // }
    },mounted(){
        this.partnerList();
        this.serviceList();
        this.bankList();
        this.mmList();
    }
}
</script>
