<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <campaign-timeline
                            :fieldstep="3"
                        ></campaign-timeline>
                    </div>
                </div>
            </div>
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your campaign objectives and entice people to learn more. This basic information will represent your campaign on your campaign page, on your campaign card, and in searches.</p>
                            </li>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(campaignDataSubmit)" id="campaign-form" ref="form">
                                    <li>
                                        <div class="head">
                                            <label>Tags <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Enter up to five keywords that best describe your campaign. These tags will help with organization and discoverability."></i>
                                        </div>
                                        <ValidationProvider name="tags" rules="required" v-slot="{ errors}">
                                            <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !campaign.tags,'success':campaign.tags}">
                                                <span class="float-label">
                                                    <label for="functions">Tags</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="campaign.tags"  
                                                        :show-labels="false" 
                                                        :options="tags_options" 
                                                        :searchable="false">
                                                    </multiselect> 
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.tags"></i>
                                                    <i class="fa fa-check" v-if="campaign.tags"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="head">
                                            <label>Campaign Duration <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="How many days will you be running your campaign for? You can run a campaign for any number of days, with a 60 day duration maximum."></i>
                                        </div>
                                        <ValidationProvider name="campaign_duration" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !campaign.campaign_duration,'success':campaign.campaign_duration}">
                                                <span class="float-label">
                                                    <input type="text" v-model="campaign.campaign_duration" class="form-control" id="campaign_duration" placeholder="Campaign Duration">
                                                    <label for="campaign_duration" class="control-label">Campaign Duration*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.campaign_duration"></i>
                                                    <i class="fa fa-check" v-if="campaign.campaign_duration"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <h5>Pitch Video or Image</h5>
                                        <p>Add a video or image to appear on the top of your campaign page. Campaigns with videos raise 2000% more than campaigns without videos. Keep your video 2-3 minutes. <a class="no-decoration" href="#!">Learn how to make a great video.</a></p>
                                        <div class="btn-group video-img-btn" role="group" aria-label="Video or Image">
                                            <b-button class="btn-mw" size="lg" :variant="campaign.activeTab == 'video' ? 'primary' : 'outline-primary'" @click="activeTabIs('video')">Video</b-button>
                                            <b-button class="btn-mw" size="lg" :variant="campaign.activeTab == 'image' ? 'primary' : 'outline-primary'"  @click="activeTabIs('image')">Image</b-button>
                                        </div>
                                        <div class="body px-0 video-img-main">
                                            <div class="video-view" :class="{'active show': campaign.activeTab == 'video'}">
                                                <div class="head">
                                                    <label>Video <span class="text-danger">*</span></label>
                                                    <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="How many days will you be running your campaign for? You can run a campaign for any number of days, with a 60 day duration maximum."></i>
                                                </div>
                                                <ValidationProvider name="campaign_video" :rules="campaign.activeTab == 'video' ? 'required' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !campaign.campaign_video,'success':campaign.campaign_video}">
                                                        <div class="custom-file">
                                                            <input type="hidden" name="campaign_video" v-model="campaign.campaign_video" accept="image/*">
                                                            <input id="file-input" type="file" accept="video/*"  class="form-control custom-file-input" ref="resetVideoFile">
                                                            <label class="custom-file-label" for="file-input"><span class="form-file-text d-block">Choose Video</span></label>
                                                            <i class="fa fa-times" v-if="errors.length && !campaign.campaign_video"></i>
                                                            <i class="fa fa-check" v-if="campaign.campaign_video"></i>
                                                        </div>
                                                    </div>
                                                    <div id="video_preview" v-if="campaign.isemptyVideo">
                                                        <video id="video" controls>
                                                            <source :src="campaign.campaignVideoUrl" type="video/mp4">
                                                        </video>
                                                        <b-button @click="removeVideo">Remove Video</b-button>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="image-view" :class="{'active show': campaign.activeTab == 'image'}">
                                                <div class="head">
                                                    <label>Image <span class="text-danger">*</span></label>
                                                    <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="How many days will you be running your campaign for? You can run a campaign for any number of days, with a 60 day duration maximum."></i>
                                                </div>
                                                <ValidationProvider name="pitchImgage" :rules="campaign.activeTab == 'image' ? 'required' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !campaign.pitchImgage,'success':campaign.pitchImgage}">
                                                        <div class="custom-file">
                                                            <input type="hidden" name="pitchImgage" v-model="campaign.pitchImgage" accept="image/*">
                                                            <input id="pitchImgage" type="file" accept="image/*"  class="form-control custom-file-input" ref="resetPitchInput" @change="pitchImageChange">
                                                            <label class="custom-file-label" for="pitchImgage">Choose Image</label>
                                                            <i class="fa fa-times" v-if="errors.length && !campaign.pitchImgage"></i>
                                                            <i class="fa fa-check" v-if="campaign.pitchImgage"></i>
                                                        </div>
                                                    </div>
                                                    <div id="preview_pitch" v-if="campaign.pitchImgage">
                                                        <img :src="campaign.pitchImgage"/>
                                                        <b-button @click="removePitchImage">Remove image</b-button>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                                        <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const CampaignTimeline = require('@/components/core/CampaignTimeline').default
import "@/plugins/vee-validate";
export default {
    name:'AddCampaignForm4',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'campaign-timeline':CampaignTimeline
    },data(){
        return{
            campaign:{
                tags:null,
                campaign_duration:'',
                activeTab:'video',
                pitchImgage: null,
                campaign_video:null,
                isemptyVideo:false,
                campaignVideoUrl:null
            },

             tags_options:[
                '3d',
                '3d printers',
                'adventure',
                'air purifiers',
                'bags'
            ]
        }
    },methods: {
        //submit form data
        campaignDataSubmit(){
            this.$router.push({ path: '/crowdfunding/campaign-faq' })
        },

        //check which tab is active
        activeTabIs(currentTab){
            this.campaign.activeTab = currentTab
        },

        //remove video
        removeVideo(){
            this.$refs.resetVideoFile.value = null;
            var video = document.getElementById('video');
            video.src = "";
            this.campaign.isemptyVideo = false
            this.campaign.campaign_video = null;
        },

        //pitch image upload
        async pitchImageChange(e){
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this.campaign.pitchImgage = URL.createObjectURL(file);
                this.campaign.campaign_video = await null
                this.campaign.isemptyVideo = false
            }
        },

        //remove pitch image
        removePitchImage: function () {
            this.$refs.resetPitchInput.value = null;
            this.campaign.pitchImgage = null;
        },
    },mounted(){
        //play video
        let input = document.getElementById('file-input');
        input.addEventListener('change', (e)=> {           
            let files = e.target.files || [];
            if (!files.length) return;
            this.campaign.isemptyVideo = true
            this.campaign.campaignVideoUrl =null
            this.campaign.campaign_video = null
            this.campaign.campaignVideoUrl = URL.createObjectURL(files[0])
            this.campaign.campaign_video = files[0]
            this.campaign.pitchImgage = null
            let video = document.getElementById('video');
            if(video){
                video.load();
            }
        });
    }
}
</script>
