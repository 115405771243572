var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header",attrs:{"id":"header"}},[(!(_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2'))?_c('div',{staticClass:"top-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-end"},_vm._l((_vm.client_details),function(detail,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._l((detail.images),function(image,index){return _c('img',{key:index,attrs:{"src":image,"width":"18","alt":""}})}),_c('span',[_vm._v(_vm._s(detail.number))])],2)])}),0)])])])]):_vm._e(),_c('div',{staticClass:"main-menu"},[_c('div',{staticClass:"container-fluid"},[_c('b-navbar',{staticClass:"bg-light p-0",attrs:{"toggleable":"lg"}},[(!(_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2'))?_c('b-navbar-brand',{staticClass:"header-name",attrs:{"to":_vm.currentLayout == 'customer-layout' ? '/customer-home' : '/home'}},[_c('img',{attrs:{"src":_vm.navbar_details.brand_logo,"width":"223","alt":"Payangel Business"}})]):_vm._e(),_c('b-navbar-toggle',{attrs:{"target":"navbarColor01"}}),_c('b-collapse',{attrs:{"id":"navbarColor01","is-nav":""}},[_c('b-nav',{staticClass:"navbar-nav ml-auto"},[_vm._l((_vm.navbar_details.navbar_menus),function(menu,index){return _c('b-nav-item',{key:index},[_vm._v(_vm._s(menu))])}),_c('b-nav-item-dropdown',{staticClass:"country-dropdown",attrs:{"variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{class:_vm.navbar_details.country_dropdawn.imageClass}),_vm._v(_vm._s(_vm.navbar_details.country_dropdawn.text_title)+" ")]},proxy:true}])},_vm._l((_vm.navbar_details.country_dropdawn.citys),function(city,index){return _c('b-dropdown-item',{key:index,attrs:{"href":"#","active":index == _vm.navbar_details.country_dropdawn.active_index},on:{"click":function($event){return _vm.activeCountry(index)}}},[_c('span',{class:city.imageClass,attrs:{"alt":""}}),_vm._v(_vm._s(city.name)+" ")])}),1),_c('b-nav-item-dropdown',{staticClass:"lang-dropdown",attrs:{"variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.navbar_details.language_dropdawn.text_title)+" ")]},proxy:true}])},_vm._l((_vm.navbar_details.language_dropdawn.citys),function(city,index){return _c('b-dropdown-item',{key:index,attrs:{"href":"#","active":index == _vm.navbar_details.language_dropdawn.active_index},on:{"click":function($event){return _vm.activeLang(index)}}},[_vm._v(" "+_vm._s(city.name)+" ")])}),1),((!(_vm.currentLayout == 'auth-v2-layout' && 
                            (_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2')) &&
                            _vm.currentLayout != 'customer-layout'))?_c('b-nav-form',[_c('a',{staticClass:"nav-link btn btn-outline",attrs:{"href":"#","target":"_self"}},[_vm._v("Log in to Personal")]),_c('router-link',{staticClass:"nav-link btn btn-login",attrs:{"to":"/authentication/page-login"}},[_vm._v("Log in")])],1):_vm._e(),(_vm.currentLayout == 'customer-layout' && _vm.currentUrl != 'customer')?_c('b-nav-form',[_c('router-link',{staticClass:"nav-link btn btn-outline",attrs:{"to":"/customer/login"}},[_vm._v("Log in to Personal")]),_c('b-button',{staticClass:"nav-link btn btn-login",on:{"click":_vm.openSignInModal}},[_vm._v("Login/Register")])],1):_vm._e()],2)],1)],1)],1)]),_c('auth-login-register-modal'),_c('mobile-number-varify-modal'),_c('user-details-modal'),_c('set-password-modal'),_c('email-varification-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }