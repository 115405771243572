<template>
    <b-modal id="send_money" title="Send Money by" hide-footer hide-header centered>
        <div class="modal-body">
            <div class="header pb-3 text-center">
                <h5 class="px-3">Hello Jones,welcome to PayAngel.</h5>
                <p class="text-center mb-0">The future of sending money in Africa</p>
            </div>
            <div class="body">
                <h6 class="mb-4 text-center">What will you want to do next?</h6>
                <ul class="list-unstyled send-money-list mb-0">
                    <li v-for="(sendmoney,i) in sendMoneyOption" :key="i">
                        <div class="d-flex align-items-center mt-4">
                            <div class="left">
                                <i :class="sendmoney.icon"></i>
                            </div>
                            <div class="center">
                                <h5 class="mb-0">{{ sendmoney.title }}</h5>
                            </div>
                            <div class="right ml-auto">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    name:'SendingMoneyModal',
    data(){
        return{
            sendMoneyOption:[
                {
                    title:'Send Money',
                    icon:'fa fa-dollar'
                },
                {
                    title:'Add Receivers',
                    icon:'fa fa-user'
                },
                {
                    title:'Add a Bank Card',
                    icon:'fa fa-credit-card'
                },
                {
                    title:'View my Spending Limit',
                    icon:'fa fa-file-text-o'
                },
                {
                    title:'Redeem my Referral Code',
                    icon:'fa fa-gift'
                }
            ]
        }
    },mounted() {
        this.$bvModal.show('send_money')
    }
}
</script>