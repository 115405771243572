<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <events-timeline :eventStep="4"></events-timeline>
                    </div>
                </div>
            </div>
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your event objectives and entice people to learn more. This basic information will represent your event on your event page, on your event card, and in searches.</p>
                            </li>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(faqDataSubmit)" id="event-form" ref="form">
                                    <li>
                                        <h5>FAQ <span class="text-danger">*</span></h5>
                                        <p>The FAQ section should provide the most common details that backers are looking for when evaluating your event. We will also provide common answers to questions about crowdfunding and how Angel Communities works.</p>
                                    </li>
                                    <li>
                                        <div v-for="(faqdata,index) in faqdatas" :key="index">
                                            <div>
                                                <div class="head">
                                                    <label>Qestion</label>
                                                    <i class="fa fa-times text-danger pull-right" @click="removeRow(index)" v-if="index !== 0"></i>
                                                </div>
                                                <ValidationProvider :name="'question_'+ index" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !faqdata.question,'success':faqdata.question}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="faqdata.question" class="form-control" :id="'question_'+ index" placeholder="Qestion*">
                                                            <label for="question" class="control-label">Qestion*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !faqdata.question"></i>
                                                            <i class="fa fa-check" v-if="faqdata.question"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div>
                                                <div class="head">
                                                    <label>Answer</label>
                                                </div>
                                                <ValidationProvider :name="'answer_' + index" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !faqdata.answer,'success':faqdata.answer}">
                                                        <span class="float-label">
                                                            <textarea v-model="faqdata.answer" class="form-control" :id="'answer_' + index" placeholder="Answer*"></textarea>
                                                            <label for="answer" class="control-label">Answer*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !faqdata.answer"></i>
                                                            <i class="fa fa-check" v-if="faqdata.answer"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div id="add_question" class="mb-3" @click="addFaq">
                                            <b-button variant="primary btn-mw" size="lg">
                                                <i class="fa fa-plus-circle fa-lg mr-2"></i>
                                                <span class="my-auto">ADD ANOTHER QUESTION</span>
                                            </b-button>
                                        </div>
                                    </li>
                                    <div class="d-flex flex-column flex-md-row justify-content-md-end justify-content-center">
                                        <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const EventTimeline = require('@/components/core/EventTimeline').default
import "@/plugins/vee-validate";

export default {
    name:'EventForm5Component',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        'events-timeline':EventTimeline
    },data(){
        return{
            faqdatas:[
                {
                    question:'',
                    answer:''
                }  
            ],
        }
    },methods: {
        //submit form data
        faqDataSubmit(){
            console.log("form data submitted");
        },
        
        //add faq
        addFaq() {
            this.faqdatas.push({
                question: '',
                answer: ''
            })
        },

        //remove faq
        removeRow: function(index){
            this.faqdatas.splice(index, 1);
        },
    }
}
</script>
