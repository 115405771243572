<template>
    <div class="card card-top-line my-profile-card">
        <money-header-widgets
            :img = "headerDetais.image"
            :title = "headerDetais.title"
            :subTitle = "headerDetais.subTitle"
            :isActions = false
        ></money-header-widgets>
        <div class="body">
            <div class="profile-progress">
                <p class="mb-0"><strong>50%</strong> Complete</p>
                <div class="progress mb-1">
                    <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-success" style="width: 50%;"></div>
                </div>
                <small class="text-muted mt-1">Just 2 Step to complete your profile<router-link to="/profile/edit-profile" class="text-primary ml-1"><i class="fa fa-link"></i></router-link></small>
            </div>
            <ul class="list-unstyled profile-timeline d-flex">
                <li v-for="(profileTimeline,i) in profileTimelines" :key="i" :class="profileTimeline.classList">
                    <p><span>{{ profileTimeline.name }}</span><strong>{{ profileTimeline.amount }}</strong></p>
                    <!-- <p><span v-b-popover.hover="profileTimeline.amount" :title="profileTimeline.name">{{ profileTimeline.name }}</span></p> -->
                </li>
            </ul>
            <div class="action-btn">
                <router-link to="/profile/edit-profile" class="btn btn-secondary btn-lg">Complete Profile</router-link>
                <b-button type="submit" variant="danger" size="lg">Increase Daily Limit</b-button>
            </div>
        </div>
    </div>
</template>
<script>
const MoneyTransferHeader = require('@/components/admin/dashboard/dashboard_v2_widgets/MoneyTransferHeader').default
export default {
    name:'MyProfile',
    components:{
        'money-header-widgets':MoneyTransferHeader
    },data(){
        return{
            headerDetais:{
                image: require(`@/assets/img/icon-my-profile.svg`),
                title:'My Profile',
                subTitle:'Keep your profile up to date so we can serve you better.'
            },

            profileTimelines:[
                {
                    name:'Basic',
                    amount:'£430.00',
                    classList:'filled'
                },
                {
                    name:'Bronze',
                    amount:'£2,300.00',
                    classList:'active'
                },
                {
                    name:'Silver',
                    amount:'£4,400.00',
                    classList:'inactive'
                },
                {
                    name:'Silver+',
                    amount:'£30,000.00',
                    classList:'inactive'
                }
            ]
        }
    }
}
</script>