<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="user-account-main add-money-content">
                            <ul class="list-unstyled mb-0 user-account-details">
                                <b-form @submit.prevent="userDetails">
                                    <li class="my-4">
                                        <div class="d-md-flex align-items-center">
                                            <label class="mb-0 mr-5">GBP</label>
                                            <div class="right ml-auto">
                                                <b-form-group class="mb-0" label-for="input-gbp">
                                                    <div class="d-flex">
                                                        <div class="form-group form-select multiselect-grp mb-0">
                                                            <span class="float-label">
                                                                <multiselect
                                                                    v-model="user.curancy"
                                                                    :show-labels="false"
                                                                    :options="curancys_options"
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    :custom-label="nameWithAmount"
                                                                    :preselect-first="true">
                                                                    <template slot="option" slot-scope="props">
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}: {{ props.option.symbol }}{{ props.option.amount }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                            </span>
                                                        </div>
                                                        <b-form-input id="input-gbp" placeholder="£50"></b-form-input>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card transfers-list shadow-all">
                                            <div class="card-body">
                                                <div class="d-flex align-items-center">
                                                    <div class="left"><i aria-hidden="true" class="fa fa-exchange"></i></div>
                                                    <div class="right">
                                                        <strong class="font-16">Easy bank transfer</strong>
                                                        <p>Easy and secure without entering account details</p>
                                                    </div>
                                                    <a class="ml-auto btn btn-primary btn-lg" href="javascript:void(0);">Change</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-end">
                                        <b-button class="mr-1 btn-mw" size="lg" @click="$router.go(-1)">Back</b-button>
                                        <b-button type="submit" variant="primary btn-mw" size="lg">Add £50</b-button>
                                    </li>
                                </b-form>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Multiselect = require('vue-multiselect').default

export default {
    name:'ExchangeComponent',
    components: {
        BreadCrumb,
        Multiselect,
    },data (){
        return{
           curancys_options: [
                { name: 'Main', symbol:'₵', amount:'70,548.00'},
                { name: 'UK Office', symbol:'£', amount:'50,630.50'},
                { name: 'US Office' , symbol:'$', amount:'32,540.30'},
                { name: 'European Office', symbol:'€', amount:'42,540.30'},
            ],

            user:{
                curancy: null
            } 
        }
    },methods: {
        userDetails(){
            console.log("submit form");
        },

        nameWithAmount ({ name, symbol,amount }) {
            return `${name}: ${symbol}${amount} `
        },
    }
}

</script>