<template>
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card card-top-line">
                        <div class="header">
                            <h2>Single Invoice</h2>
                            <ul class="header-dropdown">
                                <li class="dropdown"  v-bind:class="{ 'show': cardToggle}"  @click="cardToggle = !cardToggle" v-click-outside="cardToggleOutside">
                                    <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"></a>
                                    <ul class="dropdown-menu dropdown-menu-right" v-bind:class="{ 'show': cardToggle }">
                                        <li><a href="javascript:void(0);">Print Invoices</a></li>
                                        <li role="presentation" class="divider"></li>
                                        <li><a href="javascript:void(0);">Export to XLS</a></li>
                                        <li><a href="javascript:void(0);">Export to CSV</a></li>
                                        <li><a href="javascript:void(0);">Export to XML</a></li>
                                    </ul>
                                </li>
                            </ul>                            
                        </div>
                        <div class="body">
                            <h3>Invoice Details : <strong class="text-primary">#A0089</strong></h3>
                            
                            <ul class="nav nav-tabs-new2">
                                <li class="nav-item inlineblock"><a class="nav-link" v-on:click="setActive('Details')" :class="{ active:isActive('Details') }">Details</a></li>                                
                                <li class="nav-item inlineblock"><a class="nav-link" v-on:click="setActive('History')" :class="{ active:isActive('History') }">History</a></li>
                            </ul>
                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane in" id="details" aria-expanded="true" :class="{ active:isActive('Details') }">
                                    <div class="row clearfix">
                                        <div class="col-md-6 col-sm-12">
                                            <address>
                                                <strong>ThemeMakker Inc.</strong><br>
                                                795 Folsom Ave, Suite 546<br>
                                                San Francisco, CA 54656<br>
                                                United States<br>
                                                <strong title="Phone mt-1">Phone:</strong> (123) 456-34636<br>
                                                <strong title="Email">Email:</strong> jones@payangel.com
                                            </address>
                                        </div>
                                        <div class="col-md-5 col-sm-9 text-right">
                                            <p class="m-b-0"><strong>Order Date: </strong> Jun 15, 2018</p>
                                            <p class="m-b-0"><strong>Order Status: </strong> <span class="badge badge-warning m-b-0">Pending</span></p>
                                            <p><strong>Order ID: </strong> #123456</p>
                                        </div>
                                        <div class="col-md-1 col-sm-3 text-right">
                                            <qrcode-vue :value="value" :size="size" level="H" />
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-12">
                                            <b-table 
                                                responsive 
                                                hover  
                                                outlined 
                                                table-class="js-basic-example table-custom mb-0"
                                                head-variant="light"
                                                :items="detailsItems" 
                                                :fields="detailsFields"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection">
                                                <template #head()="{label,  field: { key }}">
                                                    {{ label }}
                                                    <!-- Custom icons -->
                                                    <template>
                                                        <i v-if="sortBy !== key" class=""></i>
                                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                    </template>  
                                                </template>
                                                <template #cell(#)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row clearfix">
                                        <div class="col-md-6">
                                            <h5>Note</h5>
                                            <p>Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.</p>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <p class="m-b-0"><b>Sub-total:</b> 2930.00</p>
                                            <p class="m-b-0">Discout: 12.9%</p>
                                            <p class="m-b-0">VAT: 12.9%</p>                                        
                                            <h3 class="m-b-0 m-t-10">USD 2930.00</h3>
                                        </div>                                    
                                        <div class="hidden-print col-md-12 text-right" v-if="!isApproveInvoice">
                                            <hr>
                                            <router-link to="/invoice/add-invoice-item" class="btn btn-secondary mr-1 btn-mw btn-lg">Edit</router-link>
                                            <button class="btn btn-primary btn-mw btn-lg" @click="approvedInvoice()">Approve</button>
                                        </div>
                                        <!-- set schedule modal-->
                                        <b-modal id="schedule" title="Schedule" hide-footer>
                                            <div class="modal-area">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="schedule" @submit.prevent="passes(scheduleSubmitForm)">
                                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                                            <li>
                                                                <ValidationProvider name="frequency" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.frequency,'success':schedule.frequency}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select a Frequency*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="schedule.frequency"  
                                                                                :show-labels="false" 
                                                                                :options="frequencyOptions" 
                                                                                :searchable="false">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && !schedule.frequency"></i>
                                                                            <i class="fa fa-check" v-if="schedule.frequency"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li v-if="schedule.frequency == 'Weekly'">
                                                                <ValidationProvider name="days" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.days,'success':schedule.days}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select a days*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="schedule.days"  
                                                                                :show-labels="false" 
                                                                                :options="daysOptions" 
                                                                                :searchable="false">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && !schedule.days"></i>
                                                                            <i class="fa fa-check" v-if="schedule.days"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li v-if="schedule.frequency == 'Custom'">
                                                                <ValidationProvider name="daysAfter" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.daysAfter,'success':schedule.daysAfter}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select days frequncy*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="schedule.daysAfter"  
                                                                                :show-labels="false" 
                                                                                :options="daysAfterOptions" 
                                                                                :searchable="false">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && !schedule.daysAfter"></i>
                                                                            <i class="fa fa-check" v-if="schedule.daysAfter"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="startDate" rules="required" v-slot="{ errors }">
                                                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.startDate && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <b-input-group>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Start Date*</span>
                                                                                    <span class="input-group-text" @click="$refs.startdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                                </div>
                                                                                <datepicker ref="startdate"  class="form-control" :bootstrap-styling="false" v-model="schedule.startDate" :typeable="true"></datepicker>
                                                                            </b-input-group>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="schedule.startDate && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="endDate" rules="required" v-slot="{ errors }">
                                                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.endDate && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <b-input-group>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">End Date*</span>
                                                                                    <span class="input-group-text" @click="$refs.endDate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                                </div>
                                                                                <datepicker ref="endDate"  class="form-control" :bootstrap-styling="false" v-model="schedule.endDate" :typeable="true"></datepicker>
                                                                            </b-input-group>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="schedule.endDate && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="endAfter" rules="required" v-slot="{ errors }">
                                                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':schedule.endAfter && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <b-input-group>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">End After*</span>
                                                                                    <span class="input-group-text" @click="$refs.endAfter.showCalendar()"><i class="icon-calendar"></i></span>
                                                                                </div>
                                                                                <datepicker ref="endAfter"  class="form-control" :bootstrap-styling="false" v-model="schedule.endAfter" :typeable="true"></datepicker>
                                                                            </b-input-group>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="schedule.endAfter && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="timezone" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !schedule.timezone,'success':schedule.timezone}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Select a timezone*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="schedule.timezone"  
                                                                                :show-labels="false" 
                                                                                :options="timezoneOptions" 
                                                                                :searchable="false">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && !schedule.timezone"></i>
                                                                            <i class="fa fa-check" v-if="schedule.timezone"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Set Schedule</b-button>
                                                                    <b-button @click="$bvModal.hide('schedule')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                </ValidationObserver>
                                            </div>
                                        </b-modal>
                                        <div class="hidden-print col-md-12 text-right" v-if="isApproveInvoice">
                                            <hr>
                                            <router-link to="/invoice/list" class="btn btn-secondary mr-1 btn-mw btn-lg">Back to Invoices</router-link>
                                            <button class="btn btn-primary btn-mw btn-lg mr-1" @click="copyLink()">Copy Link</button>
                                            <button class="btn btn-info btn-mw btn-lg mr-1">Download</button>
                                            <button class="btn btn-warning btn-mw btn-lg" v-b-modal.send_email>Send Email</button>
                                        </div>
                                        <!-- send emails modal-->
                                        <b-modal id="send_email" title="Send Emails" hide-footer>
                                            <div class="modal-area">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="send_emails" @submit.prevent="passes(sendEmails)">
                                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                                            <li>   
                                                                <ValidationProvider name="emails" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors.length && !invoice.emails,'success':invoice.emails}">
                                                                        <span class="float-label">
                                                                            <textarea type="text" v-model="invoice.emails" class="form-control" id="emails" placeholder=""/>
                                                                            <label for="emails" class="control-label">Send Email to*</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && !invoice.emails"></i>
                                                                            <i class="fa fa-check" v-if="invoice.emails"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <ValidationProvider name="reminders" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !invoice.reminders,'success':invoice.reminders}">
                                                                        <span class="float-label">
                                                                            <label for="functions">Set reminders*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="invoice.reminders"  
                                                                                :show-labels="false" 
                                                                                :options="remindersOptions" 
                                                                                :searchable="false">
                                                                            </multiselect> 
                                                                            <i class="fa fa-times" v-if="errors.length && !invoice.reminders"></i>
                                                                            <i class="fa fa-check" v-if="invoice.reminders"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </li>
                                                            <li>
                                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Send Email</b-button>
                                                                    <b-button @click="$bvModal.hide('send_email')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                </ValidationObserver>
                                            </div>
                                        </b-modal>
                                    </div>                                    
                                </div>                        
                                <div role="tabpanel" class="tab-pane" id="history" aria-expanded="false" :class="{ active:isActive('History') }">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <address>
                                                <strong>ThemeMakker Inc.</strong><br>
                                                795 Folsom Ave, Suite 546<br>
                                                San Francisco, CA 54656<br>
                                                <abbr title="Phone">P:</abbr> (123) 456-34636
                                            </address>
                                        </div>
                                        <div class="col-md-5 col-sm-9 text-right">
                                            <p class="m-b-0"><strong>Order Date: </strong> Jun 15, 2018</p>
                                            <p class="m-b-0"><strong>Order Status: </strong> <span class="badge bg-orange">Pending</span></p>
                                            <p><strong>Order ID: </strong> #123456</p>
                                        </div>
                                        <div class="col-md-1 col-sm-3 text-right">
                                            <qrcode-vue :value="value" :size="size" level="H" />
                                        </div>
                                    </div>
                                    <div class="mt-40"></div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-table 
                                                responsive 
                                                hover  
                                                outlined 
                                                table-class="js-basic-example table-custom mb-0"
                                                head-variant="light"
                                                :items="historyItems" 
                                                :fields="historyFields"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection">
                                                <template #head()="{label,  field: { key }}">
                                                    {{ label }}
                                                    <!-- Custom icons -->
                                                    <template>
                                                        <i v-if="sortBy !== key" class=""></i>
                                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                    </template>  
                                                </template>
                                                <template #cell(status)="data">
                                                    <span class="badge" :class="data.value.class">{{ data.value.title }}</span>
                                                </template>
                                                <template #cell(#)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>                                    
                                </div>                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import QrcodeVue from 'qrcode.vue'
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const Datepicker = require('vuejs-datepicker').default

export default {
    name:'InvoicePreviewComponent',
    components: {
        BreadCrumb,
        QrcodeVue,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        Datepicker
    },data() {
        return {
            isApproveInvoice:false,
            activeTab: 'Details',
            cardToggle:false,
            value: 'https://example.com',
            size: 60,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            //Invoice list table json
            detailsItems:[
                {name:'Simple Black Clock',description:'Lorem ipsum dolor sit amet.',quantity:'1',unitCost:'$380',total:'$380'},
                {name:'Brone Candle',description:'There are many variations of passages of Lorem Ipsum',quantity:'5',unitCost:'$50',total:'$250'},
                {name:'Wood Simple Clock',description:'Lorem ipsum dolor sit amet.',quantity:'2',unitCost:'$500',total:'$1000'},
                {name:'Unero Small Bag',description:'Contrary to popular belief, not simply random text',quantity:'3',unitCost:'$300',total:'$900'},
            ],
           
            //Invoice list table header fields
            detailsFields: [
                '#',
                { key: 'name', label: 'Item',sortable: true,thClass:'th_sortfix'},
                { key: 'description', label: 'Description',sortable: true,thClass:'th_sortfix'},
                { key: 'quantity', label: 'Quantity',sortable: true,thClass:'th_sortfix'},
                { key: 'unitCost', label: 'Unit Cost',sortable: true,thClass:'th_sortfix'},
                { key: 'total', label: 'Total'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Invoice list table json
            historyItems:[
                {name:'Invoice Created',date:'18 Dec, 2017',status:{title:'PANDING',class:'badge-default'}},
                {name:'Invoice Sent',date:'19 Dec, 2017',status:{title:'PANDING',class:'badge-default'}},
                {name:'Invoice Paid',date:'20 Dec, 2017',status:{title:'SUCCESS',class:'badge-success'}},
            ],
           
            //Invoice list table header fields
            historyFields: [
                '#',
                { key: 'name', label: 'Item',sortable: true,thClass:'th_sortfix'},
                { key: 'date', label: 'Date',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            invoice:{
                emails:'',
                reminders:['No remainder']
            },

            remindersOptions:[
                'No Remainders',
                'Daily', 
                'Weekly', 
                'Monthly'
            ],
            
            invoiceType: this.$route.query.type,
            schedule:{
                frequency:'',
                startDate:null,
                daysAfter:null,
                days:null,
                endDate:null,
                endAfter:null,
                timezone:null
            },
            frequencyOptions:['Daily', 'Weekly', 'Monthly', 'Yearly','Custom'],
            timezoneOptions:[
                '(UTC-12:00) International Date Line West',
                '(UTC-11:00) Coordinated Universal Time-11',
                '(UTC-10:00) Hawaii',
                '(UTC-09:00) Alaska',
                '(UTC-08:00) Baja California',
                '(UTC-07:00) Pacific Time (US &amp; Canada)',
                '(UTC-08:00) Pacific Time (US &amp; Canada)',
                '(UTC-07:00) Arizona',
                '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
                '(UTC-07:00) Mountain Time (US &amp; Canada)',
                '(UTC-06:00) Central America',
                '(UTC-06:00) Central Time (US &amp; Canada)',
                '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
                '(UTC-06:00) Saskatchewan',
                '(UTC-05:00) Bogota, Lima, Quito'
            ],
            daysOptions:[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            daysAfterOptions:[
                'Every 2 Days',
                'Every 3 Days',
                'Every 4 Days',
                'Every 5 Days',
                'Every 10 Days',
                'Every 15 Days',
                'Every 20 Days'
            ]
        }
    },methods:{
        isActive: function (activeTab) {
            return this.activeTab === activeTab
        },

        setActive: function (activeTab) {
            this.activeTab = activeTab 
        },

        cardToggleOutside: function() {
            this.cardToggle = false
        },

        approvedInvoice(){
            if(this.invoiceType == 'recurring'){
                this.$bvModal.show('schedule');
            }else{
                this.isApproveInvoice = true
            }
        },

        copyLink(){
            var inputofKey = document.createElement("input");
            inputofKey.value = 'https://192.168.225.29:8080/invoice/add-invoice-item';
            document.body.appendChild(inputofKey);
            inputofKey.select();
            document.execCommand("Copy");
            inputofKey.remove();
        },

        sendEmails(){
            this.$bvModal.hide('send_email');
        },

        scheduleSubmitForm(){
            this.isApproveInvoice = true
            this.$bvModal.hide('schedule');
        }
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }
}
</script>