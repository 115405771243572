// initial state
const state = () => ({
    resetPasswordDetail: {
        userEmail: ''
    }
})

// getters
const getters = {}

// actions
const actions = {
    addEmail ({ commit }, userContactEmail) {
        commit('setResetEmail', userContactEmail)
    }
}

// mutations
const mutations = {
    setResetEmail (state, personalEmail) {
        state.resetPasswordDetail.userEmail = personalEmail.email
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}