<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card no-shadow">
                     <!-- Header section-->
                    <div class="header">
                        <h2>{{businessName}}</h2>
                    </div>
                    <div class="body pt-0">
                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <!-- Subsidiaries Tab -->
                            <b-tab title="Subsidiaries">
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="subsidiarySearchText" placeholder="Search Subsidiaries" aria-label="Search Subsidiaries" aria-describedby="search-subsidiaries" v-on:keyup.enter="searchSubsidiaryData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-invoices"><i class="icon-magnifier" @click="searchSubsidiaryData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <!-- <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <b-button class="btn btn-primary btn-lg btn-mw" @click="showSubsidiaryForm('subsidiary')">Add Subsidiary <i class="fa fa-plus-square ml-1"></i></b-button>
                                        </div> -->
                                    </div>
                                </div>
                                <b-table 
                                    ref = "table"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getSubsidiaryList" 
                                    :fields="subsidiaries_fields"
                                    :current-page="subsidiariesCurrentPage"
                                    :per-page="subsidiariesPerPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(uuid)="data">
                                        <b-button 
                                            id="button1" 
                                            variant="outline-primary" 
                                            size="sm" 
                                            v-b-tooltip.hover
                                            :data-original-title="data.value.id"
                                            :title="data.value.id" 
                                            @click.prevent="copyTooltip($event)">
                                            <i class="fa fa-2x fa-barcode"></i>
                                        </b-button>
                                    </template>
                                    <template #cell(businessname)="row">
                                        {{ row.value }}
                                        <!-- <router-link class="font-color font-weight-bold" to="/business/subsidiaries-detail">{{ row.value }} <i class="fa fa-link text-theme"></i></router-link> -->
                                    </template>
                                    <template #cell(status)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                        </p>
                                    </template>
                                    <template #cell(action)="data">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'approved')">Approved</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'rejected')">Rejected</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'freezed')">Freezed</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'suspended')">Suspended</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'dormant')">Dormant</b-dropdown-item>
                                            <b-dropdown-item @click="deleteItem(data.item.uuid)">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="subsidiariesTotalRows > 0?' d-flex':'d-none'">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select"  v-model="subsidiariesPerPage" :options="subsidiariesPageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="subsidiariesCurrentPage" 
                                            :total-rows="subsidiariesTotalRows" 
                                            :per-page="subsidiariesPerPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page </span>
                                            </div>
                                            <input type="text" v-model="subsidiariesCurrentPageJump" class="form-control" @keyup.enter="subsidiariesOnEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- Clients Tab -->
                            <b-tab title="Clients">
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="clientSearchText" placeholder="Search Clients" aria-label="Search Clients" aria-describedby="search-clients" v-on:keyup.enter="searchClientData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-clients"><i class="icon-magnifier" @click="searchClientData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <!-- <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <b-button class="btn btn-primary btn-lg btn-mw" @click="showSubsidiaryForm('client')">Add Client <i class="fa fa-plus-square ml-1"></i></b-button>
                                        </div> -->
                                    </div>
                                </div>
                                <b-table
                                    ref = "clientTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getClientList" 
                                    :fields="clients_fields"
                                    :current-page="clientsCurrentPage"
                                    :per-page="clientsPerPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(uuid)="data">
                                        <b-button 
                                            id="button1" 
                                            variant="outline-primary" 
                                            size="sm" 
                                            v-b-tooltip.hover
                                            :data-original-title="data.value.id"
                                            :title="data.value.id" 
                                            @click.prevent="copyTooltip($event)">
                                            <i class="fa fa-2x fa-barcode"></i>
                                        </b-button>
                                    </template>
                                    <template #cell(businessname)="row">
                                        {{ row.value }}
                                        <!-- <router-link class="font-color font-weight-bold" to="/business/subsidiaries-detail">{{ row.value }} <i class="fa fa-external-link"></i></router-link> -->
                                    </template>
                                    <template #cell(status)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                        </p>
                                    </template>
                                    <template #cell(action)="data">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'approved')">Approved</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'rejected')">Rejected</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'freezed')">Freezed</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'suspended')">Suspended</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.uuid,'dormant')">Dormant</b-dropdown-item>
                                            <b-dropdown-item @click="deleteItem(data.item.uuid)">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="clientsTotalRows > 0?' d-flex':'d-none'">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="clientsPerPage" :options="clientsPageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="clientsCurrentPage" 
                                            :total-rows="clientsTotalRows" 
                                            :per-page="clientsPerPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page </span>
                                            </div>
                                            <input type="text" v-model="clientsCurrentPageJump" class="form-control" value="" @keyup.enter="clientsOnEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            
                            <!-- Branch Tab -->
                            <b-tab title="Branches">
                                
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="branchSearchText" placeholder="Search Branches" aria-label="Search Branches" aria-describedby="search-branches" v-on:keyup.enter="searchBranchData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-branches"><i class="icon-magnifier" @click="searchBranchData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <!-- <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <b-button class="btn btn-primary btn-lg btn-mw" @click="showSubsidiaryForm('branch')">Add Branch <i class="fa fa-plus-square ml-1"></i></b-button>
                                        </div> -->
                                    </div>
                                </div>
                                <b-table 
                                    ref = "branchTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getBranchList" 
                                    :fields="branch_fields"
                                    :current-page="branchCurrentPage"
                                    :per-page="branchPerPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(uuid)="data">
                                        <b-button 
                                            id="button1" 
                                            variant="outline-primary" 
                                            size="sm" 
                                            v-b-tooltip.hover
                                            :data-original-title="data.value.id"
                                            :title="data.value.id" 
                                            @click.prevent="copyTooltip($event)">
                                            <i class="fa fa-2x fa-barcode"></i>
                                        </b-button>
                                    </template>
                                    <template #cell(businessname)="row">
                                        {{ row.value }}
                                        <!-- <router-link class="font-color font-weight-bold" to="/business/subsidiaries-detail">{{ row.value }} <i class="fa fa-external-link"></i></router-link> -->
                                    </template>
                                    <template #cell(status)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                        </p>
                                    </template>
                                    <template #cell(action)="data">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item @click="updateStatus(data.item.businessid,'approved')">Approved</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.businessid,'rejected')">Rejected</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.businessid,'freezed')">Freezed</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.businessid,'suspended')">Suspended</b-dropdown-item>
                                            <b-dropdown-item @click="updateStatus(data.item.businessid,'dormant')">Dormant</b-dropdown-item>
                                            <b-dropdown-item @click="deleteItem(data.item.uuid)">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="branchTotalRows > 0?' d-flex':'d-none'">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="branchPerPage" :options="branchPageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="branchCurrentPage" 
                                            :total-rows="branchTotalRows" 
                                            :per-page="branchPerPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page</span>
                                            </div>
                                            <input type="text" v-model="branchCurrentPageJump" class="form-control" value="" @keyup.enter="branchOnEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="Personnels" :active="isPersonnel == true">
                                <!-- Personnel Tab -->
                                
                                <div class="chart-top-action d-lg-flex justify-content-between py-3">
                                    <div class="d-md-flex justify-content-between">
                                        <div class="mr-0 mr-md-3 action-top-search">
                                            <div class="input-group">
                                                <input type="text" class="form-control" v-model="personnelSearchText" placeholder="Search Personnels" aria-label="Search Personnels" aria-describedby="search-personnel" v-on:keyup.enter="searchPersonnelData">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="search-personnel"><i class="icon-magnifier" @click="searchPersonnelData()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                        <!-- <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                           <router-link to="/setting/user-add" class="btn btn-primary btn-lg btn-mw">Add User <i class="fa fa-plus-square ml-1"></i></router-link>
                                        </div> -->
                                    </div>
                                </div>
                                
                                <b-table
                                    ref = "personnelTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getPersonnelList" 
                                    :fields="personnel_fields"
                                    :current-page="personnelCurrentPage"
                                    :per-page="personnelPerPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection">
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    <template #cell(details)="data">
                                        <div class="media-object flag-img mr-2">
                                            <img :src="data.value.user_img" alt="" width="50px" class="rounded-circle">
                                            <span :class="data.value.flagClass"></span>
                                        </div>
                                        <span class="user-name">{{ data.value.name }}</span>
                                    </template>
                                    <!-- <template #cell(action)="data">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item>Edit</b-dropdown-item>
                                            <b-dropdown-item @click="showAlert(data)">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </template> -->
                                    <template #cell(function)="data">
                                        <p class="function-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-success" v-for="(functions,index) in data.value" :key="index">{{ functions }}</span>
                                        </p>
                                    </template>
                                    <template #cell(role)="data">
                                        <p class="role-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-info" v-for="(roles,index) in data.value" :key="index">{{ roles }}</span>
                                        </p>
                                    </template>
                                    <template #cell(services)="data">
                                        <p class="services-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-default" v-for="(services,index) in data.value" :key="index">{{ services }}</span>
                                        </p>
                                    </template>
                                    <template #cell(type)="data">
                                        <p class="access-badge badge-group-table mb-0">
                                            <span class="badge ml-0 badge-danger" v-for="(access,index) in data.value" :key="index">{{ access }}</span>
                                        </p>
                                    </template>
                                </b-table>
                                <!-- Table Pagination -->
                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="personnelTotalRows > 0?' d-flex':'d-none'">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="personnelPerPage" :options="personnelPageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="personnelCurrentPage" 
                                            :total-rows="personnelTotalRows" 
                                            :per-page="personnelPerPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page</span>
                                            </div>
                                            <input type="text" v-model="personnelsCurrentPageJump" class="form-control"  value="" @keyup.enter="personnelOnEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>

    </div>
    
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default


import '@/plugins/sweetalert2'
import shared from "@/shared.js";
export default {
    name:'BusinesDetailComponent',
    components: {
        BreadCrumb,
        'main-loader':LoaderComponent
    },data (){
        return{
            //All Subsidiaries List table pagination options
            subsidiariesTotalRows: 0,
            subsidiariesCurrentPage: 1,
            subsidiariesCurrentPageJump: 1,
            subsidiariesPerPage: 10,
            subsidiariesPageOptions: [10,25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //All Subsidiaries list table json
            // subsidiaries_items:[
            //     {uuid:'',name:'PayAngel Group',merchant_id:'722102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Active',class:'badge-success'},action:''},
            //     {uuid:'',name:'Thememakker Group',merchant_id:'209812',dor:'17.01.2017',owner:'Maxine Jennings',ltd_to:'$0.20',status:{name:'Suspended',class:'badge-danger'},action:''},
            //     {uuid:'',name:'Wrraptheme Group',merchant_id:'628472',dor:'15.01.2017',owner:'Eddie Kane',ltd_to:'$0.11',status:{name:'Dormant',class:'badge-warning'},action:''},
            //     {uuid:'',name:'Angel community Group',merchant_id:'392659',dor:'16.01.2017',owner:'Harry Bowen',ltd_to:'$0.16',status:{name:'Active',class:'badge-success'},action:''},
            // ],
           
            //All Subsidiaries list table header fields
            subsidiaries_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'businessname', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchandid', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                // { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Clients List table pagination options
            clientsTotalRows: 0,
            clientsCurrentPage: 1,
            clientsCurrentPageJump: 1,
            clientsPerPage: 10,
            clientsPageOptions: [10,25, 50, { value: 100, text: 100 }],


            //All Clients list table json
            // clients_items:[
            //     {uuid:'',name:'PayAngel Group',merchant_id:'729102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Active',class:'badge-success'},action:''},
            //     {uuid:'',name:'Thememakker Group',merchant_id:'842102',dor:'09.11.2017',owner:'Maxine Jennings',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
            //     {uuid:'',name:'Wrraptheme Group',merchant_id:'422002',dor:'10.09.2018',owner:'Eddie Kane',ltd_to:'$0.10',status:{name:'Suspended',class:'badge-danger'},action:''},
            //     {uuid:'',name:'Angel community Group',merchant_id:'156102',dor:'11.03.2019',owner:'Harry Bowen',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
            // ],
           
            //All Clients list table header fields
            clients_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'businessname', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchandid', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                // { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Branch List table pagination options
            branchTotalRows: 0,
            branchCurrentPage: 1,
            branchCurrentPageJump: 1,
            branchPerPage: 10,
            branchPageOptions: [10,25, 50, { value: 100, text: 100 }],

            //All Branch list table json
            branch_items:[
                {uuid:'',name:'PayAngel Group',merchant_id:'722102',dor:'30.12.2016',owner:'PayAngel',ltd_to:'$0.10',status:{name:'Dormant',class:'badge-warning'},action:''},
                {uuid:'',name:'Thememakker Group',merchant_id:'209812',dor:'17.01.2017',owner:'Maxine Jennings',ltd_to:'$0.20',status:{name:'Suspended',class:'badge-danger'},action:''},
                {uuid:'',name:'Wrraptheme Group',merchant_id:'628472',dor:'15.01.2017',owner:'Eddie Kane',ltd_to:'$0.11',status:{name:'Suspended',class:'badge-danger'},action:''},
                {uuid:'',name:'Angel community Group',merchant_id:'392659',dor:'16.01.2017',owner:'Harry Bowen',ltd_to:'$0.16',status:{name:'Suspended',class:'badge-danger'},action:''},
            ],
           
            //All Branch list table header fields
            branch_fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'branchid', label: 'Merchant Code',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                // { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                // { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Personnel List table pagination options
            personnelTotalRows: 0,
            personnelCurrentPage: 1,
            personnelsCurrentPageJump: 1,
            personnelPerPage: 10,
            personnelPageOptions: [10,25, 50, { value: 100, text: 100 }],

            //All Personnel list table json
            personnel_items:[
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                    function:['ADMIN'],
                    role:['ADMIN','MANAGER'],
                    services:['DASHBOARD'],
                    type:['READ'],
                    action:''
                }
                
            ],
           
            //All Personnel list table header fields
            personnel_fields: [
                { key: 'details', label: 'Name',sortable: true,thClass:'user-name-img th_sortfix'},
                { key: 'function', label: 'Function',sortable: true,thClass:'function-badge th_sortfix'},
                { key: 'role', label: 'Role',sortable: true,thClass:'role-badge th_sortfix'},
                { key: 'services', label: 'Services',sortable: true,thClass:'services-badge th_sortfix'},
                { key: 'type', label: 'Access Type',sortable: true,thClass:'access-badge th_sortfix'},
                // { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            breadCrumbIteams:[
                {
                    title:'Business',
                    links:'/business/list'
                },
                {
                    title:'',
                    links:''
                }
            ],
            subBusinessType: '',
            modalTitle: '',
            businessName : '',
            subsidiarySearchText:'',
            clientSearchText: '',
            branchSearchText: '',
            personnelSearchText: '',
            isPersonnel: false
        }
    },methods: {
        searchSubsidiaryData() {
            this.refreshTable();
        },
        searchClientData() {
            this.refreshClientTable();
        },
        searchBranchData() {
            this.refreshBranchTable();
        },
        searchPersonnelData() {
            this.refreshPersonnelTable();
        },
        getSelectedItem(arg) {
            this.perPage = arg;
            this.getSubsidiaryList();
        },
        //subsidiaries go to subsidiaries
        subsidiariesOnEnter(e) {
            var paginationMenu = Math.ceil(this.subsidiariesTotalRows / this.subsidiariesPerPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.subsidiariesCurrentPage = this.subsidiariesCurrentPageJump = e.target.value;
                } else {
                    this.subsidiariesCurrentPage = this.subsidiariesCurrentPageJump = paginationMenu;
                }
            } else {
                this.subsidiariesCurrentPage = this.subsidiariesCurrentPageJump = 1;
            }
        },

        //clients go to clients
        clientsOnEnter(e) {
            var paginationMenu = Math.ceil(this.clientsTotalRows / this.clientsPerPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.clientsCurrentPage = this.clientsCurrentPageJump = e.target.value
                } else {
                    this.clientsCurrentPage = this.clientsCurrentPageJump = paginationMenu;
                }
            } else {
                this.clientsCurrentPage = this.clientsCurrentPageJump = 1;
            }
        },

        //Branch go to clients
        branchOnEnter(e) {
            var paginationMenu = Math.ceil(this.branchTotalRows / this.branchPerPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.branchCurrentPage = this.branchCurrentPageJump = e.target.value
                } else {
                    this.branchCurrentPage = this.branchCurrentPageJump = paginationMenu;
                }
            } else {
                this.branchCurrentPage = this.branchCurrentPageJump = 1;
            }
        },

        //personnel go to personnel
        personnelOnEnter(e) {
            var paginationMenu = Math.ceil(this.personnelTotalRows / this.personnelPerPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.personnelCurrentPage = this.personnelsCurrentPageJump = e.target.value
                } else {
                   this.personnelCurrentPage = this.personnelsCurrentPageJump = paginationMenu; 
                }
            } else {
                this.personnelCurrentPage = this.personnelsCurrentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },

        showAlert(data) {
            console.log(data);
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    alert();
                }
            })
        },
        showSubsidiaryForm(formType) {
            //sessionStorage.removeItem("formType");
            this.subBusinessType = formType;
            this.modalTitle = 'Add '+formType.charAt(0).toUpperCase()+ formType.slice(1);
           // sessionStorage.setItem("formType", formType);
            this.$bvModal.show('add_sub_business');
        },
        async getSubsidiaryList(ctx) {
                try{
                    this.$refs.loader.show();
                    let currentpage  = '';
                    let perpage = '';
                    let sortby = '';
                    let sortdesc = false;
                    if(ctx.currentPage != undefined) {
                        currentpage =  ctx.currentPage;
                    } else {
                        currentpage = 1;
                    }
                    if(ctx.perPage != undefined) {
                        perpage = ctx.perPage;
                    } else {
                        perpage = 10;
                    }
                    if(ctx.sortBy == '') {
                        sortby = 'createdat'
                    } else {
                        sortby = ctx.sortBy;
                    }
                    if(ctx.sortDesc == false) {
                        sortdesc = 'DESC'
                    } else {
                        sortdesc = 'ASC';
                    }
                    this.subsidiariesCurrentPageJump = currentpage;
                    //subsidiariesCurrentPage: 0,
            //subsidiariesCurrentPageJump: 0,
                    
                    this.items = [];
                    const response = await this.$http.get(this.$baseurl+'business/subsidiaries?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&businessid='+sessionStorage.getItem('businessIdFromBusinessList')+'&search='+this.subsidiarySearchText,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                            },
                        });
                        if(response.data.code == '0067-000') {
                            response.data.details.forEach(element => {
                                let statusObj = {};
                                // element.isapproved==0?
                                // (statusObj.name ='Inactive', statusObj.class= 'badge-danger')
                                // :(statusObj.name ='Active', statusObj.class= 'badge-success')

                                if(element.status == 0) {
                                    statusObj.name ='Pending';
                                    statusObj.class= 'badge-danger';
                                } else if(element.status == 1) {
                                    statusObj.name ='Approved';
                                    statusObj.class= 'badge-success';
                                } else if(element.status == 2) {
                                    statusObj.name ='Rejected';
                                    statusObj.class= 'badge-warning';
                                } else if (element.status == 3) {
                                    statusObj.name ='Freezed';
                                    statusObj.class= 'badge-danger';
                                } else if(element.status == 4) {
                                    statusObj.name ='Suspended';
                                    statusObj.class= 'badge-danger';
                                } else if(element.status == 5) {
                                    statusObj.name ='Suspended';
                                    statusObj.class= 'badge-danger';
                                } else {
                                    statusObj.name ='Pending';
                                    statusObj.class= 'badge-danger';
                                }
                                let businessItem = {
                                    uuid:{id:element.businessid},
                                    businessname:element.businessname,
                                    merchandid: element.merchandid,
                                    dor:element.registrationdate==null?'-':element.registrationdate,
                                    owner: '',
                                    ltd_to:element.annualturnover==null?'-':element.annualturnover,
                                    // account_type:element.categoryname,
                                    status:statusObj,
                                    action:''
                            };
                            this.items.push(businessItem);
                        })
                            this.subsidiariesTotalRows = response.data.query.total; 
                            this.$refs.loader.hide();
                            return this.items;
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(response.data.message);                            
                        }
                } catch(error) {
                  //  return [];
                    this.$refs.loader.hide();
                    shared.toastrError(error.response.data.message);                            
                }
        },
        async getClientList(ctx) {
            try{
                this.$refs.loader.show();
                let currentpage  = '';
                let perpage = '';
                let sortby = '';
                let sortdesc = false;
                if(ctx.currentPage != undefined) {
                    currentpage =  ctx.currentPage;
                } else {
                    currentpage = 1;
                }
                if(ctx.perPage != undefined) {
                    perpage = ctx.perPage;
                } else {
                    perpage = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sortdesc = 'DESC'
                } else {
                    sortdesc = 'ASC';
                }
                
                this.clientsCurrentPageJump = currentpage;
                //subsidiariesCurrentPage: 0,
                //subsidiariesCurrentPageJump: 0,
                this.clientItems = [];
                const response = await this.$http.get(this.$baseurl+'business/clients?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&businessid='+sessionStorage.getItem('businessIdFromBusinessList')+'&search='+this.clientSearchText,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                    if(response.data.code == '0067-000') {
                        response.data.details.forEach(element => {
                            let statusObj = {};
                            // element.isapproved==0?
                            // (statusObj.name ='Inactive', statusObj.class= 'badge-danger')
                            // :(statusObj.name ='Active', statusObj.class= 'badge-success')

                            if(element.status == 0) {
                                statusObj.name ='Pending';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 1) {
                                statusObj.name ='Approved';
                                statusObj.class= 'badge-success';
                            } else if(element.status == 2) {
                                statusObj.name ='Rejected';
                                statusObj.class= 'badge-warning';
                            } else if (element.status == 3) {
                                statusObj.name ='Freezed';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 4) {
                                statusObj.name ='Suspended';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 5) {
                                statusObj.name ='Suspended';
                                statusObj.class= 'badge-danger';
                            } else {
                                statusObj.name ='Pending';
                                statusObj.class= 'badge-danger';
                            }
                            let businessItem = {
                                uuid:{id:element.businessid},
                                businessname:element.businessname,
                                country:element.countryofresidence,
                                merchandid: element.merchandid,
                                dor:element.registrationdate==null?'-':element.registrationdate,
                                owner: '',
                                ltd_to:element.annualturnover==null?'-':element.annualturnover,
                                // account_type:element.categoryname,
                                status:statusObj,
                                action:''
                        };
                        this.clientItems.push(businessItem);
                    })
                        this.clientsTotalRows = response.data.query.total; 
                        this.$refs.loader.hide();
                        return this.clientItems;
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);                            
                    }
            } catch(error) {
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);                            
               // return [];
            }
        },
        async getBranchList(ctx) {
            try{
                this.$refs.loader.show();
                let currentpage  = '';
                let perpage = '';
                let sortby = '';
                let sortdesc = false;
                if(ctx.currentPage != undefined) {
                    currentpage =  ctx.currentPage;
                } else {
                    currentpage = 1;
                }
                if(ctx.perPage != undefined) {
                    perpage = ctx.perPage;
                } else {
                    perpage = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sortdesc = 'DESC'
                } else {
                    sortdesc = 'ASC';
                }
                
                this.branchCurrentPageJump = currentpage;
                //subsidiariesCurrentPage: 0,
        //subsidiariesCurrentPageJump: 0,
                this.branchItems = [];
                const response = await this.$http.get(this.$baseurl+'business/branches?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&businessid='+sessionStorage.getItem('businessIdFromBusinessList')+'&search='+this.branchSearchText,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                    if(response.data.code == '0067-000') {
                        response.data.details.forEach(element => {
                            let statusObj = {};
                            // element.isapproved==0?
                            // (statusObj.name ='Inactive', statusObj.class= 'badge-danger')
                            // :(statusObj.name ='Active', statusObj.class= 'badge-success')

                            if(element.status == 0) {
                                statusObj.name ='Pending';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 1) {
                                statusObj.name ='Approved';
                                statusObj.class= 'badge-success';
                            } else if(element.status == 2) {
                                statusObj.name ='Rejected';
                                statusObj.class= 'badge-warning';
                            } else if (element.status == 3) {
                                statusObj.name ='Freezed';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 4) {
                                statusObj.name ='Suspended';
                                statusObj.class= 'badge-danger';
                            } else if(element.status == 5) {
                                statusObj.name ='Suspended';
                                statusObj.class= 'badge-danger';
                            } else {
                                statusObj.name ='Pending';
                                statusObj.class= 'badge-danger';
                            }
                            let businessItem = {
                                uuid:{id:element.branchid},
                                businessid:element.businessid,
                                name:element.name,
                                branchid:element.branchcode==null?'-':element.branchcode,
                                dor:element.registrationdate==null?'-':element.registrationdate,
                                // owner: '-',
                                // ltd_to:element.annualturnover==null?'-':element.annualturnover,
                                // account_type:element.categoryname,
                                status:statusObj,
                                action:''
                        };
                        this.branchItems.push(businessItem);
                    })
                        this.branchTotalRows = response.data.query.total; 
                        this.$refs.loader.hide();
                        return this.branchItems;
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);                            
                    }
            } catch(error) {
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);                            
                //return [];
            }
        },
        async getPersonnelList(ctx) {
            try{
                this.$refs.loader.show();
                let currentpage  = '';
                let perpage = '';
                let sortby = '';
                let sortdesc = false;
                if(ctx.currentPage != undefined) {
                    currentpage =  ctx.currentPage;
                } else {
                    currentpage = 1;
                }
                if(ctx.perPage != undefined) {
                    perpage = ctx.perPage;
                } else {
                    perpage = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sortdesc = 'DESC'
                } else {
                    sortdesc = 'ASC';
                }
                
                this.personnelsCurrentPageJump = currentpage;
                //subsidiariesCurrentPage: 0,
        //subsidiariesCurrentPageJump: 0,
                this.personnelItems = [];
                const response = await this.$http.get(this.$baseurl+'business/personnels?sorting='+sortdesc+'&sortby='+sortby+'&pagesize='+perpage+'&pagenumber='+currentpage+'&businessid='+sessionStorage.getItem('businessIdFromBusinessList')+'&search='+this.personnelSearchText,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
                    if(response.data.code == '0067-000') {
                        response.data.details.forEach(element => {
                        let businessItem = {
                            businessuserid : element.businessuserid,
                            details: {name:element.firstname,user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                            function:['ADMIN'],
                            role:['ADMIN','MANAGER'],
                            services:['DASHBOARD'],
                            type:['READ'],
                            action:''
                        };
                    
                        this.personnelItems.push(businessItem);
                    })
                        this.personnelTotalRows = response.data.query.total; 
                        this.$refs.loader.hide();
                        return this.personnelItems;
                    } else {
                        this.$refs.loader.hide();
                        shared.toastrError(response.data.message);                            
                    }
            } catch(error) {
                //return [];
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);                            
            }
        },
        refreshTable() {
            this.$refs.table.refresh();
        },
        refreshClientTable() {
            this.$refs.clientTable.refresh();
        },
        refreshBranchTable() {
            this.$refs.branchTable.refresh();
        },
        refreshPersonnelTable() {
            this.$refs.personnelTable.refresh();
        },

        updateStatus(businessID, status){
            shared.updateConfirm().then((result) => {
                // console.log(businessID, status);
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let dataJson = {
                        "status": status,
                        "isdeleted": false
                    }
                    // console.log(dataJson);
                    this.$http.put(this.$baseurl+"business/actions/"+businessID, dataJson,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '0102-000') {
                            shared.toastrSuccess(res.data.message);
                            this.$refs.loader.hide();
                            this.refreshTable();
                            this.refreshClientTable();
                            this.refreshBranchTable();
                        } else {
                            this.$refs.loader.hide();
                            this.refreshTable();
                            this.refreshClientTable();
                            this.refreshBranchTable();
                        }
                    }).catch((error) => {
                        //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        this.$refs.loader.hide();
                        this.refreshTable();
                        this.refreshClientTable();
                        this.refreshBranchTable();
                        shared.toastrError(error.response.data.message);
                    });
                }
            })
        },

        deleteItem(businessID) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let dataJson = {
                        "isdeleted": true
                    }
                    // console.log(dataJson);
                    this.$http.put(this.$baseurl+"business/actions/"+businessID, dataJson,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '0102-000') {
                            shared.toastrSuccess(res.data.message);
                            this.$refs.loader.hide();
                            this.refreshTable();
                            this.refreshClientTable();
                            this.refreshBranchTable();
                        } else {
                            this.$refs.loader.hide();
                            this.refreshTable();
                            this.refreshClientTable();
                            this.refreshBranchTable();
                        }
                    }).catch((error) => {
                        //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        this.$refs.loader.hide();
                        this.refreshTable();
                        this.refreshClientTable();
                        this.refreshBranchTable();
                        shared.toastrError(error.response.data.message);
                    });           
                }
            })
        },
        
    },
    mounted() {
        if(sessionStorage.getItem('personnelList')==='1') {
            this.isPersonnel = true;
        }
        if(!sessionStorage.getItem("countryId")){
            shared.toastrError('Please select a business first');
            this.$router.push({path:'/business/list'});
        }
        if(sessionStorage.getItem("jwtToken")){
            //this.subsidiariesTotalRows = this.subsidiaries_items.length
            //this.clientsTotalRows = this.clients_items.length
            //this.branchTotalRows = this.branch_items.length
            //this.personnelTotalRows = this.personnel_items.length
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
        this.$root.$refs.BusinesDetailComponent = this;
        this.businessName = sessionStorage.getItem('businessName');
        this.breadCrumbIteams[1].title = this.businessName;
        sessionStorage.removeItem('personnelList');
    },computed: {
        
    }
}

</script>
<style lang="sass">

</style>
