import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
//vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.use(VueToast)
export default {
    toastrSuccess: function(message) {
      Vue.$toast.open({
        message: message,
        type: "success",
        duration: 3000,
        position: 'top-right',
        dismissible: true
      })    
    },
    toastrError: function(message) {
      Vue.$toast.open({
        message: message,
        type: "error",
        duration: 3000,
        position: 'top-right',
        dismissible: true
      })    
    },
    updateConfirm : function() {
      return Vue.swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary btn-lg my-3',
          cancelButton: 'btn btn-outline-secondary btn-lg'
        },
        buttonsStyling: false,
        showCancelButton: true,
      })
    },
    deleteConfirm : function() {
      return Vue.swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary btn-lg my-3',
          cancelButton: 'btn btn-outline-secondary btn-lg'
        },
        buttonsStyling: false,
        showCancelButton: true,
      })
    }    
}




