<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="mb-3">
                            <h6>Add New Customer</h6>
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(customerSubmitForm)" id="add-customer">
                                <ul class="list-unstyled mb-0 common-form small-form">
                                    <li>
                                        <ValidationProvider name="businessName" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !customer.businessName,'success':customer.businessName}">
                                                <span class="float-label">
                                                    <input type="text" v-model="customer.businessName" class="form-control" id="businessName" placeholder="Business Name*">
                                                    <label for="businessName" class="control-label">Business Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !customer.businessName"></i>
                                                    <i class="fa fa-check" v-if="customer.businessName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':customer.country && !errors[0]}">
                                                    <span class="float-label">
                                                    <label for="Country">Select Country*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="customer.country" 
                                                            :show-labels="false" 
                                                            :options="countrysOptions" 
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name"
                                                            @select="setPhoneNumberFlag">
                                                            <template slot="option" slot-scope="props">
                                                                <span :class="props.option.flagClass"></span>
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect>  
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="customer.country && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="form-group" :class="{'errors': phoneNumberError == false,'success':customer.phone && phoneNumberError}">
                                            <div class="float-label">
                                                <label for="primary_number" class="control-label">Phone Number*</label>
                                                <vue-tel-input
                                                    v-model="customer.phone"
                                                    v-bind="primaryMobileNumber"
                                                    @validate ="mobileInputValidate"
                                                    ref="telInput"
                                                    @keydown.native="restrictSpecialCharacter($event)">
                                                    <template v-slot:arrow-icon>
                                                        <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                    </template>
                                                </vue-tel-input>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="phoneNumberError == false"></i>
                                                <i class="fa fa-check" v-if="phoneNumberError && customer.phone"></i>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !customer.email || errors[0],'success':customer.email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="customer.email" class="form-control" name="email" id="forgot-email">
                                                    <label for="forgot-email" class="control-label">Email*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !customer.email || errors[0]"></i>
                                                    <i class="fa fa-check" v-if="customer.email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <ValidationProvider name="firstName" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !customer.firstName,'success':customer.firstName}">
                                                <span class="float-label">
                                                    <input type="text" v-model="customer.firstName" class="form-control" id="firstName" placeholder="First Name*">
                                                    <label for="firstName" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !customer.firstName"></i>
                                                    <i class="fa fa-check" v-if="customer.firstName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <ValidationProvider name="lastName" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !customer.lastName,'success':customer.lastName}">
                                                <span class="float-label">
                                                    <input type="text" v-model="customer.lastName" class="form-control" id="lastName" placeholder="Last Name*">
                                                    <label for="lastName" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !customer.lastName"></i>
                                                    <i class="fa fa-check" v-if="customer.lastName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" v-model="customer.street" class="form-control" id="street" placeholder="Street">
                                                <label for="street" class="control-label">Street</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" v-model="customer.city" class="form-control" id="city" placeholder="Town/City">
                                                <label for="city" class="control-label">Town/City</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Note</strong>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea type="text" v-model="customer.note" class="form-control" id="note" placeholder="Note"/>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group clearfix">
                                            <label class="fancy-checkbox new-fancy">
                                                <input type="checkbox" v-model="customer.futureReference">
                                                <span>Save this customer for future reference</span>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                            <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                            <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Use These Details</b-button>
                                        </div>
                                    </li>
                                </ul>
                            </form> 
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const { VueTelInput } = require('vue-tel-input')

export default {
    name:'AddCustomerComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data(){
        return{
            customer: {
                businessName: '',
                email: '',
                country: null,
                firstName:'',
                lastName:'',
                city:'',
                note:'',
                futureReference:true
            },

            countrysOptions: [
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            ],

            phoneNumberError:'',
            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            },
        }
    },methods:{
        customerSubmitForm(){
            if(typeof(this.phoneNumberError) == 'undefined'){
                this.phoneNumberError = false
            }
            if(this.phoneNumberError){
                this.$router.push({ path: '/invoice/customer-list' })
            }
        },

        setPhoneNumberFlag(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
        },

        mobileInputValidate(phoneObject){
            this.phoneNumberError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        }
    }
}
</script>