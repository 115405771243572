<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card register-form-card">
                            <ValidationObserver v-slot="{ passes }">
                                <div class="header pb-1 text-center">
                                    <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
                                </div>
                                <div class="body">
                                    <p class="text-center mb-4">Enter your name as it appears on your official ID</p>
                                    <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration">
                                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !personaldetail.firstName,'success':personaldetail.firstName}">
                                                <span class="float-label">
                                                    <input type="text" id="firstName" v-model="personaldetail.firstName" class="form-control" placeholder="First Name*">
                                                    <label for="firstName" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !personaldetail.firstName"></i>
                                                    <i class="fa fa-check" v-if="personaldetail.firstName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" id="middleName" v-model="personaldetail.middleName" class="form-control" placeholder="Middle Name">
                                                <label for="middleName" class="control-label">Middle Name</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{'errors': errors[0],'success':personaldetail.lastName && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="text" class="form-control" id="lastName" v-model="personaldetail.lastName" placeholder="Last Name*">
                                                    <label for="lastName" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="personaldetail.lastName && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img :src="welcome_messages.banner_image" class="banner-img" alt=""> -->
        </div>
    </section>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'PersonaldetailComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        registerSubmit() {
            this.$router.push({ path: '/authentication/page-password-v2' })
        },
        
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },
    },data(){
        return{
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'The one Account you need for',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            personaldetail: {
                lastName:'',
                firstName: '',
                middleName:'',
                robot: false,
            },
        }
    },mounted () {
        this.onLoad();
    }
}
</script>
<style>

</style>