<template>
   <div id="left-sidebar" class="sidebar">
      <div class="">
        <div class="navbar-brand">
            <router-link to="/dashboard/index" v-if="toggleSidebarTheme == ''"><img :src="require(`@/assets/standapp/standapp_main.svg`)" width="150" alt="PayAngel Logo" class="img-responsive logo"></router-link>
            <router-link to="/dashboard/index" v-if="toggleSidebarTheme == 'full-dark'"><img :src="require(`@/assets/standapp/standapp_main.svg`)" width="150" alt="PayAngel Logo" class="img-responsive logo"></router-link>
            <button type="button" class="btn-toggle-offcanvas btn btn-sm btn-default" v-on:click="backSideMenu()"><i class="lnr lnr-menu fa fa-chevron-circle-left"></i></button>
        </div>
        <div class="user-account">
            <div class="user_div">
                <img  :src="require('@/assets/no-user-image.png')" class="rounded-circle user-photo" alt="User Profile Picture">
            </div>
            <div class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive" v-click-outside="outside">
                <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown" :aria-expanded="dropActive ? 'true' : 'false'"><strong>{{full_name}}</strong></a>
                <ul class="dropdown-menu dropdown-menu-right account" v-bind:class="{ 'show': dropActive }" :style="dropActive ? 'top: 100%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;' : 'top: 80%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'">
                    <li><router-link to="/setting/profile"><i class="icon-user"></i>My Profile</router-link></li>
                    <li class="divider"></li>
                    <li><router-link to="javascript:void(0)" @click.native="logout()"><i class="icon-power"></i>Logout</router-link></li>
                </ul>
            </div>
             <!-- <span>Admin</span> -->
        </div> 
          <!-- Nav tabs -->
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link"  v-on:click="setActiveNavtab('Menu')" :class="{ 'active show': openNavtab('Menu')}" data-toggle="tab">Menu</a></li>
            <li class="nav-item"><a class="nav-link"  v-on:click="setActiveNavtab('Setting')" :class="{ 'active show': openNavtab('Setting')}" data-toggle="tab"><i class="icon-settings"></i></a></li>
        </ul>  
          <!-- Tab panes -->
        <div class="tab-content p-l-0 p-r-0">
            <div class="tab-pane" :class="{ 'active show': openNavtab('Menu')}" id="menu">
                <nav id="left-sidebar-nav" class="sidebar-nav">
                    <ul id="main-menu" class="metismenu">
                        <template v-for="(menu,index) in menu_list">
                            <li v-bind:class="{ 'active': currentActiveMenu == menu.name.toLowerCase() }" :key="index" v-if="menu.children.length == 0">
                                <router-link :to="menu.path"><i :class="menu.icon"></i> <span>{{menu.name}}</span></router-link>
                            </li>

                            <li :class="{ 'active': currentActiveMenu === menu.path.substring(1)}" :key="index" v-if="menu.children.length >= 1">
                                <a class="has-arrow" v-on:click="setActive(menu.path.substring(1))"><i class="fa fa-cog"></i> <span>{{menu.name}}</span></a>
                                <ul v-bind:class="currentActiveMenu == menu.path.substring(1) ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == child.path}"  v-for="(child,index) in menu.children" :key="index"><router-link :to="child.path">{{child.name}}</router-link></li>
                                </ul>
                            </li>
                        </template>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'partner' }">
                            <router-link to="/partner"><i class="fa fa-universal-access"></i> <span>Partner</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'sms' }">
                            <router-link to="/sms"><i class="fa fa-envelope"></i> <span>SMS</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'transactions' }">
                            <router-link to="/transaction/list"><i class="fa fa-list"></i> <span>Transaction</span></router-link>
                        </li>
                        <li>
                            <router-link to="javascript:void(0)" @click.native="logout()"><i class="fa fa-sign-out"></i>Logout</router-link>
                        </li>
                    </ul>
                </nav>
            </div> 
            <div class="tab-pane p-l-15 p-r-15" :class="{ 'active show': openNavtab('Setting')}" id="setting">
                <h6>Choose Mode</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="white" :class="{ 'active': data_theme === ''}"  v-on:click="setactiveTheme('')">
                        <div class="white"></div>
                        <span>Light</span>
                    </li>
                    <li data-theme="black" :class="{ 'active': data_theme === 'full-dark'}"  v-on:click="setactiveTheme('full-dark')">
                        <div class="black"></div>
                        <span>Dark</span>
                    </li>
                </ul>
                <hr>
                <h6>Choose Skin</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="radical-red"  v-bind:class="{ 'active': activeColor === 'radical-red'}" v-on:click="changeBodycolor('radical-red')">
                        <div class="radical-red"></div>
                        <span>Radical Red</span>
                    </li>                   
                    <li data-theme="turquoise"  v-bind:class="{ 'active': activeColor === 'turquoise'}" v-on:click="changeBodycolor('turquoise')">
                        <div class="turquoise"></div>
                        <span>Turquoise</span>
                    </li>
                    <li data-theme="midnight-express" v-bind:class="{ 'active': activeColor === 'midnight-express'}" v-on:click="changeBodycolor('midnight-express')">
                        <div class="midnight-express"></div>
                        <span>Midnight Express</span>
                    </li>
                    <li data-theme="morning-glory"  v-bind:class="{ 'active': activeColor === 'morning-glory'}" v-on:click="changeBodycolor('morning-glory')">
                        <div class="morning-glory"></div>
                        <span>Morning Glory</span>
                    </li>
                    <li data-theme="dark-cyan"  v-bind:class="{ 'active': activeColor === 'dark-cyan'}" v-on:click="changeBodycolor('dark-cyan')">
                        <div class="dark-cyan"></div>
                        <span>Dark Cyan</span>
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </div>    
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
    name: 'SidebarComponent',
    methods:{
        changeBodycolor(color){
            this.activeColor = color
            document.body.className = 'theme-'+color+' '+ this.data_theme;
        },

        outside: function() {
            this.dropActive = false
        },

        setActive: function (menuItem) {
            if (this.currentActiveMenu === menuItem) {
                this.currentActiveMenu = null;
            } else {
                this.currentActiveMenu = menuItem;
            }
        },

        openNavtab: function (tab) {
            return this.currentMenuTab === tab
        },
        setActiveNavtab: function (tab) {
            this.currentMenuTab = tab 
        },
        setactiveTheme: function (theme) {
            this.data_theme = theme
            document.body.className = 'theme-'+this.activeColor +' '+ theme;
            EventBus.$emit('theme',theme) 
        },
        backSideMenu: function () {
            document.body.classList.toggle('offcanvas-active')
        },

        logout(){
            sessionStorage.clear()
            // sessionStorage.removeItem('jwtToken');
            this.$router.push({ path: '/standapp-home' })
        },

        async menuDetail(){
            try {
                const response = await this.$http.get(this.$baseurl+"auth/menus", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.code == '0014-100'){
                    response.data.menus.map((item) => {
                        // let lists = {
                        //     id : item.id,
                        //     name : item.name,
                        //     action : ''
                        // };
                        this.menu_list.push(item);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        }
    },data() {
        return {
            currentActiveMenu: window.location.pathname.split("/")[1],
            currentMenuTab: 'Menu',
            activeColor: 'turquoise',
            dropActive: '',
            data_theme:'',
            toggleSidebarClass: false,
            toggleSidebarTheme: '',

            full_name:'',

            menu_list : []
        }
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    },computed: {
        currentRoutePath() {
            return this.$route.path;
        },
    },watch:{
        $route (to){
            this.currentActiveMenu = to.path.split("/")[1];
            document.body.className = 'theme-'+this.activeColor +' '+ this.data_theme; 
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.menuDetail();
            if(sessionStorage.getItem('user_full_name')){
                this.full_name = sessionStorage.getItem('user_full_name')
            }
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
        
        EventBus.$on('theme', (theme) => {
            this.toggleSidebarTheme = theme
        })
    }
}
</script>
<style scoped>


</style>
