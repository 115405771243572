<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
            </div>
            <div class="body">
                <p class="text-center mb-4">Enter your name as it appears on your official ID</p>
                <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration">
                    <ValidationProvider name="First Name" rules="required" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !personaldetail.firstName,'success':personaldetail.firstName}">
                            <span class="float-label">
                                <input type="text" id="firstName" v-model="personaldetail.firstName" class="form-control" placeholder="First Name*">
                                <label for="firstName" class="control-label">First Name*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !personaldetail.firstName"></i>
                                <i class="fa fa-check" v-if="personaldetail.firstName"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please provide first name</span>
                        </div>
                    </ValidationProvider>
                    <div class="form-group">
                        <span class="float-label">
                            <input type="text" id="middleName" v-model="personaldetail.middleName" class="form-control" placeholder="Middle Name">
                            <label for="middleName" class="control-label">Middle Name</label>
                            <div class="line"></div>
                        </span>
                    </div>
                    <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                        <div class="form-group" :class="{'errors': errors[0],'success':personaldetail.lastName && !errors[0]}">
                            <span class="float-label">
                                <input type="text" class="form-control" id="lastName" v-model="personaldetail.lastName" placeholder="Last Name*">
                                <label for="lastName" class="control-label">Last Name*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="personaldetail.lastName && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please provide last name</span>
                        </div>
                    </ValidationProvider>
                    <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'PersonaldetailComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        registerSubmit() {
            let personalData = {
                firstname: this.personaldetail.firstName,
                lastname: this.personaldetail.lastName,
                middleName: this.personaldetail.middleName
            }
            this.$store.dispatch('personalInfo/addPersonalDetail', personalData);
            this.$router.push({ path: '/authentication/page-password' })
        }
    },data(){
        return{
            show_banner: false,            
            personaldetail: {
                lastName:'',
                firstName: '',
                middleName:'',
                robot: false,
            },
        }
    }
}
</script>
<style>

</style>