<template>
    <div class="card card-top-line my-transfer-card">
        <money-header-widgets
            :img = "headerDetais.image"
            :title = "headerDetais.title"
            :subTitle = "headerDetais.subTitle"
            :isActions = false
        ></money-header-widgets>
        <div class="body">
            <h6 class="your-title">Recent Transactions</h6>
            <ul class="list-unstyled transfer-list">
                <li class="d-flex justify-content-between" v-for="(transaction,i) in transactions" :key="i">
                    <p>{{ transaction.name }}</p>
                    <p :class="transaction.priceClassList">${{ transaction.price }}</p>
                    <p class="text-right"><button class="btn btn-xs" :class="transaction.iconClassList"><i :class="transaction.icon"></i></button></p>
                </li>
            </ul>
            <div class="action-btn">
                <b-button type="submit" variant="primary" size="lg">View My Transfers</b-button>
            </div>
        </div>
    </div>
</template>
<script>
const MoneyTransferHeader = require('@/components/admin/dashboard/dashboard_v2_widgets/MoneyTransferHeader').default
export default {
    name:'MyTransfers',
    components:{
        'money-header-widgets':MoneyTransferHeader
    },data(){
        return{
            headerDetais:{
                image: require(`@/assets/img/icon-my-transfers.svg`),
                title:'My Transfers',
                subTitle:'View my transfer history.'
            },

            transactions:[
                {
                    name:'Jacob smith',
                    price:'5.00',
                    priceClassList:'text-danger',
                    icon:'fa fa-paper-plane',
                    iconClassList:'btn-success'
                },
                {
                    name:'Charlotte Shams',
                    price:'115.00',
                    priceClassList:'text-success',
                    icon:'fa fa-paper-plane',
                    iconClassList:'btn-success'
                }
            ]
        }
    }
}
</script>