<template>
     <div class="card card-top-line remitcare-card">
        <div class="body">
            <div class="remitcare-text">
                <h4>RemitCare</h4>
                <p>CLICK here to enroll your beneficiaries</p>
                <img class="brought-img img-fluid" width="270" src="@/assets/img/brought.svg" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Remitcare'
}
</script>