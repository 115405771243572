<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-9 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(customerSubmitForm)" id="basic-form">
                                <ul class="list-unstyled mb-0 common-form small-form">
                                    <li>
                                        <div class="row clearfix">
                                            <div class="col-lg-6">
                                                <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':invoice.customer && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Select Customer / Group*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="invoice.customer" 
                                                                    :show-labels="false" 
                                                                    :options="customerOptions" 
                                                                    :searchable="true">
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="invoice.customer && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                                    <router-link to="/invoice/add-customer" class="btn btn-primary ml-md-1 mt-2 mt-md-0 btn-lg w-100">Add New Customer</router-link>
                                                    <router-link to="/invoice/add-group" class="btn btn-primary ml-md-1 mt-2 mt-md-0 w-100 btn-lg">Add New Group</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mt-3" v-if="invoice.customer">
                                        <h4 class="page-title mb-0">Invoice Informations</h4>
                                        <ul class="list-unstyled invoice-list mt-2">
                                            <li class="row" v-for="(invoiceInfo,i) in invoiceInformations" :key="i">
                                                <label class="col-sm-6 font-weight-bold">{{ invoiceInfo.key }}</label>
                                                <p class="col-sm-6">{{ invoiceInfo.value }}</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                            <router-link  to="/invoice/list" class="btn btn-secondary btn-mw btn-lg">Cancel</router-link>
                                            <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Continue</b-button>
                                        </div>
                                    </li>
                                </ul>
                            </form> 
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
export default {
    name:'AllCustomerComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data(){
        return{
            invoice: {
                customer: null
            },

            customerOptions:[
                'PayAngel',
                'PayAngel Ghana',
                'PayAngel Group',
                'StandApp Group',
                'Angel Communities'
            ],

            invoiceInformations:[
                {key:'Business Name',value:'PayAngel'},
                {key:'Email',value:'payangel@gmail.com'},
                {key:'Phone',value:'289-335-6503'},
                {key:'country',value:'United Kingdom'},
                {key:'First Name',value:'Michael'},
                {key:'Last Name',value:'Truong'},
                {key:'Street',value:'High Street'},
                {key:'Town/City',value:'Bristol'},
                {key:'Note',value:'An invoice is a time-stamped commercial document that itemizes and records a transaction between a buyer and a seller.'},
            ]
        }
    },methods:{
        customerSubmitForm(){
            this.$router.push({path:'/invoice/add-invoice-details', query:{ type: this.$route.query.type }});
        }
    }
}
</script>