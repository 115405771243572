var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"block-header"},[_c('bread-crumb')],1),_c('div',{staticClass:"timeline-form"},[_c('div',{staticClass:"timeline-form-left"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"body"},[_c('campaign-timeline')],1)])]),_c('div',{staticClass:"timeline-form-right w-100"},[_c('div',{staticClass:"card card-top-line"},[_c('div',{staticClass:"body"},[_c('ul',{staticClass:"list-unstyled mb-0 payment-single-transfer"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{ref:"form",attrs:{"id":"campaign-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.basics1DataSubmit)}}},[_c('li',[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Campaign Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"What is the title of your campaign?"}})]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.campaign.title,'success':_vm.campaign.title}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campaign.title),expression:"campaign.title"}],staticClass:"form-control",attrs:{"type":"text","id":"title","placeholder":"Campaign Title"},domProps:{"value":(_vm.campaign.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.campaign, "title", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":"title"}},[_vm._v("Campaign Title")]),_c('div',{staticClass:"line"}),(errors.length && !_vm.campaign.title)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.campaign.title)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)})],1),_c('li',[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Campaign Tagline "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"Provide a short description that best describes your campaign to your audience."}})]),_c('ValidationProvider',{attrs:{"name":"tagline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.campaign.tagline,'success':_vm.campaign.tagline}},[_c('span',{staticClass:"float-label"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.campaign.tagline),expression:"campaign.tagline"}],staticClass:"form-control",attrs:{"id":"tagline","placeholder":"Campaign Tagline"},domProps:{"value":(_vm.campaign.tagline)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.campaign, "tagline", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":"tagline"}},[_vm._v("Campaign Tagline")]),_c('div',{staticClass:"line"}),(errors.length && !_vm.campaign.tagline)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.campaign.tagline)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)})],1),_c('li',[_c('div',{staticClass:"body px-0"},[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Campaign Card Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"Upload a square image that represents your campaign. 640 x 640 recommended resolution, 220 x 220 minimum resolution."}})]),_c('ValidationProvider',{attrs:{"name":"campaign_image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.campaign.campaign_image,'success':_vm.campaign.campaign_image}},[_c('div',{staticClass:"custom-file"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campaign.campaign_image),expression:"campaign.campaign_image"}],attrs:{"type":"hidden","name":"campaign_image","accept":"image/*"},domProps:{"value":(_vm.campaign.campaign_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.campaign, "campaign_image", $event.target.value)}}}),_c('input',{ref:"resetInputFile",staticClass:"form-control custom-file-input",attrs:{"type":"file","id":"campaign_image","placeholder":"Campaign Card Image","accept":"image/*"},on:{"change":_vm.campaignImageChange}}),_c('label',{staticClass:"custom-file-label",attrs:{"for":"campaign_image"}},[_vm._v("Choose Image")]),(errors.length && !_vm.campaign.campaign_image)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.campaign.campaign_image)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])]),(_vm.campaign.campaign_image)?_c('div',{attrs:{"id":"preview"}},[_c('img',{attrs:{"src":_vm.campaign.campaign_image}}),_c('b-button',{on:{"click":_vm.removeImage}},[_vm._v("Remove image")])],1):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-md-end justify-content-center"},[_c('b-button',{staticClass:"btn-mw",attrs:{"size":"lg"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("BACK")]),_c('b-button',{attrs:{"type":"submit","variant":"primary ml-md-1 mt-2 mt-md-0 btn-mw","size":"lg"}},[_vm._v("SAVE & CONTINUE")])],1)])]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"mb-4"},[_c('h5',[_vm._v("Basics")]),_c('p',[_vm._v("Make a good first impression: introduce your campaign objectives and entice people to learn more. This basic information will represent your campaign on your campaign page, on your campaign card, and in searches.")])])}]

export { render, staticRenderFns }