<template>
    <div> 
        <AuthHeaderV2Component currentLayout="customer-layout" :currentUrl="currentUrl"/>
            <slot></slot>
        <AuthFooterV2Component v-if="displayFooter"/>
    </div>
</template>
<script>
const default_layout = "default";
const AuthHeaderV2Component = require('@/components/admin/layouts/auth_header_v2/AuthHeaderV2Component.vue').default
const AuthFooterV2Component = require('@/components/admin/layouts/auth_footer/AuthFooterV2Component.vue').default
export default {
    name:'CustomerHomeLayout',
    components: {
        AuthHeaderV2Component,
        AuthFooterV2Component
    },data: function () {
        return {
            currentUrl: this.$route.matched[0].path.replace(/\//,'')
        }
    },computed:{
        layout(){
          return (this.$route.meta.layout || default_layout)+"-layout"
        },

        displayFooter(){
            let footer = this.layout == 'customer-layout' 
            return footer
        }
    },watch:{
        $route () {
            this.currentUrl = this.$route.matched[0].path.replace(/\//,'');
        }
    }
}
</script>
<style scoped>
    @import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap';
</style>