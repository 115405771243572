<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10 d-flex flex-column h-100 justify-content-between">
                        <div class="card">
                            <div class="header pb-1 text-center">
                                <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
                            </div>
                            <div class="body">
                                <p class="text-center mb-4">Enter your name as it appears on your ID</p>
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="form-auth-small" @submit.prevent="passes(personalDetailFormSubmit)" id="registration" ref="registration">
                                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !personaldetail.firstName,'success':personaldetail.firstName}">
                                                <span class="float-label">
                                                    <input type="text" id="firstName" v-model="personaldetail.firstName" class="form-control" placeholder="First Name*">
                                                    <label for="firstName" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !personaldetail.firstName"></i>
                                                    <i class="fa fa-check" v-if="personaldetail.firstName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" id="middleName" v-model="personaldetail.middleName" class="form-control" placeholder="Middle Name">
                                                <label for="middleName" class="control-label">Middle Name</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{'errors': errors[0],'success':personaldetail.lastName && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="text" class="form-control" id="lastName" v-model="personaldetail.lastName" placeholder="Last Name*">
                                                    <label for="lastName" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="personaldetail.lastName && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <b-button type="submit" block variant="gradiunt" size="lg">CONTINUE</b-button>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                        <div class="body text-center">
                            <img src="@/assets/home_image/Powered_by_StandApp.svg" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'PersonalDetails',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        personalDetailFormSubmit() {
            this.$router.push({ path: '/customer/set-password'})
        }
    },data(){
        return{       
            personaldetail: {
                lastName:'',
                firstName: '',
                middleName:''
            },
        }
    }
}
</script>