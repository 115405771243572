<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10 d-flex flex-column h-100 justify-content-between">
                        <div class="card">
                            <div class="header pb-3 text-center">
                                <p class="lead px-3">Ring Ring...</p>
                            </div>
                            <div class="body">
                                <p class="text-center mb-3">Enter the verification code we just sent via text to your mobile phone <strong>+12105806855</strong></p>
                                <p class="text-center mb-3"><a class="mobile-number" href="#">Change your Mobile Number</a></p>
                                <div class="otp-form">
                                    <div id="otp" class="d-flex justify-content-center">
                                        <div class="form-group">
                                            <v-otp-input
                                                ref="otpInput"
                                                input-classes="otp-input form-control m-2"
                                                separator=" "
                                                :num-inputs="5"
                                                :should-auto-focus="true"
                                                :is-input-num="false"
                                                @on-complete="handleOnComplete"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="otp-timer text-center" id="otp_timer">
                                    <router-link to="#" @click.native="resendOtp()">Resend OTP</router-link>
                                    <p>
                                        <span class="text-danger">{{ wrongOtpMsg }}</span>
                                    </p>
                                    <p class="mt-3 mb-0"> 
                                        You can request a new code if it does not arrive in: 
                                        <label class="timer-text text-danger" id="timer_countdown">02:00</label>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="body text-center">
                            <img src="@/assets/home_image/Powered_by_StandApp.svg" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const OtpInput = require("@bachdgvn/vue-otp-input").default
export default {
    name:'MobileNumberVarify',
    components:{
        'v-otp-input':OtpInput
    },data(){
        return{
            clearTimer:0,
            wrongOtpMsg:''
        }
    },methods: {
        mobileNumberFormSubmit: function() {
           this.$router.push({ path: '/customer/personal-details'})
        },

        //timer counter
        countDownTimer() {
            var duration = 2 * 60
            var timer = duration, minutes, seconds;
            this.clearTimer = setInterval(function () {
                if(timer > 0) {
                    if(document.getElementById("timer_countdown")){
                        minutes = parseInt((timer / 60)%60, 10)
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;
                        document.getElementById("timer_countdown").innerHTML = minutes + ":" + seconds;
                    }
                    --timer;
                } else {
                    document.getElementById("timer_countdown").innerHTML = "00:00";
                    clearInterval(this.clearTimer);
                    this.clearTimer = 0;
                }
            }, 1000);
        },
        
        //reset timer 
        resendOtp(){
            clearInterval(this.clearTimer);
            return this.countDownTimer();
        },

        handleOnComplete(value) {
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.mobileNumberFormSubmit();
            }
        }
    },created() {
        //OTP Timer
        clearInterval(this.clearTimer)
        this.countDownTimer()
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }
}
</script>