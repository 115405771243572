<template>
    <div class="container-fluid">
        <div class="block-header">
                <bread-crumb></bread-crumb>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <weather-carousel></weather-carousel>
            </div>
            <div class="col-lg-4 col-md-6">
                <weather-city></weather-city>
            </div>
            <div class="col-lg-4 col-md-6">
                <weather-clear-night></weather-clear-night>
            </div>
            <div class="col-lg-6 col-md-12">
                <weather-sky ></weather-sky>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Weather1 = require('@/components/core/Weather1.vue').default
const Weather2 = require('@/components/core/Weather2.vue').default
const Weather3 = require('@/components/core/Weather3.vue').default
const Weather4 = require('@/components/core/Weather4.vue').default

export default {
    name: 'WeatherComponent',
    components: {
        BreadCrumb,
        'weather-carousel': Weather1,
        'weather-city': Weather2,
        'weather-clear-night': Weather3,
        'weather-sky': Weather4,
    },data() {
        return {
           
        }
    },methods: {
      
    }
}
</script>
