<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card no-shadow">
                     <!-- Header section-->
                    <div class="header">
                        <h2>{{countryName}}</h2>
                    </div>
                    <div class="body pt-0">
                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <!-- Contact Details Tab -->
                            <b-tab title="Contact Details">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(contactDetailSubmit)" id="contactdetail-form">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ValidationProvider name="email" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors[0],'success':contactDetail.email && contactDetail.email.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <input type="email" v-model="contactDetail.email" class="form-control" id="email" placeholder="Email">
                                                                            <label for="email" class="control-label">Email</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="contactDetail.email && contactDetail.email.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group" :class="{'errors': mobileInputError == false,'success':contactDetail.mobile_number && mobileInputError}"  v-if="readonly == false">
                                                                    <div class="float-label">
                                                                        <label for="primary_number" class="control-label">Mobile Number*</label>
                                                                        <vue-tel-input
                                                                            v-model="contactDetail.mobile_number"
                                                                            v-bind="primaryMobileNumber"
                                                                            @validate ="mobileInputValidate"
                                                                            ref="telInput"
                                                                            @keypress.native="onlyNumber($event)">
                                                                            <template v-slot:arrow-icon>
                                                                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                                            </template>
                                                                        </vue-tel-input>
                                                                        <div class="line"></div>
                                                                        <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                                        <i class="fa fa-check" v-if="mobileInputError && contactDetail.mobile_number"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ValidationProvider name="address1" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors[0],'success':contactDetail.address1 && contactDetail.address1.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="contactDetail.address1" class="form-control" id="address1" placeholder="Address Line1">
                                                                            <label for="address1" class="control-label">Address Line1</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="contactDetail.address1 && contactDetail.address1.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                            <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="contactDetail.address2" class="form-control" id="address2" placeholder="Address Line2">
                                                                            <label for="address2" class="control-label">Address Line2</label>
                                                                            <div class="line"></div>
                                                                        </span>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors[0],'success':contactDetail.city && contactDetail.city.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="contactDetail.city" class="form-control" id="city" placeholder="City">
                                                                            <label for="city" class="control-label">City</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="contactDetail.city && contactDetail.city.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <ValidationProvider name="province" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors[0],'success':contactDetail.province && contactDetail.province.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="contactDetail.province" class="form-control" id="province" placeholder="Province">
                                                                            <label for="province" class="control-label">Province</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="contactDetail.province && contactDetail.province.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ValidationProvider name="postalcode" rules="required" v-slot="{ errors}">
                                                                    <div class="form-group" :class="{'errors': errors[0],'success':contactDetail.postalcode && contactDetail.postalcode.length != 0  && !errors[0]}">
                                                                        <span class="float-label">
                                                                            <input type="text" v-model="contactDetail.postalcode" class="form-control" id="postalcode" placeholder="Postal Code">
                                                                            <label for="postalcode" class="control-label">Postal Code</label>
                                                                            <div class="line"></div>
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="contactDetail.postalcode && contactDetail.postalcode.length != 0 && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <button type="submit" class="btn btn-primary">Submit</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- Bank Details Tab -->
                            <b-tab title="Bank Account Details">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(bankDetailSubmit)" id="bankdetail-form">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.bank_name" class="form-control" name="bank_name" id="bank_name" placeholder="Bank Name">
                                                                    <label for="bank_name" class="control-label">Bank Name</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="bic" v-model="bankDetail.bic" placeholder="BIC">
                                                                    <label for="bic" class="control-label">BIC</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.account_number" class="form-control" name="account_number" id="account_number" placeholder="Account Number">
                                                                    <label for="account_number" class="control-label">Account Number</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="sort_code" v-model="bankDetail.sort_code" placeholder="Sort Code">
                                                                    <label for="sort_code" class="control-label">Sort Code</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="bankDetail.iban" class="form-control" name="iban" id="iban" placeholder="IBAN">
                                                                    <label for="iban" class="control-label">IBAN</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            
                            <!-- Limits Tab -->
                            <!-- <b-tab title="Limits">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(transferLimitSubmit)" id="transferlimit-form">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.fbank_min" class="form-control" id="fbank_min" placeholder="Fast Bank Minimum Limit">
                                                                    <label for="fbank_min" class="control-label">Fast Bank Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="fbank_max" v-model="transferLimit.fbank_max" placeholder="Fast Bank Maximum Limit">
                                                                    <label for="fbank_max" class="control-label">Fast Bank Maximum Limit</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.ccollection_min" class="form-control" id="ccollection_min" placeholder="Cash Collection Minimum Limit">
                                                                    <label for="ccollection_min" class="control-label">Cash Collection Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="ccollection_max" v-model="transferLimit.ccollection_max" placeholder="Cash Collection Maximum Limit">
                                                                    <label for="ccollection_max" class="control-label">Cash Collection Maximum Limit</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.mobile_money_min" class="form-control" id="mobile_money_min" placeholder="Mobile Money Minimum Limit">
                                                                    <label for="mobile_money_min" class="control-label">Mobile Money Minimum Limit</label>
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.mobile_money_max" class="form-control" id="mobile_money_max" placeholder="Mobile Money Maximum Limit">
                                                                    <label for="mobile_money_max" class="control-label">Mobile Money Maximum Limit</label>
                                                                    <div class="line"></div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab> -->

                            <!-- Discounts Tab -->
                            <!-- <b-tab title="Discounts">
                               <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <ValidationObserver v-slot="{ passes }">
                                                <div class="body">
                                                    <form @submit.prevent="passes(transferDiscountSubmit)" id="transferdiscount-form">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Fast Bank Deposit</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.fbank_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Cash Collection</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.ccollection_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activeccollection(index)" :active="index == transferDiscount.ccollection_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.ccollection_discount_amount"  placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Mobile Money</h6>
                                                                <b-input-group class="mb-3">
                                                                    <template #prepend>
                                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                            <template #button-content>
                                                                                {{ transferDiscount.mmoney_discount_type }}
                                                                            </template>
                                                                            <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activemmoney(index)" :active="index == transferDiscount.mmoney_discount_index">
                                                                                {{ type.name }}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <b-form-input v-model="transferDiscount.mmoney_discount_amount"  placeholder="Enter Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <button type="submit" class="btn btn-primary">Update</button>
                                                    </form>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </b-tab> -->

                            <!-- Supported Currency -->
                            <b-tab title="Supported Currency">
                                <supported-currency></supported-currency>
                            </b-tab>

                            <!-- Manage fund option -->
                            <b-tab title="Limits / Discount / Charges">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <b-table 
                                                fixed
                                                responsive
                                                hover 
                                                table-class="js-basic-example table-custom mb-0 risk-table"
                                                head-variant="light"
                                                :items="supported_currency" 
                                                :fields="fields">
                                                <template #cell(action)>
                                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                        <b-dropdown-item v-for="(item,index) in fundOptionitems" :key="index" @click="showModal(item.value)">{{item.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                                <template #cell(paymentaction)>
                                                    <b-dropdown text="Actions" right variant="outline-primary">
                                                        <b-dropdown-item v-for="(item,index) in fundOptionitems" :key="index" @click="showModal(item.value)">{{item.name}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>

                                <b-modal id="manage_payout_modal" title="Manage Payout" hide-footer hide-header centered no-stacking no-close-on-backdrop>
                                    <div class="modal-body">
                                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                                            <b-tab title="Limits">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <div class="body">
                                                                <form @submit.prevent="passes(transferLimitSubmit)" id="transferlimit-form">
                                                                    <div class="row">
                                                                        <div class="form-group col-md-6">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="transferLimit.fbank_min" class="form-control" id="fbank_min" placeholder="Minimum Transfer Limit">
                                                                                <label for="fbank_min" class="control-label">Minimum Transfer Limit</label>
                                                                            </span>
                                                                        </div>
                                                                        <div class="form-group col-md-6">
                                                                            <span class="float-label">
                                                                                <input type="text" class="form-control" id="fbank_max" v-model="transferLimit.fbank_max" placeholder="Maximum Transfer Limit">
                                                                                <label for="fbank_max" class="control-label">Maximum Transfer Limit</label>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ValidationObserver>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab title="Discount">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <div class="body">
                                                                <form @submit.prevent="passes(transferDiscountSubmit)" id="transferdiscount-form">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h6>Discount</h6>
                                                                            <b-input-group class="mb-3">
                                                                                <template #prepend>
                                                                                    <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                                        <template #button-content>
                                                                                            {{ transferDiscount.fbank_discount_type }}
                                                                                        </template>
                                                                                        <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                                            {{ type.name }}
                                                                                        </b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </template>
                                                                                <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                                            </b-input-group>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ValidationObserver>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab title="Charge">
                                                <div class="form-group radio-check-grp col-md-12">
                                                    <label class="fancy-radio mb-0">
                                                        <input type="radio" name="charges" v-bind:value="'charges'" @change="setBankDepositType('charges')">
                                                        <span><i></i>Charges</span>
                                                    </label>
                                                    <label class="fancy-radio mb-0">
                                                        <input type="radio" name="charges" v-bind:value="'free'" @change="setBankDepositType('free')"> 
                                                        <span><i></i>No Charge</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-12" v-if="chargeType === 'charges'">
                                                    <div class="form-group col-md-6">
                                                        <h6>Charge Value</h6>
                                                        <b-input-group class="mb-3">
                                                            <template #prepend>
                                                                <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                    <template #button-content>
                                                                        {{ transferDiscount.fbank_discount_type }}
                                                                    </template>
                                                                    <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.fbank_discount_index">
                                                                        {{ type.name }}
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </template>
                                                            <b-form-input v-model="transferDiscount.fbank_discount_amount" placeholder="Enter Value"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary">Update</button>
                                        <button type="button" class="btn btn-danger" @click="$bvModal.hide('manage_payout_modal')">CLOSE</button>
                                    </div>
                                </b-modal>
                            </b-tab>

                            <!-- Partner / Provider Tab -->
                            <b-tab title="Partner Country Rules">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="body pt-0">
                                                <div class="chart-top-action d-md-flex justify-content-end py-3">
                                                    <!-- SearchBox Filters -->
                                                    <div class="mr-0 mr-md-3 action-top-search">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" placeholder="Search partner" aria-label="Search partner" aria-describedby="search-partner" v-model="searchText" v-on:keyup.enter="refreshPartnerTable">
                                                            <div class="input-group-append" @click="refreshPartnerTable()">
                                                                <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Toggle Filter Button -->
                                                    <div class="fliter-main mr-auto mt-3 mt-md-0">
                                                        <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                                        <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                                            <div class="d-flex justify-content-between p-3">
                                                                <div class="form-group form-select single-multiselect">
                                                                    <span class="float-label">
                                                                        <multiselect
                                                                            placeholder="Select Filter Type"
                                                                            v-model="filterBy"  
                                                                            :show-labels="false" 
                                                                            :options="filterOption" 
                                                                            label="name"
                                                                            :searchable="true">
                                                                        </multiselect> 
                                                                    </span>
                                                                    <div class="line"></div>
                                                                </div>
                                                                <div class="form-group form-select single-multiselect">
                                                                    <span class="float-label">
                                                                        <multiselect
                                                                            placeholder="Select Filter Type"
                                                                            v-model="condition"  
                                                                            :show-labels="false" 
                                                                            :options="conditionList" 
                                                                            label="name"
                                                                            :searchable="true">
                                                                        </multiselect> 
                                                                    </span>
                                                                    <div class="line"></div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                                                    <div class="line"></div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshPartnerTable()">Apply</button>
                                                                </div>
                                                                <div class="form-group">
                                                                    <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>

                                                    <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown">
                                                        <b-button size="lg" variant="primary btn-mw" @click="createPartner()">Add Partner <i class="fa fa-plus-square"></i></b-button>
                                                    </div>
                                                    <add-partner></add-partner>
                                                    <b-modal id="edit_partner" title="Edit Partner" hide-footer no-stacking no-close-on-backdrop>
                                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                                            <ValidationObserver v-slot="{ passes }">
                                                                <form @submit.prevent="passes(updatePartnerForm)" id="single-form">
                                                                    <li>
                                                                        <ValidationProvider name="rank" rules="required" v-slot="{ errors}">
                                                                            <div class="form-group" :class="{'errors': errors.length,'success':editPartner.rank}">
                                                                                <span class="float-label">
                                                                                    <input type="text" v-model="editPartner.rank" class="form-control" id="name" placeholder="Rank*">
                                                                                    <label for="owner" class="control-label">Rank*</label>
                                                                                    <div class="line"></div>
                                                                                    <i class="fa fa-times" v-if="errors.length && !editPartner.rank"></i>
                                                                                    <i class="fa fa-check" v-if="editPartner.rank"></i>
                                                                                </span>
                                                                                <!-- <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span> -->
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </li>
                                                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                        <b-button type="submit" variant="primary btn-mw" size="lg">Update Partner</b-button>
                                                                        <b-button @click="$bvModal.hide('edit_partner')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                    </div>
                                                                </form>
                                                            </ValidationObserver>
                                                        </ul>
                                                    </b-modal>
                                                </div>
                                                
                                                <b-table 
                                                    ref="partnerTable"
                                                    responsive
                                                    hover 
                                                    outlined
                                                    table-class="js-basic-example table-custom mb-0"
                                                    head-variant="light"
                                                    :items="getPartnerData" 
                                                    :fields="partnerfields"
                                                    :current-page="currentPage"
                                                    :per-page="perPage"
                                                    :sort-by.sync="sortBy"
                                                    :sort-desc.sync="sortDesc"
                                                    :sort-direction="sortDirection">
                                                    <template #head()="{label,  field: { key }}">
                                                        {{ label }}
                                                        <!-- Custom icons -->
                                                        <template>
                                                            <i v-if="sortBy !== key" class=""></i>
                                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                        </template>  
                                                    </template>
                                                    <template #cell(action)="data">
                                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                            <b-dropdown-item @click="updatePartner(data)">Edit</b-dropdown-item>
                                                            <b-dropdown-item @click="deletePartner(data.item.ruleId)">Delete</b-dropdown-item>
                                                        </b-dropdown>
                                                    </template>
                                                    <template #cell(status)="data">
                                                        <div class="custom-switch success-bg">
                                                            <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, data)">
                                                            <label class="custom-switch-label" :for="data.value.id">
                                                                <span class="custom-switch-text1">Yes</span>
                                                                <span class="custom-switch-text2">No</span>
                                                            </label>
                                                        </div>
                                                    </template>
                                                </b-table>
                                                <!-- Table Pagination -->
                                                <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                                                    <div class="per-page">
                                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="number-page">
                                                        <b-pagination 
                                                            v-model="currentPage" 
                                                            :total-rows="totalRows" 
                                                            :per-page="perPage"
                                                            hide-goto-end-buttons 
                                                            hide-ellipsis 
                                                            prev-text="Previous page" 
                                                            next-text="Next page" 
                                                            align="right">
                                                        </b-pagination>
                                                    </div>
                                                    <div class="go-page">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Go To Page</span>
                                                            </div>
                                                            <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
const AddPartner = require('@/components/admin/country/AddPartnerModal.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const supportedCurrency = require('@/components/admin/country/SupportedCurrency.vue').default
import shared from "@/shared.js";
const { VueTelInput } = require('vue-tel-input')
import parsePhoneNumber from 'libphonenumber-js'

export default {
    name:'CountryDetailComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput,
        'add-partner':AddPartner,
        'main-loader':LoaderComponent,
        'supported-currency':supportedCurrency
    },data (){
        return{
            isVisibleFilter: false,
            readonly : true,
            countryIsocode : '',

            contactDetail: {
                email: "",
                mobile_number: "",
                address1: "",
                address2: "",
                city: "",
                province: "",
                postalcode: "",
            },
            mobileInputError:'',
            primaryMobileNumber:{},

            bankDetail: {
                bank_name: "",
                bic: "",
                account_number: "",
                sort_code:"",
                iban:""
            },

            transferLimit: {
                fbank_min: "",
                fbank_max: "",
                ccollection_min: "",
                ccollection_max: "",
                mobile_money_min: "",
                mobile_money_max: ""
            },

            transferDiscount: {
                fbank_discount_amount: "",
                fbank_discount_index: 0,
                fbank_discount_type: '',
                ccollection_discount_amount: "",
                ccollection_discount_index: 1,
                ccollection_discount_type: "",
                mmoney_discount_amount: "",
                mmoney_discount_index: 0,
                mmoney_discount_type: "",
                discountType:[
                    {
                        name:'Flat'
                    },
                    {
                        name:'Percentage'
                    }
                ]
            },

            breadCrumbIteams:[
                // {
                //     title:'Country',
                //     links:'/country/list'
                // },
                // {
                //     title:this.countryName,
                //     links:''
                // }
            ],

            fundManage : {
                fundOptionType:[],
                setdata:[],
                minLimit:[],
                maxLimit:[],
                discount:[],
                discount_type: '',
                discount_index: 1,
                discountType:[
                    {
                        name:'Flat'
                    },
                    {
                        name:'Percentage'
                    }
                ]
            },           


            fundOptionitems:[
                {index:0, name:'Bank Deposit',value:'bankDeposit'},
                {index:1, name:'Mobile Money',value:'mobileMoney'},
                {index:2, name:'Cash Pickup',value:'cashPickup'}
            ],
            
            currencyName: [],
            allCurrency: [],
            supported_currency: [],

            fields: [
                { key: 'currency', label: 'Currency'},
                { key: 'action', label: 'Payout Options'},
                { key: 'paymentaction', label: 'Payment Options'}
            ],

            chargeType:'',

            showFundOption: [],
            showTransferTypeDiv : [],
            showLimitDiscountDiv: [],
            selectedIndex: [],

            items:[],
            countryName:'',
            partnerfields: [
                { key: 'partnername', label: 'Processing Partner'},
                { key: 'servicename', label: 'Service Name'},
                { key: 'account', label: 'Account Holding Entity'},
                // { key: 'bankname', label: 'Bank Name'},
                // { key: 'mobilemoneyname', label: 'Mobile Money Name'},
                { key: 'rank', label: 'Rank'},
                { key: 'status', label: 'Status'},
                { key: 'action', label: 'Actions'}
            ],

            filterOption: [
                { value: 'partnername', name:'Partner Name' },
                { value: 'servicename', name:'Service Name' },
                { value: 'bankname', name:'Bank Name' },
                { value: 'mobilemoneyname', name:'Mobile Money Name'},
                { value: 'rank', name:'Rank' },
                { value: 'status', name:'Status' }
            ],

            conditionList: [
                { value: '==', name:'equals to' },
                { value: '>=', name:'greater than or equals to' },
                { value: '>', name:'greater than' },
                { value: '<', name:'less than' },
                { value: '<=', name:'less than or equals to' },
                { value: 'like', name:'like' }
            ],
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: '',

            editPartner:{
                rank:'',
                status:''
            },
            ruleID:'',
            apiErrorCode: '',
            apiErrorMsg : ''
        }
    },methods: {  
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        onlyNumber(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },

        async countryDetail(countryID){
            try {
                const response = await this.$http.get(this.$baseurl+"countries/"+countryID);
                // JSON responses are automatically parsed.
                // console.log(response);
                this.countryName = response.data.name;

                this.breadCrumbIteams=[
                    {
                        title:'Country',
                        links:'/country/list'
                    },
                    {
                        title:this.countryName,
                        links:''
                    }
                ];
                this.countryIsocode = response.data.isocode;
                
                this.primaryMobileNumber = {
                    mode: "auto",
                    defaultCountry:response.data.isocode,
                    autoFormat:false,
                    dropdownOptions:{
                        showFlags: true,
                        disabled:true,
                        showDialCodeInSelection:true
                    }, 
                },
                this.readonly = false;

            } catch (error) {
                console.log(error);
            }
        },

        showModal(payoutValue){
            console.log(payoutValue);
            this.$bvModal.show('manage_payout_modal')
        },

        selectIndex(e, fieldName,elementindex){
            // console.log(this.setdata[elementindex], elementindex);
            if(e.target.checked) {
                if(!this.showTransferTypeDiv.some(obj => obj.index == elementindex)) {
                    for(let i=0; i<elementindex; i++){
                        if(this.showTransferTypeDiv[i] === undefined){
                            this.showTransferTypeDiv.push({'fieldName': '', 'index': i})           
                        }
                    }
                    this.showTransferTypeDiv.push({'fieldName':fieldName, 'index': elementindex})
                } else {
                    this.showTransferTypeDiv[elementindex].fieldName = fieldName;
                    this.showTransferTypeDiv[elementindex].index = elementindex;
                }  
                // console.log('if',this.showTransferTypeDiv);
            } else {
                // alert(1);
                this.showTransferTypeDiv[elementindex].fieldName = '';
                this.showTransferTypeDiv[elementindex].index = elementindex;
                // console.log('else',this.showTransferTypeDiv);

                if(this.showLimitDiscountDiv[elementindex]){
                    this.fundManage.setdata.splice(elementindex,1);
                    this.showLimitDiscountDiv[elementindex].fieldName = '';
                    this.showLimitDiscountDiv[elementindex].index = elementindex;
                }
            }
        },

        setBankDepositType(type){
            this.chargeType = type;
            // alert(type);
            // this.fundManage.setdata[elementIndex] = type;
            // // console.log(this.fundManage.setdata);
            // if(!this.showLimitDiscountDiv.some(obj => obj.index == elementIndex)) {
            //     for(let i=0; i<elementIndex; i++){
            //         if(this.showLimitDiscountDiv[i] === undefined){
            //             this.showLimitDiscountDiv.push({'depositType': '', 'fieldName': '', 'index': i})           
            //         }
            //     }
            //     this.showLimitDiscountDiv.push({'depositType':type, 'fieldName':fieldName, 'index': elementIndex})
            // } else {
            //     this.showLimitDiscountDiv[elementIndex].depositType = type;
            //     this.showLimitDiscountDiv[elementIndex].fieldName = fieldName;
            //     this.showLimitDiscountDiv[elementIndex].index = elementIndex;
            // } 
        },

        selectCurrency(e, currencyName,elementindex){
            console.log(currencyName,elementindex);
            if(e.target.checked) {
                if(!this.showFundOption.some(obj => obj.index == elementindex)) {
                    for(let i=0; i<elementindex; i++){
                        if(this.showFundOption[i] === undefined){
                            this.showFundOption.push({'fieldName': '', 'index': i})           
                        }
                    }
                    this.showFundOption.push({'fieldName':currencyName, 'index': elementindex})
                } else {
                    this.showFundOption[elementindex].fieldName = currencyName;
                    this.showFundOption[elementindex].index = elementindex;
                }  
                console.log('if',this.showFundOption);
            } else {
                // alert(1);showFundOption
                this.showFundOption[elementindex].fieldName = '';
                this.showFundOption[elementindex].index = elementindex;
                console.log('else',this.showFundOption);
                // if(this.showTransferTypeDiv[elementindex]){
                //     this.showTransferTypeDiv[elementindex].fieldName = '';
                //     this.showTransferTypeDiv[elementindex].index = elementindex;
                // }   
                // if(this.showLimitDiscountDiv[elementindex]){
                //     this.fundManage.setdata.splice(elementindex,1);
                //     this.showLimitDiscountDiv[elementindex].fieldName = '';
                //     this.showLimitDiscountDiv[elementindex].index = elementindex;
                // }
            }
        },

        fundOptionSubmit(){
            // console.log(this.fundManage.fundOptionType);
            // console.log(this.fundManage.setdata);
            this.fundManage.fundOptionType.forEach((element,index) => {
                console.log(element, this.fundManage.setdata[index]);
            })
            // console.log(this.fundOptionType['charges'], this.fundOptionType['free'])
        },

        //set active prefix
        activefbanktype(menuIndex){
            this.transferDiscount.fbank_discount_index = menuIndex;
            this.transferDiscount.fbank_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activeccollection(menuIndex){
            this.transferDiscount.ccollection_discount_index = menuIndex;
            this.transferDiscount.ccollection_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activemmoney(menuIndex){
            this.transferDiscount.mmoney_discount_index = menuIndex;
            this.transferDiscount.mmoney_discount_type = this.transferDiscount.discountType[menuIndex].name;
        },
        activefund(menuIndex){
            this.fundManage.discount_index = menuIndex;
            this.fundManage.discount_type = this.fundManage.discountType[menuIndex].name;
        },

        async contactDetailData(countryID) {            
            try {
                const response = await this.$http.get(this.$baseurl+"countries/contact/"+countryID,{
                    headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        },
                });
                if(response.data.code == '0146-100'){
                    const contactdetails = response.data.details;
                    const phoneNumber = parsePhoneNumber(contactdetails.phone, this.countryIsocode);
                    this.contactDetail.email = contactdetails.email;
                    this.contactDetail.mobile_number = contactdetails.phone ? phoneNumber.nationalNumber : '';
                    this.contactDetail.address1 = contactdetails.addressline1;
                    this.contactDetail.address2 = contactdetails.addressline2;
                    this.contactDetail.city = contactdetails.city;
                    this.contactDetail.province = contactdetails.province;
                    this.contactDetail.postalcode = contactdetails.postalcode;
                }
            } catch (error) {
                console.log(error);
            }
        },

        contactDetailSubmit() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                let contactData = {
                    countryid:sessionStorage.getItem("countryId"),
                    addressline1:this.contactDetail.address1,
                    addressline2:this.contactDetail.address2,
                    province:this.contactDetail.province,
                    city:this.contactDetail.city,
                    postalcode:this.contactDetail.postalcode,
                    email:this.contactDetail.email,
                    phone:this.contactDetail.mobile_number
                }
                // console.log('contact data',contactData);

                this.$http.post(this.$baseurl+"countries/contact", contactData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    },
                }).then((res) => {
                    if(res.data.code == '0146-100') {
                        shared.toastrSuccess(res.data.message);
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                });
            }
        },

        async bankDetailData() {
            this.bankDetail.bank_name = 'N/A',
            this.bankDetail.bic = '123457',
            this.bankDetail.account_number = '110123456789',
            this.bankDetail.sort_code = 'TEST123',
            this.bankDetail.iban = ''
        },
        bankDetailSubmit() {
            console.log("Submitting bank detail");
        },

        async transferLimitData() {
            this.transferLimit.fbank_min = "100",
            this.transferLimit.fbank_max = "1000",
            this.transferLimit.ccollection_min = "100",
            this.transferLimit.ccollection_max = "1001",
            this.transferLimit.mobile_money_min = "100",
            this.transferLimit.mobile_money_max = "1002"
        },
        transferLimitSubmit() {
            console.log("Submitting transfer limit");
        },

        async transferDiscountData() {
            this.transferDiscount.fbank_discount_amount= '10',
            this.transferDiscount.fbank_discount_type = 'Flat',
            this.transferDiscount.ccollection_discount_amount = '5',
            this.transferDiscount.ccollection_discount_type = 'Percentage',
            this.transferDiscount.mmoney_discount_amount = '50',
            this.transferDiscount.mmoney_discount_type = 'Flat'
        },
        transferDiscountSubmit() {
            console.log("Submitting transfer discount",this.transferDiscount.fbank_discount_type,this.transferDiscount.ccollection_discount_type,this.transferDiscount.mmoney_discount_type);
        },

        async getPartnerData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'rank'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'asc'
                } else {
                    sort = 'desc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    // countryid : sessionStorage.getItem("countryId"),
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText,
                    filterby : this.filterBy ? this.filterBy.value : '',
                    filtervalue : this.filterValue,
                    condition : this.condition ? this.condition.value : '=='
                }
                const response = await this.$http.get(this.$baseurl+"partner/integrations/"+sessionStorage.getItem("countryId"), { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.code == '1101-000'){
                    response.data.details.map((item, index) => {
                        let acc = '';
                        if(item.bankname){
                            acc = item.bankname ? item.bankname : '-';
                        } else if (item.mobilemoneyname){
                            acc = item.mobilemoneyname ? item.mobilemoneyname : '-';
                        } else {
                            acc = '-';
                        }

                        let businessLists = {
                            id:item.partnerid,
                            ruleId:item.configid,
                            partnername:item.partnername,
                            servicename: item.servicename ? item.servicename : '-',
                            account: acc,
                            // bankname:item.bankname ? item.bankname : '-',
                            // mobilemoneyname:item.mobilemoneyname ? item.mobilemoneyname : '-',
                            rank:item.rank,
                            status:{id:'status_'+index,isChecked:item.status},
                            action:''
                        };
                        this.items.push(businessLists);
                    });
                    this.totalRows = response.data.query.total;
                    return this.items;
                }              
            } catch (error) {
                console.log(error);
            }
        },
        
        refreshPartnerTable() {
            this.$refs.partnerTable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshPartnerTable();
        },

        createPartner(){
            this.$bvModal.show('add_partner');
        },

        updatePartner(partnerDetail){
            // console.log(partnerDetail);
            this.ruleID = partnerDetail.item.ruleId;
            this.editPartner.rank = partnerDetail.item.rank;
            this.editPartner.status = partnerDetail.item.status.isChecked;
            this.$bvModal.show('edit_partner');
        },

        updatePartnerForm(){
            let updateData = {
                ruleid: this.ruleID,
                rank: parseInt(this.editPartner.rank),
                status: this.editPartner.status
            }
            this.$http.put(this.$baseurl+'partner/rule', updateData, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                },
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                if(res.data.code == '1101-000') {
                    shared.toastrSuccess(res.data.message);
                    this.refreshPartnerTable();
                    this.$bvModal.hide('edit_partner');
                    this.apiErrorMsg = '';
                    this.apiErrorCode = '';
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
            });
        },

        updateStatus(event, row){
            this.$refs.loader.show();
            // console.log(event.target.checked, row);
            let updateData = {
                ruleid: row.item.ruleId,
                rank: parseInt(row.item.rank),
                status: event.target.checked
            }            
            this.$http.put(this.$baseurl+'partner/rule', updateData, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '1101-000') {
                    shared.toastrSuccess(res.data.message);
                    this.$refs.loader.hide();
                    this.refreshPartnerTable();
                } else {
                    this.$refs.loader.hide();
                    this.refreshPartnerTable();
                }
            }).catch((error) => {
                this.$refs.loader.hide();
                this.refreshPartnerTable();
                shared.toastrError(error.response.data.message);
            });
        },

        deletePartner(ruleId) {
            // console.log(partnerId);
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    this.$http.delete(this.$baseurl+"partner/rule/"+ruleId, {    
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '1101-000') {
                            shared.toastrSuccess(res.data.message);
                            this.refreshPartnerTable();
                            this.$refs.loader.hide();
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        // if(this.apiErrorCode == "E0065-005") {
                        //     this.$refs.loader.hide();
                        //     shared.toastrError(error.response.data.message);
                        // } else {
                            this.apiErrorMessage = error.response.data.message;
                        // }
                    });                
                }
            })

        },

        setSupportedCurrency(currencyData){
            this.supported_currency = currencyData;
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            if(sessionStorage.getItem("countryId")){
                this.countryDetail(sessionStorage.getItem("countryId"));
                this.contactDetailData(sessionStorage.getItem("countryId"));
            }        
            this.$root.$refs.CountryDetailComponent = this;
            
            this.bankDetailData();
            this.transferLimitData();
            this.transferDiscountData();
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }
}
</script>
<style lang="sass">

</style>
