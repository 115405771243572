<template>
    <div class="container-fluid">
        <!-- BreadCrumb -->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <!-- Campaign Timeline -->
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <campaign-timeline></campaign-timeline>
                    </div>
                </div>
            </div>
            <!-- Campaign Form -->
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your campaign objectives and entice people to learn more. This basic information will represent your campaign on your campaign page, on your campaign card, and in searches.</p>
                            </li>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(basics1DataSubmit)" id="campaign-form" ref="form">
                                    <li>
                                        <div class="head">
                                            <label>Campaign Title <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="What is the title of your campaign?"></i>
                                        </div>
                                        <ValidationProvider name="title" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !campaign.title,'success':campaign.title}">
                                                <span class="float-label">
                                                    <input type="text" v-model="campaign.title" class="form-control" id="title" placeholder="Campaign Title">
                                                    <label for="title" class="control-label">Campaign Title</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.title"></i>
                                                    <i class="fa fa-check" v-if="campaign.title"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="head">
                                            <label>Campaign Tagline <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Provide a short description that best describes your campaign to your audience."></i>
                                        </div>
                                        <ValidationProvider name="tagline" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !campaign.tagline,'success':campaign.tagline}">
                                                <span class="float-label">
                                                    <textarea v-model="campaign.tagline" class="form-control" id="tagline" placeholder="Campaign Tagline"></textarea>
                                                    <label for="tagline" class="control-label">Campaign Tagline</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.tagline"></i>
                                                    <i class="fa fa-check" v-if="campaign.tagline"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="body px-0">
                                            <div class="head">
                                                <label>Campaign Card Image <span class="text-danger">*</span></label>
                                                <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Upload a square image that represents your campaign. 640 x 640 recommended resolution, 220 x 220 minimum resolution."></i>
                                            </div>
                                            <ValidationProvider name="campaign_image" rules="required" v-slot="{ errors}">
                                                <div class="form-group" :class="{'errors': errors.length && !campaign.campaign_image,'success':campaign.campaign_image}">
                                                    <div class="custom-file">
                                                        <input type="hidden" name="campaign_image" v-model="campaign.campaign_image" accept="image/*">
                                                        <input type="file" ref="resetInputFile" id="campaign_image" class="form-control custom-file-input" placeholder="Campaign Card Image" accept="image/*" @change="campaignImageChange" />
                                                        <label class="custom-file-label" for="campaign_image">Choose Image</label>
                                                        <i class="fa fa-times" v-if="errors.length && !campaign.campaign_image"></i>
                                                        <i class="fa fa-check" v-if="campaign.campaign_image"></i>
                                                    </div>
                                                </div>
                                                <div id="preview" v-if="campaign.campaign_image">
                                                    <img :src="campaign.campaign_image"/>
                                                    <b-button @click="removeImage">Remove image</b-button>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </li>
                                    <div class="d-flex flex-column flex-md-row justify-content-md-end justify-content-center">
                                        <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const CampaignTimeline = require('@/components/core/CampaignTimeline').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name:'AddCampaignComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        'campaign-timeline':CampaignTimeline
    },data(){
        return{
            campaign:{
                title:'',
                tagline:'',
                campaign_image: null
            }
        }
    },methods: {
        //image upload
        campaignImageChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this.campaign.campaign_image = URL.createObjectURL(file);
            }
        },

        //submit form data
        basics1DataSubmit(){
            this.$router.push({ path: '/crowdfunding/campaign-basic2' })
        },

        //remove campaign image
        removeImage: function () {
            this.$refs.resetInputFile.value = null;
            this.campaign.campaign_image = null;
        },
    }
}
</script>
