<template>
    <div class="card card-img number-chart" :class="[card_top_line || card_top_line_color ? 'card-top-line' : '', card_top_line_color]">
        <card-actions
            :actions = "dashboardActions"
            :isLight = true
        ></card-actions>
        <div class="card-body h-100 flex-column d-flex">
            <div class="d-flex justify-content-between pt-3" :class="{ 'knob-chart-child': !img_class}">
                <div class="d-flex flex-column number">
                    <h6>{{ title }}</h6>
                    <span v-html="subTitle" :class="{'small': isSubtitleSmall}"></span>
                    <div class="cta-btn mt-auto" v-if="button_text">
                        <button class="btn btn-xs btn-primary">{{ button_text }}</button>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center" :class="{ '' : img_class}">
                    <!-- <img :src="images" alt="" v-if="img_class" width="55"> -->
                    <span :class="img_class" v-if="img_class"></span>
                    <vue-circle-control-slider v-if="!img_class"
                        :size="100"
                        :offKnob="true"
                        :value="circle_width"
                        sign="%"
                        dial
                        :dialFontSize="14"
                        :progressWidth="4"
                        :circleWidth="4"
                        :progressColor="circle_class"
                        circleColor="#eeeeee"
                    />
                    <small class="text-muted text-center" :class="{'d-none': img_class}"><span class="badge ml-0 mr-0" :class="classes">{{ compare_text }}</span></small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const CardActions = require('@/components/core/CardActions.vue').default
import VueCircleControlSlider from 'vue-circle-control-slider'

export default {
    name:'Earning',
    components: {
        'card-actions': CardActions,
        VueCircleControlSlider
    },
    props: {
        title : String, 
        subTitle : String,
        isSubtitleSmall: Boolean,
        classes : String, 
        compare_text: String, 
        card_top_line: Boolean,
        card_top_line_color: String,
        img_class: String,
        circle_class:String,
        circle_width: Number,
        button_text:String
    },
    data(){
        return{
            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Send",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Top-up",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Withdraw",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "More",
                    url: "javascript:void(0);"
                }
            ]
        }
    }
}
</script>
