<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recent transactions -->
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header d-flex align-items-center justify-content-between">
                        <h2>Country List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search country" aria-label="Search country" aria-describedby="search-country" v-model="searchText" v-on:keyup.enter="refreshCountryTable">
                                    <div class="input-group-append" @click="refreshCountryTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                            </div>
                        </div>
                        <b-table 
                            ref="countryTable"
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="getCountryData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.uuid"
                                    :title="data.value.uuid" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Active</b-dropdown-item>
                                    <b-dropdown-item>Inactive</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template #cell(details)="data">
                                <a class="cursor-pointer font-color font-weight-bold" @click="setCountry(data)">
                                <!-- <router-link class="font-color font-weight-bold" :to="'/country/details/' + data.value.id"> -->
                                    <div class="align-items-center d-flex">
                                        <span :class="data.value.flagClass" class="mr-2"></span>
                                        <span>{{ data.value.name }}</span>
                                    </div>
                                <!-- </router-link> -->
                                </a>
                            </template>
                            <template #cell(domestic)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'domestic', data)">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(destination)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'destination', data)">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(crossborder)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'crossborder', data)">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template> 
                            <template #cell(isblacklisted)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'isblacklisted', data)">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template> 

                            <template #cell(show_details)="row">
                                <!-- <span v-if="row.item.countryIsoCode == 'US'">{{row.item.countryIsoCode}}</span> -->
                                <a class="btn" :class="[row.detailsShowing  ? 'btn-primary text-white' : 'btn-outline-primary']" href="javascript:void(0);" @click="row.toggleDetails(); getState(row.item.uuid)" v-if="row.item.countryIsoCode == 'US' || row.item.countryIsoCode == 'GH' || row.item.countryIsoCode == 'CA' || row.item.countryIsoCode == 'GB' || row.item.countryIsoCode == 'AU'">
                                    <i class="fa fa-angle-down" v-if="!row.detailsShowing"></i>
                                    <i class="fa fa-angle-up" v-if="row.detailsShowing"></i>
                                </a>
                            </template>
                            <template #row-details>
                                <b-card class="scroll-table">
                                    <b-table 
                                        ref="stateTable"
                                        responsive
                                        hover 
                                        outlined
                                        table-class="js-basic-example table-custom mb-0"
                                        head-variant="light"
                                        :items="stateItems" 
                                        :fields="stateFields"
                                        :sort-direction="sortDirection">
                                        <!-- <template #head()="{label,  field: { key }}">
                                            {{ label }}
                                            <template>
                                                <i v-if="subSortBy !== key" class=""></i>
                                                <i v-else-if="subSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                            </template>  
                                        </template> -->
                                        <!-- <template #cell(currency)="row">
                                            <div class="d-flex align-items-center">
                                                <span :class="row.value.flagClass" class="mr-2"></span>
                                                <span>{{ row.value.cname }}</span>
                                            </div>
                                        </template> -->
                                        <template #cell(domestic)="data">
                                            <div class="custom-switch success-bg">
                                                <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStateStatus($event, 'domestic', data)">
                                                <label class="custom-switch-label" :for="data.value.id">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </template>
                                        <template #cell(destination)="data">
                                            <div class="custom-switch success-bg">
                                                <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStateStatus($event, 'destination', data)">
                                                <label class="custom-switch-label" :for="data.value.id">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </template>
                                        <template #cell(crossborder)="data">
                                            <div class="custom-switch success-bg">
                                                <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStateStatus($event, 'crossborder', data)">
                                                <label class="custom-switch-label" :for="data.value.id">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </template> 
                                        <template #cell(isblacklisted)="data">
                                            <div class="custom-switch success-bg">
                                                <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStateStatus($event, 'isblacklisted', data)">
                                                <label class="custom-switch-label" :for="data.value.id">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </template>
                                    </b-table>
                                </b-card>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>

<script>
  import BreadCrumb from '@/components/BreadCrumb'
  import TransactionsTopRow from '@/components/core/TransactionsTopRow.vue'
  const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
  import shared from "@/shared.js";

  export default  {
    name: 'country-component',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow,
        'main-loader':LoaderComponent
    },data (){
        return{
            isVisibleFilter: false,

            //Recent transactions table data
            items:[],
           
            //Recent transactions table header
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'details', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'isocode', label: 'Alpha-2'},
                { key: 'isocode3', label: 'Alpha-3'},
                { key: 'numeric', label: 'Numeric' },
                { key: 'dialcode', label: 'Dialling Code' },
                { key: 'currency', label: 'Currency' },
                { key: 'domestic', label: 'Domestic'},
                { key: 'crossborder', label: 'Cross Border'},
                { key: 'destination', label: 'Destination'},
                { key: 'isblacklisted', label: 'Blacklist'},
                // { key: 'action', label: 'Status'},
                { key: 'show_details', label: '', thStyle:'width:40px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Recent transactions table pagination
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',

            //Summary data
            transactions_toprows:[],

            stateItems:[],
           
            //All Acounts list table header fields
            stateFields: [
                { key: 'name', label: 'State Name',sortable: true,thClass:'th_sortfix'},
                { key: 'domestic', label: 'Domestic'},
                { key: 'crossborder', label: 'Cross Border'},
                { key: 'destination', label: 'Destination'},
                { key: 'isblacklisted', label: 'Blacklist'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],
        }
    },methods: {
        //Recent transactions table go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        refreshCountryTable() {
            this.$refs.countryTable.refresh();
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },

        statusChangeAlert(event, key, row){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            
            swalWithBootstrapButtons.fire({
                title: "Are you sure? You want to change this status",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateStatus(event, key, row);
                }
            })  
        },

        updateStatus(event, key, row){
            this.$refs.loader.show();
            // console.log(event.target.checked, key, row);
            let dataJson = {
                'domestic' : key==='domestic'? event.target.checked : row.item.domestic.isChecked,
                'crossborder' : key==='crossborder'? event.target.checked: row.item.crossborder.isChecked,
                'destination' : key==='destination'? event.target.checked: row.item.destination.isChecked,
                'blacklisted' : key==='isblacklisted'? event.target.checked: row.item.isblacklisted.isChecked, 
            }
            // console.log(dataJson);
            this.$http.put(this.$baseurl+"countries/"+row.item.countryid, dataJson,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0003-000') {
                    shared.toastrSuccess(res.data.message);
                    this.$refs.loader.hide();
                    this.totalCountries();
                    this.refreshCountryTable();
                } else {
                    this.$refs.loader.hide();
                    this.refreshCountryTable();
                }
            }).catch((error) => {
                //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                this.$refs.loader.hide();
                this.refreshCountryTable();
                shared.toastrError(error.response.data.message);
            });
        },

        async getCountryData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'asc'
                } else {
                    sort = 'desc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText
                }
                const response = await this.$http.get(this.$baseurl+"countries/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item, index) => {
                    let businessLists = {
                        uuid:{uuid:item.countryid},
                        countryid:item.countryid,
                        details: {id:item.countryid,name:item.countryname,flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-md fi-round"},
                        countryIsoCode:item.isocode,
                        isocode:item.alpha2,
                        isocode3:item.alpha3,
                        currency:item.currencycode,
                        numeric:item.isonumeric,
                        dialcode:item.mobilecountrycode,
                        domestic:{id:'domestic_'+index,isChecked:item.domestic,countryId:item.countryid},
                        destination:{id:'destination_'+index,isChecked:item.destination,countryId:item.countryid},
                        crossborder:{id:'crossborder_'+index,isChecked:item.crossborder,countryId:item.countryid},
                        isblacklisted:{id:'isblacklisted_'+index,isChecked:item.isblacklisted,countryId:item.countryid},
                        // action:'',
                        show_details:''

                    };
                    this.items.push(businessLists);
                }); 
                this.totalRows = response.data.query.total;
                return this.items;               
            } catch (error) {
                console.log(error);
            }
        },

        async totalCountries(){
            try {
                const response = await this.$http.get(this.$baseurl+"countries/stats", {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.code == '0003-000'){
                    this.transactions_toprows = [
                        {
                            icon:'fa fa-globe',
                            text:'Total Countries',
                            price:response.data.details.total.toString(),
                            // progress_class:'bg-primary',
                            // progressbar_width:'87',
                            // compared_text:'1% compared to yesterday'
                        },
                        {
                            icon:'fa fa-map-o',
                            text:'Total Domestic Countries',
                            price:response.data.details.domestic.toString(),
                            // progress_class:'bg-success',
                            // progressbar_width:'28',
                            // compared_text:'19% compared to last month'
                        },
                        {
                            icon:'fa fa-map-marker',
                            text:'Total Cross border Countries',
                            price:response.data.details.crossborder.toString(),
                            // progress_class:'bg-warning',
                            // progressbar_width:'20',
                            // compared_text:'19% compared to last year'
                        },
                        {
                            icon:'fa fa-map-marker',
                            text:'Total Destination Countries',
                            price:response.data.details.destination.toString(),
                            // progress_class:'bg-info',
                            // progressbar_width:'41',
                            // compared_text:'19% compared to last year'
                        }
                    ];
                }
                            
            } catch (error) {
                console.log(error);
            }
        },

        setCountry(data){
            // console.log(data);
            sessionStorage.setItem('countryId', data.item.countryid);
            this.$router.push({path:'/country/details'});
        },
        refreshStateTable() {
            this.$refs.stateTable.refresh();
        },

        async getState(countryid){
            try {
                this.stateItems = [];
                const response = await this.$http.get(this.$baseurl+"states/"+countryid, { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                
                response.data.details.map((item, index) => {
                    let stateLists = {
                        uuid:item.stateid,
                        name:item.statename,
                        domestic:{id:'statedomestic_'+index,isChecked:item.domestic,stateId:item.stateid},
                        destination:{id:'statedestination_'+index,isChecked:item.destination,stateId:item.stateid},
                        crossborder:{id:'statecrossborder_'+index,isChecked:item.crossborder,stateId:item.stateid},
                        isblacklisted:{id:'stateisblacklisted_'+index,isChecked:item.isblacklisted,stateId:item.stateid}
                    };
                    this.stateItems.push(stateLists);
                }); 
                return this.stateItems;               
            } catch (error) {
                console.log(error);
            }
        },

        updateStateStatus(event, key, row){
            this.$refs.loader.show();
            // console.log(row);
            let dataJson = {
                'stateid': row.item.uuid,
                'domestic' : key==='domestic'? event.target.checked : row.item.domestic.isChecked,
                'crossborder' : key==='crossborder'? event.target.checked: row.item.crossborder.isChecked,
                'destination' : key==='destination'? event.target.checked: row.item.destination.isChecked,
                'isblacklisted' : key==='isblacklisted'? event.target.checked: row.item.isblacklisted.isChecked, 
            }
            // console.log(dataJson);
            this.$http.put(this.$baseurl+"states", dataJson,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0149-100') {
                    shared.toastrSuccess(res.data.message);
                    // this.getState(row.item.uuid);
                    this.refreshCountryTable();
                    // this.refreshStateTable();
                    this.$refs.loader.hide();
                } else {
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.totalRows = this.items.length;
            this.totalCountries();
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
    }
  }
</script>

<style>
/* .flag-align{
    bottom: -4px;
} */
</style>
