var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"block-header"},[_c('bread-crumb')],1),_c('div',{staticClass:"timeline-form"},[_c('div',{staticClass:"timeline-form-left"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"body"},[_c('events-timeline')],1)])]),_c('div',{staticClass:"timeline-form-right w-100"},[_c('div',{staticClass:"card card-top-line"},[_c('div',{staticClass:"body"},[_c('ul',{staticClass:"list-unstyled mb-0 payment-single-transfer"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{ref:"form",attrs:{"id":"event-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.event1DataSubmit)}}},[_c('li',[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Event Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"What is the title of your event?"}})]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.event.title,'success':_vm.event.title}},[_c('span',{staticClass:"float-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.title),expression:"event.title"}],staticClass:"form-control",attrs:{"type":"text","id":"title","placeholder":"Event Title"},domProps:{"value":(_vm.event.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "title", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":"title"}},[_vm._v("Event Title")]),_c('div',{staticClass:"line"}),(errors.length && !_vm.event.title)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.event.title)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)})],1),_c('li',[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Event Tagline "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"Provide a short description that best describes your event to your audience."}})]),_c('ValidationProvider',{attrs:{"name":"tagline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.event.tagline,'success':_vm.event.tagline}},[_c('span',{staticClass:"float-label"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.tagline),expression:"event.tagline"}],staticClass:"form-control",attrs:{"id":"tagline","placeholder":"Event Tagline"},domProps:{"value":(_vm.event.tagline)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "tagline", $event.target.value)}}}),_c('label',{staticClass:"control-label",attrs:{"for":"tagline"}},[_vm._v("Event Tagline")]),_c('div',{staticClass:"line"}),(errors.length && !_vm.event.tagline)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.event.tagline)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])]}}],null,true)})],1),_c('li',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('ValidationProvider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group datepicker",class:{'errors': errors[0],'success':_vm.event.startDate && !errors[0]}},[_c('span',{staticClass:"float-label"},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("Event Start Date Time*")]),_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"icon-calendar"})])]),_c('date-picker',{staticClass:"form-control",attrs:{"name":"startdate","config":_vm.config},model:{value:(_vm.event.startDate),callback:function ($$v) {_vm.$set(_vm.event, "startDate", $$v)},expression:"event.startDate"}})],1),(errors.length && errors[0])?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.event.startDate && !errors[0])?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('ValidationProvider',{attrs:{"name":"endDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group datepicker",class:{'errors': errors[0],'success':_vm.event.endDate && !errors[0]}},[_c('span',{staticClass:"float-label"},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("Event End Date Time*")]),_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"icon-calendar"})])]),_c('date-picker',{staticClass:"form-control",attrs:{"name":"enddate","config":_vm.config},model:{value:(_vm.event.endDate),callback:function ($$v) {_vm.$set(_vm.event, "endDate", $$v)},expression:"event.endDate"}})],1),(errors.length && errors[0])?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.event.endDate && !errors[0])?_c('i',{staticClass:"fa fa-check"}):_vm._e()],1)])]}}],null,true)})],1)])]),_c('li',[_c('div',{staticClass:"body px-0"},[_c('div',{staticClass:"head"},[_c('label',[_vm._v("Event Card Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"fa fa-info-circle ml-1",attrs:{"aria-hidden":"true","title":"Upload a square image that represents your event. 640 x 640 recommended resolution, 220 x 220 minimum resolution."}})]),_c('ValidationProvider',{attrs:{"name":"event_image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{'errors': errors.length && !_vm.event.event_image,'success':_vm.event.event_image}},[_c('div',{staticClass:"custom-file"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.event_image),expression:"event.event_image"}],attrs:{"type":"hidden","name":"event_image","accept":"image/*"},domProps:{"value":(_vm.event.event_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "event_image", $event.target.value)}}}),_c('input',{ref:"resetInputFile",staticClass:"form-control custom-file-input",attrs:{"type":"file","id":"event_image","placeholder":"event Card Image","accept":"image/*"},on:{"change":_vm.eventImageChange}}),_c('label',{staticClass:"custom-file-label",attrs:{"for":"event_image"}},[_vm._v("Choose Image")]),(errors.length && !_vm.event.event_image)?_c('i',{staticClass:"fa fa-times"}):_vm._e(),(_vm.event.event_image)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])]),(_vm.event.event_image)?_c('div',{attrs:{"id":"preview"}},[_c('img',{attrs:{"src":_vm.event.event_image}}),_c('b-button',{on:{"click":_vm.removeImage}},[_vm._v("Remove image")])],1):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-md-end justify-content-center"},[_c('b-button',{staticClass:"btn-mw",attrs:{"size":"lg"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("BACK")]),_c('b-button',{attrs:{"type":"submit","variant":"primary ml-md-1 mt-2 mt-md-0 btn-mw","size":"lg"}},[_vm._v("SAVE & CONTINUE")])],1)])]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"mb-4"},[_c('h5',[_vm._v("Basics")]),_c('p',[_vm._v("Make a good first impression: introduce your event objectives and entice people to learn more. This basic information will represent your event on your event page, on your event card, and in searches.")])])}]

export { render, staticRenderFns }