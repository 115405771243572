<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :showPayorTransferButton="true" :showProfile="true"></bread-crumb>
        </div>
        <div class="row clearfix row-deck">
            <div class="col-md-4 col-sm-12" v-for="(earning,index) in earnings" :key="index">
                <earnings-component
                    :title = "earning.title"
                    :subTitle = "earning.subTitle"
                    :isSubtitleSmall = "earning.isSubtitleSmall"
                    :classes = "earning.class"
                    :compare_text= "earning.compare_text"
                    :icon_class= "earning.icon_class"
                    :card_top_line= true
                    :img_class= earning.img_class
                    :circle_class= earning.circle_class
                    :circle_width = earning.circle_width
                    :button_text = earning.button_text
                    ref="selectableTable"
                ></earnings-component>
            </div>
        </div>

        <div class="row clearfix row-deck">
            <div class="col-md-8">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Table Filter</h2>
                        <card-actions
                            :actions = "dashboardActions"
                            :isLight = true
                        ></card-actions>
                    </div>
                    <div class="body">
                        <div class="button-filter">
                            <button type="button" class="btn btn-simple btn-sm btn-default btn-filter" data-target="all" @click="dropdawn_filter('todos')">Todos</button>
                            <button type="button" class="btn btn-simple btn-sm m-1 btn-success btn-filter" data-target="approved" @click="dropdawn_filter('approved')">Approved</button>
                            <button type="button" class="btn btn-simple btn-sm m-1 btn-warning btn-filter" data-target="suspended" @click="dropdawn_filter('suspended')">Suspended</button>
                            <button type="button" class="btn btn-simple btn-sm m-1 btn-info btn-filter" data-target="pending" @click="dropdawn_filter('pending')">Pending</button>
                            <button type="button" class="btn btn-simple btn-sm m-1 btn-danger btn-filter" data-target="blocked" @click="dropdawn_filter('blocked')">Blocked</button>
                        </div>
                        <b-table 
                            responsive
                            hover 
                            class="m-t-20"
                            table-class="m-b-0 table-filter"
                            thead-class="hidden_header"
                            :items="items" 
                            :fields="fields">
                            <template #cell(images)="data">
                                <div class="media-object flag-img">
                                    <img :src="data.value.path" alt="" width="50px" class="rounded-circle">
                                    <span :class="data.value.flag_class"></span>
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(dropdawn)="data">
                                <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="onChange($event,data.index+1)">
                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Top 5 Receivers</h2>
                        <card-actions
                            :actions = "dashboardActions"
                            :isLight = true
                        ></card-actions>    
                    </div>
                    <div class="body pb-0">
                        <ul class="right_chat list-unstyled">
                            <li v-for="(receiver,index) in receivers" :key="index">
                                <div class="media align-items-center">
                                    <div class="flag-img">
                                        <img class="media-object" :src="receiver.images" alt="">
                                        <span :class="receiver.image_flag"></span>
                                    </div>
                                    <div class="media-body">
                                        <span class="name">{{ receiver.name }}</span>
                                    </div>
                                    <button class="btn btn-success btn-xs"><i :class="receiver.icon"></i></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr>
                    <div class="header pb-3 pt-0"><h2>Top 5 Senders</h2></div>
                    <div class="body pt-0">
                        <ul class="right_chat list-unstyled">
                                <li v-for="(sender,index) in senders" :key="index">
                                <div class="media align-items-center">
                                    <div class="flag-img">
                                        <img class="media-object" :src="sender.images" alt="">
                                        <span :class="sender.image_flag"></span>
                                    </div>
                                    <div class="media-body">
                                        <span class="name">{{ sender.name }}</span>
                                    </div>
                                    <button class="btn btn-success btn-xs"><i :class="sender.icon"></i></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix row-deck">
            <div class="col-md-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Send/Receive Statistics</h2>
                        <card-actions
                            :actions = "dashboardActions"
                            :isLight = true
                        ></card-actions>    
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <div class="date-range-main mr-3">
                                <template>
                                    <v-md-date-range-picker
                                        :opens="opens"
                                        :presets="presets"
                                        :autoApply="autoApplyDateRange"
                                    ></v-md-date-range-picker>
                                </template>
                            </div>
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <div class="btn-group ml-0 ml-md-3 mt-3 mt-md-0" role="group" aria-label="Basic example">
                                <button class="btn btn-outline-primary" id="daily" @click="xcategories('daily')" :class="{active: selection =='daily'}">Daily</button>
                                <button class="btn btn-outline-primary" id="weekly" @click="xcategories('weekly')" :class="{active: selection =='weekly'}">Weekly</button>
                                <button class="btn btn-outline-primary" id="monthly"  @click="xcategories('monthly')" :class="{active: selection =='monthly'}">Monthly</button>
                                <button class="btn btn-outline-primary" id="yearly" @click="xcategories('yearly')" :class="{active: selection =='yearly'}">Yearly</button>
                            </div>
                        </div>
                        <div class="date-slider d-flex">
                            <carousel class="date-slider-list" :scrollPerPage="false" :perPageCustom="[[375, 2], [420, 3], [768, 5], [1350, 7], [1500, 9]]"  :mouse-drag="false" :paginationEnabled="false" :navigationEnabled="true" navigationPrevLabel="<i class='fa fa-angle-left'></i>" navigationNextLabel="<i class='fa fa-angle-right'></i>">
                                <slide class="date-slider-item" v-for="index in last_date" :key="index">
                                    <b-link  class="date-main" href="#active"  @click="setActive(index)" :class="{ active : active_index == index }">
                                        <label>{{ index }} {{ current_month }} {{ current_year }}</label>
                                        <span class="percentage-bg" v-once v-bind:style="{ height: get_random_data() + 'px' }"></span>
                                    </b-link>
                                </slide>
                            </carousel>
                        </div>
                        <apexchart :height="350"  ref="chart" :options= areaDatetimeChart.options :series= areaDatetimeChart.series></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <send-money-modal></send-money-modal>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Earning = require('@/components/core/Earning.vue').default
const CardActions = require('@/components/core/CardActions.vue').default
const moment = require('moment')
const SendingMoneyModal = require('@/components/admin/dashboard/SendingMoneyModal.vue').default
const VueApexCharts = require('vue-apexcharts')
const { Carousel, Slide } = require('vue-carousel')
const { VMdDateRangePicker } = require("v-md-date-range-picker")
export default {
    name:'DashboardV1Component',
    components: {
        BreadCrumb,
        'earnings-component': Earning,
        'card-actions': CardActions,
        'send-money-modal':SendingMoneyModal,
        'apexchart':VueApexCharts,
        'v-md-date-range-picker':VMdDateRangePicker,
        Carousel,
        Slide
    },data (){
        return{
            isVisibleFilter: false,
            active_index:0,
            selection: 'weekly',
            selectedClass:'',
            itemIndex: '',
            options: [
                { value: 'approved', text: 'Approved' },
                { value: 'suspended', text: 'Suspended' },
                { value: 'pending', text: 'Pending' },
                { value: 'blocked', text: 'Blocked' },
            ],
            
            earnings:[
                {
                    title:'EARNINGS',
                    subTitle:'£22,500',
                    isSubtitleSmall: false,
                    class:'badge-success',
                    compare_text:'compared to last week',
                    img_class : 'flag-icon flag-icon-gb fi-xl64 fi-round',
                    circle_class:'',
                    circle_width: null,
                    button_text:'',
                },
                {
                    title:'LIMIT USAGE',
                    subTitle:'Daily limit: <b>7,000.0</b><br>Used: <b>1,5000.00</b>',
                    isSubtitleSmall: true,
                    class:'badge-danger',
                    compare_text:'Used',
                    img_class :'',
                    circle_class:'#ff0000',
                    circle_width: 25,
                    button_text:'Increase Daily Limit',

                },
                {
                    title:'PROFILE STATUS',
                    subTitle:'Account Type: <b>Silver</b>',
                    isSubtitleSmall: true,
                    class:'badge-warning',
                    compare_text:'Completed',
                    img_class :'',
                    circle_class:'#28a745',
                    circle_width: 75,
                    button_text:'Edit Profile',

                }
            ],

            items: [
                { images:{path: require(`@/assets/xs/avatar1.jpg`),flag_class:"flag-icon flag-icon-ca fi-md fi-round"},name:'Jacob',lname:'Smith',email:'jacob@gnail.com', status:{name:'Paid',class:'badge-success'},dropdawn:{selected:'approved',class:'btn-outline-success'}},
                { images:{path: require(`@/assets/xs/avatar2.jpg`),flag_class:"flag-icon flag-icon-us fi-md fi-round"},name:'Charlotte',lname:'Shams',email:'a.charlotte@gnail.com', status:{name:'Unpaid',class:'badge-danger'},dropdawn:{selected:'suspended',class:'btn-outline-warning'}},
                { images:{path: require(`@/assets/xs/avatar3.jpg`),flag_class:"flag-icon flag-icon-gb fi-md fi-round"},name:'Grayson',lname:'Camly',email:'grayson@yahoo.com', status:{name:'Pending',class:'badge-warning'},dropdawn:{selected:'blocked',class:'btn-outline-danger'}},
                { images:{path: require(`@/assets/xs/avatar4.jpg`),flag_class:"flag-icon flag-icon-au fi-md fi-round"},name:'Jacob',lname:'Hank',email:'jacob@gnail.com', status:{name:'Paid',class:'badge-success'},dropdawn:{selected:'approved',class:'btn-outline-success'}},
                { images:{path: require(`@/assets/xs/avatar5.jpg`),flag_class:"flag-icon flag-icon-gh fi-md fi-round"},name:'Amelia',lname:'Larry',email:'amelia@gnail.com',status:{name:'Pending',class:'badge-warning'},dropdawn:{selected:'pending',class:'btn-outline-info'}},
                { images:{path: require(`@/assets/xs/avatar6.jpg`),flag_class:"flag-icon flag-icon-ca fi-md fi-round"},name:'Michael',lname:'Otto',email:'michael@gmail.com',status:{name:'Unpaid',class:'badge-danger'},dropdawn:{selected:'pending',class:'btn-outline-info'}},
            ],
            
            fields: [
                { key: 'images', label: ''},
                { key: 'name', label: '',},
                { key: 'lname', label: '',},
                { key: 'email', label: ''},
                { key: 'status', label: ''},
                { key: 'dropdawn', label: ''},
            ],
            autoApplyDateRange: false,
            opens: 'left',
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
            areaDatetimeChart:{
                options :{
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false,
                        },
                    },
                    stroke: {
                        colors: ['transparent'],
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories:  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    },
                    colors: ['var(--primary-color-500)']
                },
                series: [{
                    data: [400, 430, 448, 470, 540, 580, 690]
                }]
            },

            receivers:[
                {
                    images: require(`@/assets/xs/avatar4.jpg`),
                    image_flag: "flag-icon flag-icon-ca fi-sm fi-round",
                    name:'Jacob Smith',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar1.jpg`),
                    image_flag: "flag-icon flag-icon-ca fi-sm fi-round GB",
                    name:'Charlotte Shams',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar4.jpg`),
                    image_flag: "flag-icon flag-icon-us fi-sm fi-round",
                    name:'Grayson Otto',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar1.jpg`),
                    image_flag: "flag-icon flag-icon-in fi-sm fi-round",
                    name:'Amelia hank',
                    icon:'fa fa-send-o'
                }
            ],
            senders:[
                {
                    images: require(`@/assets/xs/avatar4.jpg`),
                    image_flag: "flag-icon flag-icon-gb fi-sm fi-round",
                    name:'Jacob Smith',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar1.jpg`),
                    image_flag: "flag-icon flag-icon-ca fi-sm fi-round",
                    name:'Charlotte Shams',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar4.jpg`),
                    image_flag: "flag-icon flag-icon-au fi-sm fi-round",
                    name:'Grayson Otto',
                    icon:'fa fa-send-o'
                },
                {
                    images: require(`@/assets/xs/avatar1.jpg`),
                    image_flag: "flag-icon flag-icon-gb fi-sm fi-round",
                    name:'Amelia hank',
                    icon:'fa fa-send-o'
                }
            ],

            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Send",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Top-up",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Withdraw",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "More",
                    url: "javascript:void(0);"
                }
            ], 
            current_month:'',
            first_date:'',
            last_date: 0,
            current_year:'',
            random_number: 0,
        }
    },methods: {
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'approved'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'suspended'){
                        classVar = 'btn-outline-warning'
                    }else if(event.target.value == 'pending'){
                        classVar = 'btn-outline-info'
                    }else if(event.target.value == 'blocked'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        dropdawn_filter() {
           
        },

        xcategories(timeline) {
            this.selection = timeline
            switch (timeline) {
                case 'daily':
                    this.$refs.chart.updateOptions({
                        xaxis: {
                            categories:  ['01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00'
                            ,'11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00'
                            ,'23:00','24:00']
                        }
                    })
                    this.$refs.chart.updateSeries([{
                        data: [710, 295, 320, 254, 879, 210, 222, 569, 
                        789, 100, 98, 758, 320, 155, 462, 235, 541, 
                        657, 320, 120, 789, 256, 147, 963]
                    }])
                    break
                case 'monthly':
                    this.$refs.chart.updateOptions({
                        xaxis: {
                            categories:  ['1 Aug','2 Aug','3 Aug','4 Aug','5 Aug','6 Aug','7 Aug','8 Aug','9Aug','10 Aug','11 Aug','12 Aug','13 Aug','14 Aug',
                            '15 Aug','16 Aug','17 Aug','18 Aug','19 Aug','20 Aug','21 Aug','22 Aug',
                            '23 Aug','24 Aug','25Aug','26 Aug','27 Aug','28 Aug','29 Aug','30 Aug','31 Aug']
                        }
                    })
                    this.$refs.chart.updateSeries([{
                        data: [400, 430, 448, 470, 540, 580, 690, 710, 295, 320, 254, 879, 210, 222, 569, 789, 100, 98, 758, 320, 155, 462, 235, 541, 657, 320, 120, 789, 256, 147, 963]
                    }])
                    break
                case 'yearly':
                    this.$refs.chart.updateOptions({
                        xaxis: {
                            categories:  ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec']
                        }
                    })
                    this.$refs.chart.updateSeries([{
                        data: [400, 430, 448, 470, 540, 580, 690, 1150, 1240, 1350, 1450, 1560]
                    }])
                    break
                case 'weekly':
                    this.$refs.chart.updateOptions({
                        
                    })
                    this.$refs.chart.updateSeries([{
                        data: [400, 430, 448, 470, 540, 580, 690]
                    }])
                    break
                default:
            }
        },

        setActive:function(el){
            this.active_index = el;
            return;
        },

        get_random_data:function(){
            return  Math.random() * 50;
        },
    },created: function (){
        const dt = new Date();
        const locale = navigator.languages != undefined ? navigator.languages[0] : navigator.language;
        this.current_month = dt.toLocaleDateString(locale, {month: 'long'});
        this.last_date = parseInt(moment().endOf('month').format("DD"))
        this.first_date = moment().format("01");
        this.current_year = moment().endOf('month').format("YYYY")
    }
}
</script>
<style>
.hidden_header {
  display: none;
}
.VueCarousel {
  width: 100%;
}

</style>