<template>
    <div class="landing-page"> 
        <AuthHeaderV2Component/>
            <slot></slot>
        <AuthFooterV2Component v-if="['home','term_condition','overview'].includes($route.name)"/>
    </div>
</template>
<script>
import AuthHeaderV2Component from '@/components/admin/layouts/auth_header_v2/AuthHeaderV2Component.vue'
import AuthFooterV2Component from '@/components/admin/layouts/auth_footer/AuthFooterV2Component.vue'
export default {
    name:'AuthV2Layout',
    components: {
        AuthHeaderV2Component,
        AuthFooterV2Component
    }
}
</script>
<style scoped>
    @import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap';
</style>