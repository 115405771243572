<template>
    <div class="card card-top-line">
        <money-header-widgets
            :img = "headerDetais.image"
            :title = "headerDetais.title"
            :subTitle = "headerDetais.subTitle"
            :isActions = true
        ></money-header-widgets>
        <div class="body">
            <ul class="list-unstyled mb-0 send-money-card">
                <li>
                    <div class="phone-with-country d-flex justify-content-between align-items-center">
                        <div class="select-country">
                            <div class="form-group form-select single-multiselect mb-0">
                                <span class="float-label">
                                    <multiselect
                                        placeholder=""
                                        v-model="moneyDetails.currencyFrom"   
                                        :show-labels="false" 
                                        :options="currency_options"
                                        :searchable="false"
                                        label="name" 
                                        track-by="name"
                                        :custom-label="currencyFlag"
                                        @select="fromCurrencySelected">
                                        <template slot="singleLabel" slot-scope="props">
                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                            <span class="option-title">
                                                <span>{{ props.option.name }}</span>
                                            </span>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="option-title">
                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                <span>{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                </span>
                            </div>
                        </div>
                        <div class="add-number">
                            <div class="form-group mb-0">
                                <span class="float-label">
                                    <input type="text" v-model="moneyDetails.amountFrom" class="form-control" id="amountFrom" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)" @input="setcurrencyOnTo()">
                                    <div class="line"></div>
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="text-center exchange-icon">
                    <a href="javascript:void(0);"><i class="fa fa-exchange"></i></a>
                </li>
                <li>
                    <div class="phone-with-country d-flex justify-content-between align-items-center">
                        <div class="select-country">
                            <div class="form-group mb-0 form-select single-multiselect">
                                <span class="float-label">
                                    <multiselect
                                        placeholder=""
                                        v-model="moneyDetails.currencyTo"   
                                        :show-labels="false" 
                                        :options="currency_options"
                                        :searchable="false"
                                        label="name" 
                                        track-by="name"
                                        :custom-label="currencyFlag"
                                        @select="toCurrencySelected">
                                        <template slot="singleLabel" slot-scope="props">
                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                            <span class="option-title">
                                                <span>{{ props.option.name }}</span>
                                            </span>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="option-title">
                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                <span>{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                </span>
                            </div>
                        </div>
                        <div class="add-number">
                            <div class="form-group mb-0">
                                <span class="float-label">
                                    <input type="text" v-model="moneyDetails.amountTo" class="form-control" id="amountTo" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)"  @input="setcurrencyOnFrom()">
                                    <div class="line"></div>
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="action-btn m-t-12">
                <b-button type="submit" variant="danger" size="lg">Send Money</b-button>
            </div>
        </div>
    </div>
</template>
<script>
const Multiselect = require('vue-multiselect').default
const MoneyTransferHeader = require('@/components/admin/dashboard/dashboard_v2_widgets/MoneyTransferHeader').default
export default {
    name:"SendMoney",
    components: {
        Multiselect,
        'money-header-widgets':MoneyTransferHeader
    },data(){
        return{
            headerDetais:{
                image: require(`@/assets/img/icon-send-money-now.svg`),
                title:'Send Money Now',
                subTitle:'It’s simple, fast and secure Your Friends and Family now only a few ckicks away'
            },
            moneyDetails:{
                amountFrom:'',
                currencyFrom:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amountTo:'',
                currencyTo:{ name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
            },
            currency_options:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],

            currencyRate:{
                GBP:{
                    GHS:8.39,
                    USD:1.36,
                    RUB:101.46,
                    GBP:0.00
                },
                GHS:{
                    GBP:0.12,
                    USD:0.16,
                    RUB:12.10,
                    GHS:0.00
                },
                USD:{
                    GHS:6.16,
                    GBP:0.73,
                    RUB:74.46,
                    USD:0.00
                },
                RUB:{
                    GHS:0.083,
                    GBP:0.0098,
                    USD:0.013,
                    RUB:0.00
                }
            }
        }
    },methods:{
        sendMoneySubmit() {
            console.log("submit");
        },

        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },

        handleInput (e) {
            let stringValue = e.target.value.toString()
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,2})?$/
            if(!stringValue.match(regex) && this.moneyDetails.amountFrom !== '' && this.moneyDetails.amountTo !== '') {
                this.moneyDetails.amountFrom = null
                this.moneyDetails.amountTo = null
            }
            
        },

        checkDecimals (e) {
            let stringValue = e.target.value.toString();
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,1})?$/
            if (!stringValue.match(regex)
                && e.which != 8   // backspace
                && e.which != 46  // delete
                && (e.which < 48 || e.which > 57)
                && (e.which < 96 || e.which > 105)){
                    e.preventDefault();
                    return false;
                }
        },

        fromCurrencySelected(currency){
            this.moneyDetails.currencyFrom = currency
           
            if(this.moneyDetails.amountTo){
               this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[this.moneyDetails.currencyTo.name][this.moneyDetails.currencyFrom.name]).toFixed(2);            
            }

            if(currency.name ==  this.moneyDetails.currencyTo.name){
                this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo).toFixed(2);
            }
        },

        toCurrencySelected(currency){
            this.moneyDetails.currencyTo = currency
            
            if(this.moneyDetails.amountFrom){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[this.moneyDetails.currencyFrom.name][this.moneyDetails.currencyTo.name]).toFixed(2);          
            }
            if(currency.name ==  this.moneyDetails.currencyFrom.name){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom).toFixed(2);
            }
        },

        setcurrencyOnTo(){
            var from = this.moneyDetails.currencyFrom.name;
            var to = this.moneyDetails.currencyTo.name;
            this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[from][to]).toFixed(2);
        },

        setcurrencyOnFrom(){
            var from = this.moneyDetails.currencyFrom.name;
            var to = this.moneyDetails.currencyTo.name;
            this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[to][from]).toFixed(2);
        }
    }
}
</script>