import { render, staticRenderFns } from "./AuthFooterComponent.vue?vue&type=template&id=13fda9a1&"
import script from "./AuthFooterComponent.vue?vue&type=script&lang=js&"
export * from "./AuthFooterComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports