<template>
    <chart :options="baroptions" class="teambar" autoresize></chart>
</template>
<script>
import "@/plugins/echarts"
export default {
    name:'MiniBarChart',
    props:{
        baroptions:Object
    }
}
</script>
