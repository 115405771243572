<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-8 col-md-10 col-sm-12">
                <div class="card card-top-line">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="header text-center">
                                <img src="@/assets/event-main.svg" class="mw-100" alt="">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="body">
                                <ul class="list-unstyled mb-5 text-center">
                                    <li><h4>Lets's get ready to start your Event!</h4></li>
                                    <li>
                                        <p>We want to create the best onboarding for you - please fill out the information below. 
                                            <strong>Your answers will be locked for this event and can't be changed later.</strong>
                                        </p>
                                    </li>
                                </ul>
                                <ul class="list-unstyled mb-0">
                                    <ValidationObserver v-slot="{ passes }">
                                        <form @submit.prevent="passes(eventSubmit)" id="basic-form">
                                            <li>
                                                <label class="mb-1">Who are you raising money for ?</label>
                                                <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Please choose the type of account that will be receiving your funds."></i>
                                                <div class="d-flex justify-content-between align-items-stretch flex-column flex-md-row mb-4 mt-1 who-list">
                                                    <div class="align-items-center border d-flex flex-column justify-content-center flex-grow-1 p-4 who-box" @click="moneyActive('individual')" :class="{'active': activeMoney == 'individual'}">
                                                        <div class="btn btn-primary btn-lg mb-1">
                                                            <div class="svg-box wh-30 ml-auto mr-auto">
                                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                                                    <path class="st0" d="M50,2.5c26.2,0,47.5,21.3,47.5,47.5S76.2,97.5,50,97.5S2.5,76.2,2.5,50c0-12.6,5-24.7,13.9-33.6
                                                                        S37.4,2.5,50,2.5z M40.6,57.6c0.2-1.7-4.8-8.1-5.7-11.2c-2-3.1-2.7-8.1-0.5-11.4c0.9-1.3,0.5-2.4,0.5-4.3c0-18,31.5-18,31.5,0
                                                                        c0,2.3-0.5,2.8,0.7,4.6c2.1,3,1,8.3-0.8,11.1c-1.1,3.2-6.3,9.4-6,11.2C60.6,66.9,40.5,66.6,40.6,57.6L40.6,57.6L40.6,57.6z
                                                                        M16.6,73.2c2.5-2.8,7-2.9,12.5-5.7c2.1-1,4.1-2.2,6-3.5c6.4,7.8,11.6,16.4,9.9,26.3c1.7,0.2,3.3,0.3,5,0.3c0.4,0,0.8,0,1.3,0
                                                                        C50.9,78.9,57.7,70.3,65,63.1c3.7,2.5,7.7,4.5,12,5.9c3.6,1.2,5.7,2,7,3.3C96.3,53.6,91.1,28.4,72.4,16s-44-7.1-56.3,11.6
                                                                        C7,41.5,7.2,59.5,16.6,73.2L16.6,73.2z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <h6 class="mb-0 mt-3">Individual</h6>
                                                    </div>
                                                    <div class="align-items-center border d-flex flex-column justify-content-center flex-grow-1 p-4 who-box mx-md-3 my-3 my-md-0" @click="moneyActive('business')" :class="{'active': activeMoney == 'business'}">
                                                        <div class="btn btn-primary btn-lg mb-1">
                                                            <div class="svg-box wh-30 ml-auto mr-auto">
                                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                                                    <path id="XMLID_13_" class="st0" d="M2.5,48h19.2v34.2H2.5V48z M72.9,29.7c5.3,0,9.6,4.3,9.6,9.6s-4.3,9.6-9.6,9.6
                                                                        c-5.3,0-9.6-4.3-9.6-9.6l0,0C63.3,34,67.6,29.7,72.9,29.7z M72.9,24.7c8.1,0,14.6,6.5,14.6,14.6S81,53.9,72.9,53.9
                                                                        s-14.6-6.5-14.6-14.6c0-3.9,1.5-7.6,4.3-10.3C65.3,26.3,69.1,24.7,72.9,24.7L72.9,24.7z M82.3,30c-5.2-5.1-13.5-5.1-18.7,0
                                                                        s-5.1,13.5,0,18.7s13.5,5.1,18.7,0c2.5-2.5,3.9-5.8,3.9-9.3C86.1,35.8,84.7,32.5,82.3,30z M72.3,44.4c-0.3,0-0.6-0.1-0.8-0.1
                                                                        C71,44.2,70.4,44,70,43.8c-0.5-0.2-0.9-0.5-1.2-0.8l1.2-2.3c0.3,0.3,0.6,0.5,1,0.7s0.8,0.4,1.3,0.5c0.4,0.1,0.8,0.2,1.1,0.2
                                                                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.2,0.3-0.4c0-0.1-0.1-0.3-0.2-0.3c-0.2-0.1-0.5-0.2-0.7-0.3l-1.7-0.5c-0.5-0.1-0.9-0.3-1.3-0.5
                                                                        s-0.7-0.5-1-0.9s-0.4-0.9-0.4-1.4c0-0.6,0.2-1.3,0.5-1.8s0.8-0.9,1.4-1.2c0.4-0.2,0.9-0.3,1.3-0.4V33H74v1.3c0.5,0,0.9,0.2,1.4,0.3
                                                                        c0.6,0.2,1.2,0.6,1.7,1.1l-1.1,2c-0.4-0.3-0.9-0.6-1.3-0.8c-0.5-0.2-1.1-0.3-1.6-0.3c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.3,0.1-0.4,0.2
                                                                        c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.2,0.3c0.3,0.1,0.7,0.3,1,0.3l1.2,0.3c0.7,0.2,1.4,0.5,2,1c0.5,0.5,0.7,1.2,0.7,1.8
                                                                        c0,0.7-0.1,1.4-0.5,2c-0.3,0.5-0.8,0.9-1.4,1.2c-0.3,0.1-0.7,0.2-1,0.3v1.3h-1.8L72.3,44.4L72.3,44.4z M25.6,79.3V50.8h12.8
                                                                        c5.4,1,10.9,3.9,16.3,7.4h10c4.5,0.3,6.9,4.8,2.5,7.9c-3.5,2.6-8.1,2.4-12.8,2c-3.3-0.2-3.4,4.2,0,4.2c1.2,0.1,2.5-0.2,3.6-0.2
                                                                        c5.9,0,10.8-1.1,13.7-5.8l1.5-3.5L88,55.4c7.4-2.4,12.7,5.3,7.2,10.7c-10.3,7.6-21.4,14.1-33,19.4c-8.2,5-16.4,4.8-24.6,0L25.6,79.3
                                                                        z M45.7,15c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S41.3,15,45.7,15L45.7,15z M45.7,10.8c6.7,0,12.2,5.5,12.2,12.2
                                                                        s-5.5,12.2-12.2,12.2S33.5,29.7,33.5,23c0-3.2,1.3-6.3,3.6-8.6C39.3,12.1,42.4,10.8,45.7,10.8z M53.3,15.4c-4.2-4.2-11-4.2-15.2,0
                                                                        s-4.2,11,0,15.2s11,4.2,15.2,0c2-2,3.1-4.8,3.1-7.6C56.5,20.1,55.3,17.4,53.3,15.4z M45.1,27.2c-0.2,0-0.5-0.1-0.7-0.1
                                                                        c-0.4-0.1-0.9-0.2-1.3-0.4c-0.4-0.2-0.7-0.4-1-0.7l1-1.9c0.2,0.2,0.5,0.4,0.8,0.6s0.7,0.3,1.1,0.4c0.3,0.1,0.6,0.1,0.9,0.1
                                                                        c0.2,0,0.5,0,0.7-0.1c0.1,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.4-0.2-0.6-0.2l-1.4-0.4c-0.4-0.1-0.7-0.2-1.1-0.4
                                                                        c-0.3-0.2-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.7-0.8,1.2-1c0.3-0.2,0.7-0.3,1.1-0.3v-1.1h1.4v1.1
                                                                        c0.4,0,0.8,0.1,1.1,0.3c0.5,0.2,1,0.5,1.5,0.9l-1,1.7c-0.3-0.3-0.7-0.5-1.1-0.7c-0.4-0.2-0.9-0.3-1.3-0.3c-0.2,0-0.4,0-0.6,0.1
                                                                        c-0.1,0-0.2,0.1-0.3,0.2s-0.1,0.1-0.1,0.2s0.1,0.2,0.2,0.3c0.3,0.1,0.6,0.2,0.9,0.3l1,0.3c0.6,0.1,1.2,0.4,1.7,0.8
                                                                        c0.4,0.4,0.6,1,0.6,1.5c0,0.6-0.1,1.2-0.4,1.7c-0.3,0.4-0.7,0.8-1.2,1c-0.3,0.1-0.6,0.2-0.9,0.2v1.1h-1.5
                                                                        C45.1,28.3,45.1,27.2,45.1,27.2z"/>
                                                                    <text id="XMLID_1_" transform="matrix(1 0 0 1 68.7213 43.6537)" class="st1 st2 st3">$</text>
                                                                    <text id="XMLID_3_" transform="matrix(1 0 0 1 41.3043 27.4034)" class="st1 st2 st3">$</text>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <h6 class="mb-0 mt-3">Business</h6>
                                                    </div>
                                                    <div class="align-items-center border d-flex flex-column justify-content-center flex-grow-1 p-4 who-box" @click="moneyActive('profit')" :class="{'active': activeMoney == 'profit'}">
                                                        <div class="btn btn-primary btn-lg mb-1">
                                                            <div class="svg-box wh-30 ml-auto mr-auto">
                                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                                                    <g>
                                                                        <path d="M2.5,17.7c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7v60.4h81c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7H2.5L2.5,17.7
                                                                            L2.5,17.7z"/>
                                                                        <path d="M78.2,34.8h9.7c0.6,0,1,0.5,1,1v38.1c0,0.6-0.5,1-1,1h-9.7c-0.6,0-1-0.5-1-1v-38C77.1,35.3,77.6,34.8,78.2,34.8z"/>
                                                                        <path d="M59,34.8h9.7c0.6,0,1,0.7,1,1.5v37.1c0,0.8-0.5,1.5-1,1.5H59c-0.6,0-1-0.7-1-1.5V36.3C58,35.6,58.5,34.8,59,34.8z"/>
                                                                        <path d="M39.9,34.8h9.7c0.6,0,1,0.5,1,1l0,0v38.1c0,0.6-0.5,1-1,1h-9.7c-0.6,0-1-0.5-1-1v-38C38.9,35.3,39.4,34.8,39.9,34.8z"/>
                                                                        <path d="M20.8,34.9h9.7c0.6,0,1,0.7,1,1.7l0,0v36.8c0,0.9-0.5,1.6-1,1.7h-9.7c-0.6,0-1-0.7-1-1.7V36.7
                                                                            C19.8,35.7,20.3,34.9,20.8,34.9L20.8,34.9z"/>
                                                                        <polygon points="22.6,24.2 63,24.2 63,29.8 84.9,21.2 63,12.4 63,18.1 22.6,18.1 	"/>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <h6 class="mb-0 mt-3">Not for Profit</h6>
                                                        <h6 class="mb-0 mt-0">Organisation</h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="head">
                                                    <label>Where are you located? <span class="text-danger">*</span></label>
                                                    <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="If you are raising funds as an individual, what is your country of legal residence? If you are raising funds for a business, where is the business headquartered? Learn more about country limitation."></i>
                                                </div>
                                                <ValidationProvider name="residence_country" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors.length && !country.residenceCountrys,'success':country.residenceCountrys}">
                                                            <span class="float-label">
                                                            <label for="Country">Select a residence country</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="country.residenceCountrys" 
                                                                    :show-labels="false" 
                                                                    :options="residence_countrys" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && !country.residenceCountrys"></i>
                                                                <i class="fa fa-check" v-if="country.residenceCountrys"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="head">
                                                    <label>Where is your bank? <span class="text-danger">*</span></label>
                                                    <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Your bank account location determines the currency in which you can raise funds."></i>
                                                </div>
                                                <ValidationProvider name="bank_country" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors.length && !country.bankCountry,'success':country.bankCountry}">
                                                            <span class="float-label">
                                                            <label for="Country">Select a bank country</label>
                                                                <multiselect
                                                                    :disabled="!country.residenceCountrys ? true : false"
                                                                    placeholder=""
                                                                    v-model="country.bankCountry" 
                                                                    :show-labels="false" 
                                                                    :options="bank_countrys" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && !country.bankCountry"></i>
                                                                <i class="fa fa-check" v-if="country.bankCountry"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li class="mt-4 mb-4">
                                                <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                                                    <router-link to="/event/list" class="btn btn-secondary btn-lg btn-mw">BACK</router-link>
                                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Start My Event</b-button>
                                                </div>
                                            </li>
                                            <li class="text-center">
                                                <label class="mb-1">What countries are supported by Angel Communities?</label>
                                                <p>You can start a campaining in countries supported by our payment processor.Learn more about potential options if your country is not supported at this time.</p>
                                            </li>
                                        </form>
                                    </ValidationObserver>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
import "@/plugins/vee-validate";

export default {
    name:'EventDashboardComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },data(){
        return{
            activeMoney: 'individual',
            country: {
                residenceCountrys: null,
                bankCountry: null,
            },

            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            bank_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],
        }
    },methods: {
        eventSubmit() {
           this.$router.push({ path: '/event/event-details'})
        },

        moneyActive(currentMoney){
            this.activeMoney = currentMoney;
        }
    }
}
</script>