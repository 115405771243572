<template>
    <b-modal id="add_credit_modal" title="Add Credit" hide-footer no-stacking no-close-on-backdrop>
        <div class="body">
            <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true">
                <b-tab class="transfer-tab" title="Mobile Money" active>
                    <mobile-money></mobile-money>
                </b-tab>
                <b-tab title="Pay by Card">
                    <pay-by-card></pay-by-card>
                </b-tab>
                <b-tab title="Pay by Bank">
                    <pay-by-bank></pay-by-bank>
                </b-tab>
            </b-tabs>
        </div>
    </b-modal>
</template>
<script>
export default {
    name:'AddCreditComponent',
    components:{
        'mobile-money': () => import('@/components/admin/sms/payment-by-options/MobileMoney.vue'),
        'pay-by-card': () => import('@/components/admin/sms/payment-by-options/PaybyCard.vue'),
        'pay-by-bank': () => import('@/components/admin/sms/payment-by-options/PaybyBank.vue')
    },data(){
        return{
            
        }
    },methods:{

    }
}
</script>