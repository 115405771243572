<template>
   <div class="card">
        <div class="header">
            <h2>Popular Posts</h2>                        
        </div>
        <div class="body widget popular-post">
            <div class="row">
                <div class="col-lg-12">
                    <div class="single_post">
                        <p class="m-b-0">Apple Introduces Search Ads Basic</p>
                        <span>jun 8, 2018</span>
                        <div class="img-post">
                            <img src="./../../assets/blog/blog-page-2.jpg" alt="Awesome Image">                                        
                        </div>                                            
                    </div>
                </div>
            </div>
        </div>
        <div class="body widget popular-post">
            <div class="row">
                <div class="col-lg-12">
                    <div class="single_post">
                        <p class="m-b-0">new rules, more cars, more races</p>
                        <span>jun 22, 2018</span>
                        <div class="img-post">
                            <img src="./../../assets/blog/blog-page-3.jpg" alt="Awesome Image">                                        
                        </div>                                            
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'PopularPosts'
}
</script>
