<template>
    <div class="right-content mx-auto my-auto w-50 otp-card">
        <div class="header pb-3 text-center">
            <p class="lead px-3">Ring Ring...</p>
        </div>
        <div class="body">
            <p class="text-center mb-3">Enter the verification code we just sent to your email <strong>{{contactDetail.userEmail}}</strong></p>
            <!-- <p class="text-center mb-3"><a class="mobile-number" href="#">Change your Mobile Number</a></p> -->
            <div class="otp-form">
                <div id="otp" class="d-flex justify-content-center">
                    <div class="form-group">
                        <v-otp-input
                            ref="otpInput"
                            input-classes="otp-input form-control m-2"
                            separator=" "
                            :num-inputs="5"
                            :should-auto-focus="true"
                            :is-input-num="false"
                            @on-complete="handleOnComplete"
                        />
                    </div>
                </div>
            </div>
            <div class="otp-timer text-center" id="otp_timer">
                <span v-if="showResendOtp">
                    <p>Code hasn't arrived? 
                        <a class="text-danger" href="javascript:void(0)" @click="resendOtp()">Resend OTP</a>
                    </p>
                    <!-- <p class="mt-3 mb-0"> 
                        You may be experiencing mobile network issues, check network settings on your phone
                    </p> -->
                </span>
                <p>
                    <span class="text-danger">{{ wrongOtpMsg }}</span>
                </p>
                <p class="mt-3 mb-0" v-if="!showResendOtp"> 
                    You can request a new code if it does not arrive in: 
                    <label class="timer-text text-danger" id="timer_countdown">02:00</label>
                </p>
                <!-- <a href="javascript:void(0);" @click="resendOtp()">Resend OTP</a>
                <p>
                    <span class="text-danger">{{ wrongOtpMsg }}</span>
                </p>
                <p class="mt-3 mb-0"> 
                    You can request a new code if it does not arrive in: 
                    <label class="timer-text text-danger" id="timer_countdown"></label>
                </p> -->
            </div>
        </div>
    </div>           
</template>
<script>
const OtpInput = require("@bachdgvn/vue-otp-input").default
import { mapState } from 'vuex'
export default {
    name:'StandappOtpComponent',
    components:{
        'v-otp-input':OtpInput
    },data(){
        return{
            clearTimer:0,
            wrongOtpMsg:'',
            showResendOtp:false
        }
    },methods: {
        redirectLoginPage: function(value) {
            let activateData = {
                email: this.contactDetail.userEmail, 
                activationcode: value //OTP,
            }
            // console.log(activateData);
            this.$http.put(this.$baseurl+'business/register/activate', activateData)
            .then((res) => {
                //Perform Success Action
                // OTP = '';
                if(res.data.code == '0002-002') {
                    this.$swal('Success!', 'Registration complete. Please login now to set-up your profile.', 'success', 'OK');
                    this.$router.push({ path: '/standapp-login' })
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        //timer counter
        countDownTimer() {
            var duration = 2 * 60
            var timer = duration, minutes, seconds;
            var that = this;
            this.clearTimer = setInterval(function () {
                if(timer > 0) {
                    if(document.getElementById("timer_countdown")){
                        minutes = parseInt((timer / 60)%60, 10)
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;
                        document.getElementById("timer_countdown").innerHTML = minutes + ":" + seconds;
                    }
                    --timer;
                    // if(timer <= 0){
                    //     that.showResendOtp = true;
                    // } else {
                    //     that.showResendOtp = false;
                    // }
                } else {
                    document.getElementById("timer_countdown").innerHTML = "00:00";
                    clearInterval(this.clearTimer);
                    this.clearTimer = 0;
                    if(timer <= 0){
                        that.showResendOtp = true;
                    } else {
                        that.showResendOtp = false;
                    }
                }
            }, 1000);
        },
        
        //reset timer 
        resendOtp(){
            // this.countDown = 120
            let resendOtpData = {
                email: this.contactDetail.userEmail, 
            }
           this.$http.post(this.$baseurl+'business/register/resend', resendOtpData)
            .then((res) => {
                //Perform Success Action
                this.$swal('Success!', res.data.message, 'success', 'OK');
                clearInterval(this.clearTimer);
                return this.countDownTimer();
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },

        handleOnComplete(value) {
            // console.log('OTP completed: ', value);
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.redirectLoginPage(value);
            }
        }
    },created() {
        //OTP Timer
        clearInterval(this.clearTimer)
        this.countDownTimer()
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }, computed: mapState({
        contactDetail: state => state.contactDetail.contactDetail
    })
}
</script>
