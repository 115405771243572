<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <campaign-timeline
                            :fieldstep="1"
                        ></campaign-timeline>
                    </div>
                </div>
            </div>
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your campaign objectives and entice people to learn more. This basic information will represent your campaign on your campaign page, on your campaign card, and in searches.</p>
                            </li>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(basic2DataSubmit)" id="campaign-form" ref="form">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">
                                                    <label>Location <span class="text-danger">*</span></label>
                                                    <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Choose the location where you are running the campaign. This location will be visible on your campaign page for your audience to see."></i>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="campaignCountry" rules="required" v-slot="{ errors}">
                                                    <div class="form-group form-select single-multiselect country-dropdown" :class="{'errors': errors.length && !campaign.campaign_country,'success':campaign.campaign_country}">
                                                        <span class="float-label">
                                                            <label for="functions">Select Country</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="campaign.campaign_country"  
                                                                :show-labels="false" 
                                                                :options="campaignCountry_options" 
                                                                :searchable="false"
                                                                label="name" 
                                                                track-by="name" >
                                                                <template slot="option" slot-scope="props">
                                                                    <div class="option-title">
                                                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                                                        <span>{{ props.option.name }}</span>
                                                                    </div>
                                                                </template>
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && !campaign.campaign_country"></i>
                                                            <i class="fa fa-check" v-if="campaign.campaign_country"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="campaign_city" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !campaign.campaign_city,'success':campaign.campaign_city}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="campaign.campaign_city" class="form-control" id="campaign_city" placeholder="Campaign City">
                                                            <label for="campaign_city" class="control-label">Campaign City</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !campaign.campaign_city"></i>
                                                            <i class="fa fa-check" v-if="campaign.campaign_city"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="head">
                                            <label>Category <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="To help backers find your campaign, select a category that best represents your project."></i>
                                        </div>
                                        <ValidationProvider name="campaignCategory" rules="required" v-slot="{ errors}">
                                            <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !campaign.campaign_category,'success':campaign.campaign_category}">
                                                <span class="float-label">
                                                    <label for="functions">Select Category</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="campaign.campaign_category"  
                                                        :show-labels="false" 
                                                        :options="campaignCategory_options" 
                                                        :searchable="false">
                                                    </multiselect> 
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.campaign_category"></i>
                                                    <i class="fa fa-check" v-if="campaign.campaign_category"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="head">
                                            <label>Other Category <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Is there a category you did not see that would better describe your project?"></i>
                                        </div>
                                        <ValidationProvider name="category_other" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !campaign.category_other,'success':campaign.category_other}">
                                                <span class="float-label">
                                                    <input type="text" v-model="campaign.category_other" class="form-control" id="category_other" placeholder="Campaign Duration">
                                                    <label for="category_other" class="control-label">Campaign Duration</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !campaign.category_other"></i>
                                                    <i class="fa fa-check" v-if="campaign.category_other"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <label class="mb-1">Are you producing a hardware product? <span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <label class="fancy-radio">
                                                <input type="radio" name="hardware_yes" value="hardware_yes" v-model="campaign.isHardware">
                                                <span><i></i>Yes</span>
                                            </label>
                                            <label class="fancy-radio">
                                                <input type="radio" name="hardware_no" value="hardware_no" v-model="campaign.isHardware">
                                                <span><i></i>No</span>
                                            </label>
                                            <p id="error-radio"></p>
                                        </div>
                                    </li>
                                    <div class="d-flex flex-column flex-md-row justify-content-md-end justify-content-center">
                                        <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const CampaignTimeline = require('@/components/core/CampaignTimeline').default
import "@/plugins/vee-validate";
export default {
    name:'AddCampaignForm2',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'campaign-timeline':CampaignTimeline
    },data(){
        return{
            campaign:{
                campaign_country: null,
                campaign_city:'',
                campaign_category:null,
                category_other:'',
                isHardware:'hardware_yes',
            },

            campaignCountry_options: [
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Ghana', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'France', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            campaignCategory_options: [
                'Audio',
                'Camera Gear',
                'Education',
                'Productivity',
                'Transportation',
                'Other Innovative Products'
            ],
        }
    },methods: {
        //submit form data
        basic2DataSubmit(){
            this.$router.push({ path: '/crowdfunding/campaign-basic3' })
        },
    }
}
</script>
