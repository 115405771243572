<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix row-deck nd-card">
            <!-- Send Money Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <send-money-widgets></send-money-widgets>
            </div>

            <!-- Manage Beneficiaries Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <manage-beneficiary-widgets></manage-beneficiary-widgets>
            </div>

            <!-- My Profile Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <my-profile-widgets></my-profile-widgets>
            </div>

            <!-- Payangel Rewards Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <payangel-rewards-widgets></payangel-rewards-widgets>
            </div>

            <!-- My Transfers Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <my-transfers-widgets></my-transfers-widgets>
            </div>

            <!-- Remitcare Card -->
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
               <remit-care-widgets></remit-care-widgets>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const SendMoney = require('@/components/admin/dashboard/dashboard_v2_widgets/SendMoney').default
const ManageBeneficiary = require('@/components/admin/dashboard/dashboard_v2_widgets/ManageBeneficiary').default
const MyProfile = require('@/components/admin/dashboard/dashboard_v2_widgets/profile/MyProfile').default
const PayangelRewards = require('@/components/admin/dashboard/dashboard_v2_widgets/PayangelRewards').default
const MyTransfers = require('@/components/admin/dashboard/dashboard_v2_widgets/MyTransfers').default
const Remitcare = require('@/components/admin/dashboard/dashboard_v2_widgets/Remitcare').default

export default {
    name:'DashboardV2Component',
    components: {
        BreadCrumb,
        'send-money-widgets':SendMoney,
        'manage-beneficiary-widgets':ManageBeneficiary,
        'my-profile-widgets':MyProfile,
        'payangel-rewards-widgets':PayangelRewards,
        'my-transfers-widgets':MyTransfers,
        'remit-care-widgets':Remitcare
    }
}
</script>