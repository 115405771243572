<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :displaybeneficiaries="true"></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
           <div class="col-lg-12 col-md-12">
               <div class="card card-top-line no-shadow">
                   <div class="body payment-main d-flex justify-content-between">
                       <div class="left-payment border rounded w-50 mr-2">
                           <ul class="list-unstyled mb-0 text-center">
                                <li class="p-3"><img src="@/assets/transfer_money.svg" class="mw-100" alt=""></li>
                                <li><h4 class="my-3">Welcome to PayAngel Payment</h4></li>
                           </ul>
                       </div>
                       <div class="right-payment w-50 ml-2">
                           <ul class="list-unstyled mb-0 text-center d-flex flex-column justify-content-between h-100">
                               <li class="border rounded">
                                   <router-link class="btn-pay-or-transfer p-3" to="/pay-transfer/account-details">
                                        <div class="btn btn-primary btn-lg mb-1">
                                            <div class="svg-box wh-30 ml-auto mr-auto font-0">
                                                <i class="fa fa-paper-plane"></i>
                                            </div>
                                        </div>
                                        <h6 class="mb-0 text-dark">Single Payment or Transfer</h6>
                                    </router-link>
                               </li>
                               <li class="border rounded">
                                    <div class="btn-pay-or-transfer p-3">
                                        <div class="btn btn-primary btn-lg mb-1">
                                            <div class="svg-box wh-30 ml-auto mr-auto font-0">
                                                <svg class="payment svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                                    <g>
                                                        <path d="M11.5,51c0-1.5,1.2-2.7,2.7-2.7h64.1L61.2,31.2c-1.1-1.1-1.1-2.8,0-3.8c1.1-1.1,2.8-1.1,3.8,0l21.7,21.7
                                                            c1.1,1.1,1.1,2.8,0,3.8c0,0,0,0,0,0L65,74.7c-1.1,1.1-2.8,1.1-3.8,0s-1.1-2.8,0-3.8l17.1-17.1H14.2C12.7,53.7,11.5,52.5,11.5,51z"
                                                            />
                                                        <path d="M8,50c0,23.2,18.8,42,42,42s42-18.8,42-42S73.2,8,50,8S8,26.8,8,50z M98,50c0,26.5-21.5,48-48,48S2,76.5,2,50S23.5,2,50,2
                                                            S98,23.5,98,50z M24.7,26c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h22.7l-7.7,7.7c-0.7,0.7-0.7,1.8,0,2.5c0.7,0.7,1.8,0.7,2.5,0
                                                            L53,29.1c0.7-0.7,0.7-1.8,0-2.5c0,0,0,0,0,0L42.3,15.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l7.7,7.7H24.7z M24.7,70
                                                            c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h22.7l-7.7,7.7c-0.7,0.7-0.7,1.8,0,2.5c0.7,0.7,1.8,0.7,2.5,0L53,73.1
                                                            c0.7-0.7,0.7-1.8,0-2.5c0,0,0,0,0,0L42.3,59.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l7.7,7.7H24.7z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <h6 class="mb-0 text-dark">Multi Payments or Transfers</h6>
                                    </div>
                               </li>
                               <li class="border rounded">
                                   <div class="btn-pay-or-transfer p-3">
                                        <div class="btn btn-primary btn-lg mb-1">
                                            <div class="svg-box wh-30 ml-auto mr-auto font-0">
                                                <svg class="payment svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                                    <g>
                                                        <path d="M20.9,20.1h19.5V14h-7.8c0,0,27.5-14.1,49.6,16l5.6-3.5c0,0-21-35.3-60.2-17.7V1h-6.8V20.1z"/>
                                                        <path d="M34.5,89.3l-9.9-16.8l-5.2,3.1l4,6.7c0,0-26.1-16.5-11.4-50.8L6,28.4c0,0-19.7,36,15.4,60.8l-6.8,4l3.4,5.8L34.5,89.3z"/>
                                                        <path d="M82.9,42.5l-9.6,17l5.3,3l3.8-6.8c0,0-1.3,30.9-38.4,35.2l0.2,6.6c0,0,41.1-0.8,45.1-43.6l6.8,3.9l3.3-5.9L82.9,42.5z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <h6 class="mb-0 text-dark">Bulk Payment or Transfers</h6>
                                    </div>
                               </li>
                           </ul>
                       </div>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'TransferComponent',
    components: {
        BreadCrumb,
    },data (){
        return{
            counterPartiesSees:[
                {
                    name:'PAYINC GROUP LIMITED',
                    total_accounts:6,
                },
                {
                    name:'Jones Amegbor',
                    total_accounts:3,
                },
                {
                    name:'7 LCH SA',
                    total_accounts:1,
                },
                {
                    name:'Natwest Markets PLC',
                    total_accounts:5,
                },
                {
                    name:'Societe Generale S.A.',
                    total_accounts:3,
                }
            ]
        }
    },filters: {
        short_hand (company_name) {
            // You can put your logic here.
            let words = company_name.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    }
}

</script>
