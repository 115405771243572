<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration" autocomplete="off">
                    <!-- Country -->
                    <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':registerformdata.country && registerformdata.country.length != 0  && !errors[0]}">
                                <span class="float-label">
                                <label for="Country">Select Country*</label>
                                    <multiselect
                                        placeholder=""
                                        v-model="registerformdata.country" 
                                        :show-labels="false" 
                                        :options="countrys_options" 
                                        :searchable="false" 
                                        label="name" 
                                        track-by="name"
                                        @select="setPrimaryNumberCountryCode">
                                        <template slot="option" slot-scope="props">
                                            <span :class="props.option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                    <i class="fa fa-check" v-if="registerformdata.country && registerformdata.country.length != 0 && !errors[0]"></i>
                                </span>
                            </div>
                        </div>
                    </ValidationProvider>
                    
                    <!-- Business Name -->
                    <ValidationProvider name="Business Name" rules="required" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !registerformdata.businessname,'success':registerformdata.businessname, 'mb-0':apiErrorCode == 'V0010-001'}">
                            <span class="float-label">
                                <input type="text" id="business_name" v-model="registerformdata.businessname" class="form-control" placeholder="Business Name*">
                                <label for="business_name" class="control-label">Business Name*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !registerformdata.businessname"></i>
                                <i class="fa fa-check" v-if="registerformdata.businessname"></i>
                            </span>
                            <span class="text-danger error-msg" v-if="errors[0]">Please provide unique business name</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0010-001'">{{apiErrorMessage}}</span> 
                    
                    <!-- Country Code and Mobile Number -->
                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':registerformdata.mobile_number && mobileInputError}">
                        <div class="float-label">
                            <label for="primary_number" class="control-label">Contact Mobile Number*</label>
                            <vue-tel-input
                                v-model="registerformdata.mobile_number"
                                v-bind="primaryMobileNumber"
                                @validate ="mobileInputValidate"
                                ref="telInput"
                                @keydown.native="restrictSpecialCharacter($event)">
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                </template>
                            </vue-tel-input>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="mobileInputError == false"></i>
                            <i class="fa fa-check" v-if="mobileInputError && registerformdata.mobile_number"></i>
                        </div>
                    </div>
                    
                    <!-- Email -->
                    <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !registerformdata.contact_email || errors[0],'success':registerformdata.contact_email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" v-model="registerformdata.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*" @blur="sweetAlertForEmail">
                                <label for="contact_email" class="control-label">Contact Email Address*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !registerformdata.contact_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="registerformdata.contact_email && !errors[0]"></i>
                            </span>
                            <span v-if="registerformdata.displayConfirmPopup = registerformdata.contact_email && !errors[0]"/>
                        </div>
                    </ValidationProvider>

                    <!-- Email Yes/No -->
                    <!-- <div  v-if="registerformdata.displaylinkedEmail">
                        <ValidationProvider name="profile_password" :rules="registerformdata.displaylinkedEmail ? 'required' : ''" v-slot="{ errors}" v-if="registerformdata.displaylinkedEmail">
                            <div class="head">
                                <label><strong>Enter the password for the existing profile</strong></label>
                            </div>
                            <div class="form-group set-password" :class="{'errors': errors.length && !registerformdata.profile_password,'success':registerformdata.profile_password}">
                                <span class="float-label">
                                    <input :type="registerformdata.password_type" v-model="registerformdata.profile_password" class="form-control" name="profile_password" id="profile_password" placeholder="Password*">
                                    <label for="profile_password" class="control-label">Password*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !registerformdata.profile_password"></i>
                                    <i class="fa fa-check" v-if="registerformdata.profile_password"></i>
                                    <i class="fa fa-eye" :class="registerformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div> -->

                    <!-- term/condition checkbox -->
                    <div class="form-group clearfix">
                        <label class="fancy-checkbox new-fancy">
                            <input type="checkbox" @click="checkIsChecked($event)">
                            <span>Check here to indicate that you have read and agree to the 
                            <strong><router-link to="/terms-and-conditions" target="_blank">Terms and Conditions</router-link></strong></span>
                        </label>
                        <span class="text-danger">{{  registerformdata.errorMsg }}</span>
                    </div>
                    <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                    <div class="bottom">
                        <span>Have an account? <router-link to="/standapp-login">Login</router-link></span>
                    </div>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
const Multiselect = require('vue-multiselect').default
import '@/plugins/sweetalert2'
const { VueTelInput } = require('vue-tel-input')
import parsePhoneNumber from 'libphonenumber-js'
export default {
    name:'StandappRegisterComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data(){
        return{
            registerformdata: {
                is_referral_value:'',
                is_referral:false,
                linkedEmail:false,
                displayConfirmPopup: false,
                displaylinkedEmail:false,
                profile_password:'',
                country: { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                contact_email:'',
                mobile_number:'',
                businessname: '',
                robot: false,
                password_type: 'password',
                IsChecked: false,
                errorMsg:'',
                countryflag:''
            },

            countrys_options: [],
            // countrys_options:[
            //     { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
            //     { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
            //     { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
            //     { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            // ],
            
            mobileInputError:'',
            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            },

            apiErrorCode : '',
            apiErrorMessage : '',
            emailExistCode: ''
        }
    },methods: {
        registerSubmit() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            // if(this.registerformdata.profile_password && this.mobileInputError){
            //     if(this.registerformdata.IsChecked){
            //         this.$router.push({ path: '/standapp-passcode' })
            //     }else{
            //         return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
            //     }
            // }else{
                if(this.mobileInputError){
                    if(this.registerformdata.IsChecked){
                        const phoneNumber = parsePhoneNumber(this.registerformdata.mobile_number, this.registerformdata.country.primaryNumberCode);
                        let businessData = {
                            name: this.registerformdata.businessname,
                            country: this.registerformdata.country.primaryNumberCode,
                            email: this.registerformdata.contact_email,
                            mobile: phoneNumber.number
                        }
                        this.$http.get(this.$baseurl+'business/query/', { params: businessData })
                        .then((res) => {
                            //Perform Success Action
                            // console.log(res);
                            if(res.data){
                                this.apiErrorCode = 'V0010-001';
                                this.apiErrorMessage = 'This business name already registered. Please provide unique business name.';
                            } 
                        })
                        .catch((error) => {
                            this.apiErrorCode = error.response.data.code;
                            if(this.apiErrorCode == 'E0010-001'){
                                if(this.registerformdata.profile_password){
                                    if(this.registerformdata.IsChecked){
                                        this.$router.push({ path: '/standapp-passcode' })
                                    }else{
                                        return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                                    }
                                }else{
                                    if(this.registerformdata.IsChecked){
                                        this.$store.dispatch('registration/addBusiness', businessData);
                                        this.$router.push({ path: '/standapp-personaldetail' })
                                    }else{
                                        return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                                    }
                                }
                            } else {
                                this.apiErrorMessage = error.response.data.detail;
                            }
                        })
                    }else{
                        return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                    }
                }
            // }
        },

        async getData() {
            try {
                const response = await this.$http.get(this.$baseurl+"countries/source");
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                        let countryData = {
                            name: item.name, 
                            flagClass:"flag-icon flag-icon-"+item.isoCode.toLowerCase()+" fi-xl fi-round",
                            primaryNumberCode:item.isoCode,
                            code: item.dialCode
                        }
                    this.countrys_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        },    

        //visible password text
        showPassword() {
            this.registerformdata.password_type = this.registerformdata.password_type === 'password' ? 'text' : 'password';
        },

        sweetAlertForEmail(){
            let emailCheckingData = {
                email : this.registerformdata.contact_email
            }
            this.$http.get(this.$baseurl+'business/query/email', { params: emailCheckingData })
            .then((res) => {
                //Perform Success Action
                this.emailExistCode = res.data.code;
                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-primary btn-lg my-3',
                        cancelButton: 'btn btn-outline-secondary btn-lg'
                    },
                    buttonsStyling: false
                })
                if(this.registerformdata.displayConfirmPopup){
                    swalWithBootstrapButtons.fire({
                        text: "Do you want to continue the registration for this business with the same email address ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // this.registerformdata.displaylinkedEmail = true
                                let businessData = {
                                    name: this.registerformdata.businessname,
                                    country: this.registerformdata.country.code,
                                    email: this.registerformdata.contact_email,
                                    mobile: this.registerformdata.mobile_number
                                }
                                this.$store.dispatch('registration/addBusiness', businessData);
                                this.$router.push({ path: '/standapp-linked-business' })
                            }
                            // else{
                            //     this.registerformdata.displaylinkedEmail = false
                            // } 
                    })
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                // console.log('email exist',error.response.data.code);
                this.emailExistCode = error.response.data.code;
            });
        },

        //check Arrow Certification checkbox cheked
        checkIsChecked(event){
            this.registerformdata.IsChecked = event.target.checked
            if(this.registerformdata.IsChecked){
                return this.registerformdata.errorMsg = ''
            }
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
            this.$root.$refs.sRightHeader.setCountry(defaultCountry.primaryNumberCode);
            // this.$root.$refs.standappHeader2.setCountryCode(defaultCountry.primaryNumberCode);
            sessionStorage.setItem('countryCode',defaultCountry.primaryNumberCode);
        },
       
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        setCountryDropdown(isocode){
            this.countrys_options.forEach(element => {
                if(element.primaryNumberCode == isocode){
                    this.registerformdata.country = { name: element.name, flagClass: "flag-icon flag-icon-"+isocode.toLowerCase()+" fi-xl fi-round",primaryNumberCode:isocode.toUpperCase()};
                    this.$refs.telInput.choose(isocode);
                    // this.setPrimaryNumberCountryCode(country);
                }
            })
        }
        
    },watch: {
        mobileNumber(val) {
            this.registerformdata.mobile_number = val.replace(/\W/g, "");
        },
    },created() {
        this.getData();
        this.$root.$refs.standappRegister = this;
        // if(sessionStorage.getItem('countryCode')){
        //     console.log('ddd');
        //     this.setCountryDropdown(sessionStorage.getItem('countryCode'));
        // }
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
