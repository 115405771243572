<template>
    <b-modal id="login_register" title="Login or Register" hide-footer modal-class="customer-home-modal" no-stacking>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <form class="contact-detail" @submit.prevent="passes(loginOrRegisterFormSubmit)">
                    <div class="login-card card-mobile" :class="{active: activeformClass}">
                        <div class="country-dropdown">
                            <div class="form-group form-select single-multiselect three-view">
                                <span class="float-label">
                                    <label for="Country">Country</label>
                                    <multiselect
                                        placeholder=""
                                        :searchable="false"
                                        v-model="loginRegister.contact_country" 
                                        :show-labels="false" 
                                        :options="country_options"  
                                        label="name" 
                                        track-by="name"
                                        @select="setPrimaryNumberCountryCode">
                                        <template slot="option" slot-scope="props">
                                            <div class="option-title">
                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                <span>{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                </span>
                            </div>
                        </div>
                        <div class="form-group" :class="{'errors': mobileNumberError == false,'success':loginRegister.primaryNumber && mobileNumberError}">
                            <div class="float-label">
                                <label for="primary_number" class="control-label">Mobile Number*</label>
                                <vue-tel-input
                                    class="default-country"
                                    v-model="loginRegister.primaryNumber"
                                    v-bind="mobileNumber"
                                    @validate ="mobileInputValidate"
                                    ref="telInput"
                                    @keydown.native="restrictSpecialCharacter($event)"
                                >
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                </template>
                                </vue-tel-input>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="mobileNumberError == false"></i>
                                <i class="fa fa-check" v-if="mobileNumberError && loginRegister.primaryNumber"></i>
                            </div>
                        </div>
                        <div class="bottom">
                            <b-button variant="gradiunt" size="lg" block @click="loginOrRegisterFormSubmit()">NEXT</b-button> 
                            <b-button variant="outline-primary" size="lg" block   @click="toggleEmaiMobileInput()">Login/Register by {{ toggleEmailMobileTitle }}</b-button> 
                        </div>  
                    </div> 
                    <div class="login-card card-email" :class="{active: !activeformClass}">
                        <div class="country-dropdown">
                            <div class="form-group form-select single-multiselect">
                                <span class="float-label">
                                    <label for="Country">Country</label>
                                    <multiselect
                                        placeholder=""
                                        :searchable="false"
                                        v-model="loginRegister.contact_country" 
                                        :show-labels="false" 
                                        :options="country_options"  
                                        label="name" 
                                        track-by="name"
                                        @select="setPrimaryNumberCountryCode">
                                        <template slot="option" slot-scope="props">
                                            <div class="option-title">
                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                <span>{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                </span>
                            </div>
                        </div>
                        <ValidationProvider name="contact_email" :rules="!activeformClass ? 'required|email' : ''" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && loginRegister.contact_email || errors[0],'success':loginRegister.contact_email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" v-model="loginRegister.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*">
                                    <label for="contact_email" class="control-label">Email*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !loginRegister.contact_email || errors[0] "></i>
                                    <i class="fa fa-check" v-if="loginRegister.contact_email && !errors[0]"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="bottom">
                            <b-button variant="gradiunt" type="submit" size="lg" block>NEXT</b-button> 
                            <b-button variant="outline-primary" size="lg" block @click="toggleEmaiMobileInput()">Login/Register by {{ toggleEmailMobileTitle }}</b-button> 
                        </div>  
                    </div> 
                </form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
const Multiselect = require('vue-multiselect').default
const { VueTelInput } = require('vue-tel-input')

export default {
    name:'LoginRegisterModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data(){
        return{
            activeformClass: true,
            loginRegister:{
                contact_email:'',
                contact_country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'}],
                primaryNumber:''
            },

            mobileNumberError:'',
            mobileNumber: {
                mode: "auto",
                autoFormat:false,
                defaultCountry: "GB",
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                },   
            },

            country_options:[
                { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round",primaryNumberCode:'CA'},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
            ],
            toggleEmailMobileTitle: 'email',
        }
    },methods:{
        loginOrRegisterFormSubmit(){
            if(typeof(this.mobileNumberError) == 'undefined'){
                this.mobileNumberError = false
            }
            
            if(this.mobileNumberError == true && this.activeformClass){
                this.$bvModal.show('mobile_number_varify');
            }else if(this.loginRegister.contact_email && !this.activeformClass){
                this.$bvModal.show('mobile_number_varify');
            }else{
                this.activeformClass ? this.loginRegister.contact_email = '' : this.loginRegister.primaryNumber == ''  
            }
        },

        mobileInputValidate(phoneObject){
            this.mobileNumberError = phoneObject.valid
        },


        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
            this.mobileNumber.defaultCountry = defaultCountry.primaryNumberCode
        },

        toggleEmaiMobileInput(){
            this.activeformClass = !this.activeformClass
            if(this.activeformClass){
                this.toggleEmailMobileTitle = 'email'
            }else{
                this.toggleEmailMobileTitle = 'mobile number'
            } 
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        }
    },watch: {
        mobileNumber(val) {
            this.loginRegister.primaryNumber = val.replace(/\W/g, "");
        },
    }
}
</script>