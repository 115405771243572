<template>
    <div class="container-fluid">
        <!-- BreadCrumb -->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <!-- event Timeline -->
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <events-timeline></events-timeline>
                    </div>
                </div>
            </div>
            <!-- event Form -->
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your event objectives and entice people to learn more. This basic information will represent your event on your event page, on your event card, and in searches.</p>
                            </li>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(event1DataSubmit)" id="event-form" ref="form">
                                    <li>
                                        <div class="head">
                                            <label>Event Title <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="What is the title of your event?"></i>
                                        </div>
                                        <ValidationProvider name="title" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !event.title,'success':event.title}">
                                                <span class="float-label">
                                                    <input type="text" v-model="event.title" class="form-control" id="title" placeholder="Event Title">
                                                    <label for="title" class="control-label">Event Title</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !event.title"></i>
                                                    <i class="fa fa-check" v-if="event.title"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="head">
                                            <label>Event Tagline <span class="text-danger">*</span></label>
                                            <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Provide a short description that best describes your event to your audience."></i>
                                        </div>
                                        <ValidationProvider name="tagline" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !event.tagline,'success':event.tagline}">
                                                <span class="float-label">
                                                    <textarea v-model="event.tagline" class="form-control" id="tagline" placeholder="Event Tagline"></textarea>
                                                    <label for="tagline" class="control-label">Event Tagline</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !event.tagline"></i>
                                                    <i class="fa fa-check" v-if="event.tagline"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12">
                                                <ValidationProvider name="startDate" rules="required" v-slot="{ errors }">
                                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':event.startDate && !errors[0]}">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Event Start Date Time*</span>
                                                                    <span class="input-group-text"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <date-picker class="form-control" name="startdate" v-model="event.startDate" :config="config"></date-picker>
                                                            </b-input-group>
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="event.startDate && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-lg-6 col-md-12">
                                                <ValidationProvider name="endDate" rules="required" v-slot="{ errors }">
                                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':event.endDate && !errors[0]}">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Event End Date Time*</span>
                                                                    <span class="input-group-text"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <date-picker class="form-control" name="enddate" v-model="event.endDate" :config="config"></date-picker>
                                                            </b-input-group>
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="event.endDate && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="body px-0">
                                            <div class="head">
                                                <label>Event Card Image <span class="text-danger">*</span></label>
                                                <i class="fa fa-info-circle ml-1" aria-hidden="true" v-b-tooltip.hover.html title="Upload a square image that represents your event. 640 x 640 recommended resolution, 220 x 220 minimum resolution."></i>
                                            </div>
                                            <ValidationProvider name="event_image" rules="required" v-slot="{ errors}">
                                                <div class="form-group" :class="{'errors': errors.length && !event.event_image,'success':event.event_image}">
                                                    <div class="custom-file">
                                                        <input type="hidden" name="event_image" v-model="event.event_image" accept="image/*">
                                                        <input type="file" ref="resetInputFile" id="event_image" class="form-control custom-file-input" placeholder="event Card Image" accept="image/*" @change="eventImageChange" />
                                                        <label class="custom-file-label" for="event_image">Choose Image</label>
                                                        <i class="fa fa-times" v-if="errors.length && !event.event_image"></i>
                                                        <i class="fa fa-check" v-if="event.event_image"></i>
                                                    </div>
                                                </div>
                                                <div id="preview" v-if="event.event_image">
                                                    <img :src="event.event_image"/>
                                                    <b-button @click="removeImage">Remove image</b-button>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </li>
                                    <div class="d-flex flex-column flex-md-row justify-content-md-end justify-content-center">
                                        <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const EventTimeline = require('@/components/core/EventTimeline').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const datePicker = require('vue-bootstrap-datetimepicker')
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import "@/plugins/vee-validate";

export default {
    name:'EventForm1Component',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        'events-timeline':EventTimeline,
        datePicker
    },data(){
        return{
            event:{
                title:'',
                tagline:'',
                event_image: null,
                startDate:'',
                endDate:''
            },
            
            config: {
                format: 'DD/MM/YYYY h:mm:ss',
                useCurrent: false,
                showClear: true,
                showClose: false
            }
        }
    },methods: {
        //image upload
        eventImageChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this.event.event_image = URL.createObjectURL(file);
            }
        },

        //submit form data
        event1DataSubmit(){
            this.$router.push({ path: '/event/event-location'})
        },

        //remove event image
        removeImage: function () {
            this.$refs.resetInputFile.value = null;
            this.event.event_image = null;
        },
    }
}
</script>
